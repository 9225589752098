import { createSelector } from 'reselect';
import type { Ari } from '@atlassian/jira-platform-ari';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	CLASSIC,
	NEXTGEN,
	type RoadmapType,
	type BoardId,
} from '@atlassian/jira-software-roadmap-model/src/app/index.tsx';
import type { MacroConfig } from '../../model/app';
import type { State } from '../types';

export const getLoggedInAccountId = (state: State): AccountId => state.app.loggedInAccountId;
export const getCloudId = (state: State): string | undefined => state.app.cloudId;
export const getActivationId = (state: State): string | undefined => state.app.activationId;
export const getIsReadOnly = (state: State): boolean => state.app.isReadOnly;
export const getSourceARI = (state: State): Ari => state.app.sourceAri;
export const getBoardId = (state: State): BoardId => state.app.boardId;
export const getMacroConfig = (state: State): MacroConfig | undefined => state.app.macroConfig;

export const hasMacroConfig = createSelector(getMacroConfig, (macroConfig?: MacroConfig) =>
	Boolean(macroConfig),
);

export const getMacroHideHeader = createSelector(getMacroConfig, (macroConfig?: MacroConfig) =>
	Boolean(macroConfig && macroConfig.hideHeader),
);

export const getContainerName = (state: State): string => state.app.containerName;

// ========================== //
// Classic specific use cases //
// ========================== //

export const getRoadmapType = (state: State): RoadmapType => state.app.type;
// Use only when there is no sensible abstraction (see below for examples)
export const isClassic = createSelector(getRoadmapType, (type: RoadmapType) => type === CLASSIC);

export const shouldUseQuickFilters = (state: State): boolean => state.app.type === CLASSIC;
export const shouldUseCustomFilters = (state: State): boolean => state.app.type === NEXTGEN;
