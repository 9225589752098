/** @jsx jsx */
import React, { useRef } from 'react';
import { css, keyframes, jsx } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import { borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';
import type {
	OnDragStart,
	OnDrag,
	OnDragEnd,
} from '@atlassian/jira-drag-observer/src/common/types.tsx';
import { withDragObserver } from '@atlassian/jira-drag-observer/src/ui/index.tsx';
import type { Color } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { isBaseLevel } from '@atlassian/jira-software-roadmap-model/src/hierarchy/index.tsx';
import {
	BASE_BAR_HEIGHT,
	BAR_HEIGHT,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/chart-item.tsx';
import { INTERVAL } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/date.tsx';
import type {
	BarDragType,
	ChartItemUpdateParameter,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/chart-item.tsx';
import type { DateType } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/date.tsx';
import {
	getStartDate,
	getDueDate,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/utils/bar/positions.tsx';
import { getBarTheme } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/utils/bar/theme.tsx';
import { useTimelineState } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/timeline/index.tsx';
import { RIGHT } from '../../../../common/constants';
import { RoadmapDateLabels } from '../../../../common/ui/date-labels';

const Bar = ({
	left,
	right,
	level,
	color,
	children,
	'data-testid': dataTestId,
}: {
	left: number;
	right: number;
	level: number;
	color: Color;
	children?: React.ReactNode;
	'data-testid': string;
}) => (
	<div
		style={{
			left: `${left}px`,
			right: `${right}px`,
		}}
		css={[barStyles, isBaseLevel(level) && baseLevelStyles]}
		data-testid={dataTestId}
	>
		{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */}
		<span style={{ backgroundColor: getBarTheme(color).background }} css={indicatorStyles} />
		{children}
	</div>
);

const DraggableBar = withDragObserver(Bar);

type Props = {
	level: number;
	color: Color;
	startDateType: DateType;
	dueDateType: DateType;
	leftPosition: number;
	rightPosition: number;
	onDrag: OnDrag;
	onDragStart: (type: BarDragType) => void;
	onDragEnd: (params: ChartItemUpdateParameter, analyticsEvent: UIAnalyticsEvent) => void;
	onClick: (params: ChartItemUpdateParameter, analyticsEvent: UIAnalyticsEvent) => void;
};

const PreviewBar = ({
	level,
	color,
	startDateType,
	dueDateType,
	leftPosition,
	rightPosition,
	onDrag,
	onDragStart,
	onDragEnd,
	onClick,
}: Props) => {
	const elemRef = useRef<HTMLElement | null>(null);
	const [{ timelineDuration, timelineWidth }] = useTimelineState();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const startDate = getStartDate(leftPosition, timelineDuration, timelineWidth);
	const dueDate = getDueDate(rightPosition, timelineDuration, timelineWidth);

	// === CALLBACKS === //

	const onHandleDragStart: OnDragStart = () => {
		// Just a sensible default direction, this will update depending on current drag state
		onDragStart(RIGHT);
	};

	const onHandleDragEnd: OnDragEnd = () => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'dragged',
			actionSubject: 'previewBar',
		});
		fireUIAnalytics(analyticsEvent, 'previewBar', { level });

		onDragEnd({ dates: { startDate, dueDate } }, analyticsEvent);
	};

	const onHandleClick = () => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'previewBar',
		});
		fireUIAnalytics(analyticsEvent, 'previewBar', { level });

		onClick({ dates: { startDate, dueDate } }, analyticsEvent);
	};

	const getBarBoundingClientRect = () => elemRef.current?.getBoundingClientRect();

	// === RENDER === //

	/* Interval items should:
	 * - Not be draggable, since their dates only be set to existing intervals, not arbitrarily.
	 * - Not display date labels, for the same reason as above.
	 */
	const isIntervalDateType = startDateType === INTERVAL && dueDateType === INTERVAL;

	return (
		// NOTE: ts error needs to be fixed in withDragObserver from @atlassian/jira-drag-observer
		// @ts-expect-error - TS2322 - Type '{ children: false | Element; "data-testid": string; innerRef: MutableRefObject<unknown>; color: Color; left: number; right: number; isDisabled: boolean; onDrag: OnDrag; onDragStart: OnDragStart; onDragEnd: OnDragEnd; onClick: () => void; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<DragObserver> & Pick<Readonly<Props>, never> & InexactPartial<...> & InexactPartial<...>'.
		<DraggableBar
			data-testid="roadmap.timeline-table-kit.ui.chart-item-content.no-date-content.preview-bar.preview-bar"
			innerRef={elemRef}
			color={color}
			left={leftPosition}
			right={rightPosition}
			isDisabled={isIntervalDateType}
			onDrag={onDrag}
			onDragStart={onHandleDragStart}
			onDragEnd={onHandleDragEnd}
			onClick={onHandleClick}
		>
			{!isIntervalDateType && (
				<RoadmapDateLabels
					startDate={startDate}
					dueDate={dueDate}
					getBarBoundingClientRect={getBarBoundingClientRect}
				/>
			)}
		</DraggableBar>
	);
};

export { PreviewBar };

const fadeInStyles = keyframes({
	'0%': { opacity: 0 },
	'100%': { opacity: 1 },
});

const baseLevelStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${BASE_BAR_HEIGHT}px`,
});

const barStyles = css({
	position: 'absolute',
	minWidth: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${BAR_HEIGHT}px`,
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
	opacity: 0,
	animationDelay: '0.2s',
	animationDuration: '0.2s',
	animationName: fadeInStyles,
	animationTimingFunction: 'ease-in-out',
	animationFillMode: 'forwards',
	cursor: 'pointer',
});

// NOTE: There's no easy way atm to apply opacity to a color token without affecting its children
// Hence, a pseudo "before" element is used as a hack here for controlling the background color
const indicatorStyles = css({
	content: '',
	position: 'absolute',
	top: 0,
	left: 0,
	bottom: 0,
	right: 0,
	opacity: 0.5,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
});
