import React from 'react';
import { EXPLICIT } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/date.tsx';
import type {
	Placeholder,
	BarDragType,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/chart-item.tsx';
import type { DateType } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/date.tsx';
import { FormattedDate } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/ui/formatted-date/main.tsx';
import { LEFT, RIGHT, LEFT_AND_RIGHT } from '../../constants';
import { Duration } from './duration';
import { getDurationProps } from './duration/utils';
import { useDatePlacement } from './use-date-placement';

type Props = {
	dragType?: BarDragType | undefined;
	placeholder?: Placeholder;
	prevStartDate?: number;
	prevDueDate?: number;
	startDate: number;
	dueDate: number;
	startDateType: DateType;
	dueDateType: DateType;
	getBarBoundingClientRect: () => ClientRect | undefined;
};

/* For the purpose of displaying the dates, the interaction context is important.
 * - If a date would be hidden by the viewport on one side, we attempt to display it on the other side
 * - All dates are considered explicit while dragging, as any inferred dates will be replaced after the interaction
 * - Only show the date that is being updated.
 */
const RoadmapDateLabels = ({
	prevStartDate,
	prevDueDate,
	startDate,
	dueDate,
	startDateType,
	dueDateType,
	placeholder,
	dragType,
	getBarBoundingClientRect,
}: Props) => {
	const displayStartDateType =
		dragType === LEFT || dragType === LEFT_AND_RIGHT ? EXPLICIT : startDateType;
	const displayDueDateType =
		dragType === RIGHT || dragType === LEFT_AND_RIGHT ? EXPLICIT : dueDateType;

	const displayPrevStartDate = dragType !== RIGHT ? prevStartDate : undefined;
	const displayPrevDueDate = dragType !== LEFT ? prevDueDate : undefined;
	const displayStartDate = dragType !== RIGHT ? startDate : undefined;
	const displayDueDate = dragType !== LEFT ? dueDate : undefined;

	const isDurationVisible = !placeholder;

	const placement = useDatePlacement({
		dragType,
		startDateType: displayStartDateType,
		dueDateType: displayDueDateType,
		placeholder,
		isDurationVisible,
		getBarBoundingClientRect,
	});

	const renderDuration = () => (
		<Duration
			{...getDurationProps(
				displayPrevStartDate,
				displayPrevDueDate,
				displayStartDate,
				displayDueDate,
				dragType,
				startDate,
				dueDate,
			)}
		/>
	);

	return (
		<FormattedDate
			placement={placement}
			startDate={displayStartDate}
			dueDate={displayDueDate}
			startDateType={displayStartDateType}
			dueDateType={displayDueDateType}
			placeholder={placeholder}
			isDurationVisible={isDurationVisible}
			renderDuration={renderDuration}
		/>
	);
};

RoadmapDateLabels.defaultProps = {
	startDateType: EXPLICIT,
	dueDateType: EXPLICIT,
};

export { RoadmapDateLabels };
