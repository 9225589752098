import 'rxjs/add/observable/of';
import isEqual from 'lodash/isEqual';
import { Observable } from 'rxjs/Observable';
import { prefixer } from '@atlassian/jira-common-storage-redux/src/engines/common';
import type { Engine } from '@atlassian/jira-common-storage-redux/src/types';
import { type Ari, type AriConfig, parseAri } from '@atlassian/jira-platform-ari';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { BoardId } from '@atlassian/jira-software-roadmap-model/src/app/index.tsx';
import setUserSettings from '@atlassian/jira-software-roadmap-services/src/users/set-user-settings.tsx';

export const engine = (
	prefix: string,
	boardId: BoardId,
	sourceAri: Ari,
	loggedInAccountIdSelector: () => AccountId,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	prefetchedData?: { [key: string]: any },
): Engine => {
	const mod = prefixer(`standard-${prefix}`);
	const blob: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[key: string]: any;
	} = prefetchedData || {};
	let propertyKey = mod(`board-${boardId}`);

	const ariConfig: AriConfig | undefined = parseAri(sourceAri);
	if (ariConfig) {
		propertyKey = mod(`${ariConfig.resourceType}-${ariConfig.resourceId}`);
	}

	return {
		get: <T,>(key: string): Observable<T> => Observable.of(blob[key]),
		set: <T,>(key: string, value: T) => {
			if (!isEqual(blob[key], value)) {
				blob[key] = value;
				setUserSettings(loggedInAccountIdSelector(), propertyKey, JSON.stringify(blob));
			}
		},
		allowsModificationWithoutHydration: false,
	};
};
