import React, { memo, useMemo, useCallback, type KeyboardEvent, type MouseEvent } from 'react';
import { useIntl } from 'react-intl-next';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import traceUFOPress from '@atlassian/react-ufo/trace-press';
import { INSIDE, BEFORE, listItemMenu } from '../../../../../../../../common/constants';
import { useListItemMenuOptions } from '../../../../../../../../common/context/list/index.tsx';
import type { ListItemOptionId } from '../../../../../../../../common/types/item.tsx';
import { fireUIAnalyticsEvent } from '../../../../../../../../common/utils/analytics';
import { ListItemMenuOption } from '../../../../list-item-menu-option';
import type { CreateItemMenuProps } from '../../../types';
import { renderOverrideLabel } from '../../../utils';
import messages from './messages';

const CreateItemMenu = ({
	id,
	level,
	depth,
	parentId,
	isCreateChildEnabled,
	isCreateSiblingEnabled,
	onInlineCreateClicked,
	setInitialFocusRef,
	onCreateTriggerClick,
	setCloseMenu,
}: CreateItemMenuProps) => {
	const { formatMessage } = useIntl();
	const [{ overrideLabels }] = useListItemMenuOptions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const onClickCreateChildOption = useCallback(
		(event: KeyboardEvent | MouseEvent) => {
			onCreateTriggerClick();

			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'createChildMenuOption',
				attributes: {
					position: INSIDE,
				},
			});
			fireUIAnalyticsEvent(analyticsEvent);
			traceUFOPress('timeline-create-child-option-clicked', event.timeStamp);
			onInlineCreateClicked({ parentId: id, position: INSIDE });
			setCloseMenu(event, { shouldReturnFocus: false });
		},
		[createAnalyticsEvent, id, onCreateTriggerClick, onInlineCreateClicked, setCloseMenu],
	);

	const onClickCreateSiblingAboveOption = useCallback(
		(event: KeyboardEvent | MouseEvent) => {
			onCreateTriggerClick();
			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'createAboveMenuOption',
				attributes: {
					position: BEFORE,
					level,
					depth,
				},
			});
			fireUIAnalyticsEvent(analyticsEvent);
			traceUFOPress('timeline-create-above-option-clicked', event.timeStamp);
			onInlineCreateClicked({ beforeId: id, parentId, position: BEFORE });
			setCloseMenu(event, { shouldReturnFocus: false });
		},
		[
			createAnalyticsEvent,
			depth,
			id,
			level,
			onCreateTriggerClick,
			onInlineCreateClicked,
			parentId,
			setCloseMenu,
		],
	);

	const createItemChildOptions = useMemo(() => {
		const options: {
			id: ListItemOptionId;
			onClick: (event: KeyboardEvent | MouseEvent) => void;
		}[] = [];
		if (isCreateChildEnabled) {
			options.push({ id: listItemMenu.CREATE_CHILD_ITEM, onClick: onClickCreateChildOption });
		}
		if (isCreateSiblingEnabled) {
			options.push({
				id: listItemMenu.CREATE_ITEM_ABOVE,
				onClick: onClickCreateSiblingAboveOption,
			});
		}
		return options;
	}, [
		isCreateChildEnabled,
		isCreateSiblingEnabled,
		onClickCreateChildOption,
		onClickCreateSiblingAboveOption,
	]);

	return (
		<ListItemMenuOption
			setInitialFocusRef={setInitialFocusRef}
			label={
				renderOverrideLabel(formatMessage, overrideLabels[listItemMenu.CREATE_ITEM]) ||
				formatMessage(messages.createItem)
			}
		>
			{({ setInitialFocusRef: setChildFocusRef }) => (
				<>
					{createItemChildOptions.map(({ id: optionId, onClick }, index) => (
						<ListItemMenuOption
							key={optionId}
							label={
								renderOverrideLabel(formatMessage, overrideLabels[optionId]) ||
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								formatMessage(messages[optionId as keyof typeof messages])
							}
							setInitialFocusRef={index === 0 ? setChildFocusRef : undefined}
							onClick={onClick}
						/>
					))}
				</>
			)}
		</ListItemMenuOption>
	);
};

export default memo<CreateItemMenuProps>(CreateItemMenu);
