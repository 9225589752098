import type { IssueId, IssueStatusCategoryId } from '@atlassian/jira-shared-types/src/general.tsx';
import type {
	Subtask,
	SubtaskHash,
} from '@atlassian/jira-software-roadmap-model/src/subtask/index.tsx';

export const EXTEND_SUBTASKS = 'state.entities.subtasks.EXTEND_SUBTASKS' as const;

export type ExtendSubtasksAction = {
	type: typeof EXTEND_SUBTASKS;
	payload: SubtaskHash;
};

export const extendSubtasks = (payload: SubtaskHash): ExtendSubtasksAction => ({
	type: EXTEND_SUBTASKS,
	payload,
});

// === Add new subtask to issue ===
export const ADD_SUBTASK = 'state.entities.subtasks.ADD_SUBTASK' as const;

type AddSubtaskPayload = {
	id: IssueId;
	subtask: Subtask;
};

export type AddSubtaskAction = {
	type: typeof ADD_SUBTASK;
	payload: AddSubtaskPayload;
};

export const addSubtask = (payload: AddSubtaskPayload): AddSubtaskAction => ({
	type: ADD_SUBTASK,
	payload,
});

// === Remove subtask from issue ===
export const REMOVE_SUBTASKS = 'state.entities.subtasks.REMOVE_SUBTASKS' as const;

type removeSubtasksPayload = {
	id: IssueId;
	parentId: IssueId;
}[];

export type RemoveSubtasksAction = {
	type: typeof REMOVE_SUBTASKS;
	payload: removeSubtasksPayload;
};

export const removeSubtasks = (payload: removeSubtasksPayload): RemoveSubtasksAction => ({
	type: REMOVE_SUBTASKS,
	payload,
});

// === Update subtask in issue ===
export const UPDATE_SUBTASK_STATUS = 'state.entities.subtasks.UPDATE_SUBTASK_STATUS' as const;

type UpdateSubtaskStatusPayload = {
	id: IssueId;
	parentId: IssueId;
	statusCategoryId: IssueStatusCategoryId;
};

export type UpdateSubtaskStatusAction = {
	type: typeof UPDATE_SUBTASK_STATUS;
	payload: UpdateSubtaskStatusPayload;
};

export const updateSubtaskStatus = (
	payload: UpdateSubtaskStatusPayload,
): UpdateSubtaskStatusAction => ({
	type: UPDATE_SUBTASK_STATUS,
	payload,
});

// === Change subtask parent ===
export const CHANGE_SUBTASK_PARENT = 'state.entities.subtasks.CHANGE_SUBTASK_PARENT' as const;

type ChangeSubtaskParentPayload = {
	id: IssueId;
	oldParentId: IssueId;
	newParentId: IssueId;
};

export type ChangeSubtaskParentAction = {
	type: typeof CHANGE_SUBTASK_PARENT;
	payload: ChangeSubtaskParentPayload;
};

export const changeSubtaskParent = (
	payload: ChangeSubtaskParentPayload,
): ChangeSubtaskParentAction => ({
	type: CHANGE_SUBTASK_PARENT,
	payload,
});
