import React, { memo, useState, useEffect } from 'react';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import {
	LIST_DRAG_AND_DROP,
	CHILD_LIST_DRAG_AND_DROP,
	ROW_DRAG_AND_DROP_ID,
	DEFAULT_LEVEL,
} from '../../common/constants';
import { useEnablements } from '../../common/context/enablements/index.tsx';
import { useSideEffectMarshal } from '../../common/context/side-effect-marshal';
import { useTimelineViewportActions } from '../../common/context/timeline-viewport/index.tsx';
import type { DndLongTaskMetricComponent, RowDragData } from '../../common/types/drag-and-drop.tsx';
import { isBaseLevel as getIsBaseLevel } from '../../common/utils/hierarchy-enriched-items';

type Props = {
	DndLongTaskMetric: DndLongTaskMetricComponent | undefined;
};

const DragMonitor = ({ DndLongTaskMetric }: Props) => {
	const [{ isTreeDrag }] = useEnablements();
	const [dragState, setDragState] = useState({ level: DEFAULT_LEVEL, isDragging: false });
	const { onRowDragStart, onRowDragEnd, onRowDragOutOfBounds } = useSideEffectMarshal();
	const { onResize } = useTimelineViewportActions();
	// Effects //
	useEffect(
		() =>
			combine(
				monitorForElements({
					onDragStart: ({ source }) => {
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						const item = source.data as RowDragData;
						if (item.type === ROW_DRAG_AND_DROP_ID) {
							onRowDragStart(item.id);
							setDragState({ level: item.level, isDragging: true });
						}
					},
					onDropTargetChange: ({ source, location }) => {
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						const item = source.data as RowDragData;
						if (item.type === ROW_DRAG_AND_DROP_ID) {
							const { dropTargets } = location.current;

							if (dropTargets.length === 0) {
								onRowDragOutOfBounds(item.id);
							}
						}
					},
					onDrop: ({ source }) => {
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						const item = source.data as RowDragData;
						if (item.type === ROW_DRAG_AND_DROP_ID) {
							onRowDragEnd(item.id);
							setDragState({ level: item.level, isDragging: false });
							if (isTreeDrag) {
								onResize({
									indicatorLineOffset: 0,
								});
							}
						}
					},
				}),
			),
		[onRowDragStart, onRowDragEnd, onRowDragOutOfBounds, onResize, isTreeDrag],
	);

	return DndLongTaskMetric ? (
		<DndLongTaskMetric
			isDragging={dragState.isDragging}
			metricType={getIsBaseLevel(dragState.level) ? CHILD_LIST_DRAG_AND_DROP : LIST_DRAG_AND_DROP}
		/>
	) : null;
};

const DragMonitorEnabler = (props: Props) => {
	const [{ isDragEnabled }] = useEnablements();
	return isDragEnabled ? <DragMonitor {...props} /> : null;
};

export default memo<Props>(DragMonitorEnabler);
