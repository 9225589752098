import {
	ISSUE_CREATED_EVENT,
	ISSUE_DELETED_EVENT,
	ISSUE_TRANSITIONED_EVENT,
	ISSUE_UPDATED_EVENT,
} from '@atlassian/jira-realtime/src/common/constants/events.tsx';
import type { RealtimeEventType } from '@atlassian/jira-realtime/src/common/types/events.tsx';
import type {
	ProjectEventPayload,
	IssueEventPayload,
	BoardEventPayload,
} from '@atlassian/jira-realtime/src/common/types/payloads.tsx';
import { getLoggedInAccountId } from '../../../state/app/selectors';
import type { State } from '../../../state/types';

export const isCurrentUser = (state: State, accountId: string) =>
	getLoggedInAccountId(state) === accountId;

const issueEventTypes: RealtimeEventType[] = [
	ISSUE_CREATED_EVENT,
	ISSUE_UPDATED_EVENT,
	ISSUE_TRANSITIONED_EVENT,
	ISSUE_DELETED_EVENT,
];

export const isIssueEvent = (
	type: RealtimeEventType,
	payload?: ProjectEventPayload | IssueEventPayload | BoardEventPayload,
): payload is IssueEventPayload =>
	issueEventTypes.includes(type) &&
	(payload === undefined || ('issueId' in payload && payload.issueId !== undefined));
