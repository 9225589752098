import join from 'lodash/join';
import qs from 'query-string';
import type {
	Label,
	CustomFilterId as CustomFilterTypeId,
} from '@atlassian/jira-filters/src/common/types.tsx';
import type {
	ComponentId,
	IssueTypeId,
	AccountId,
	VersionId,
	IssueStatusCategoryId,
	IssueId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import {
	type RoadmapType,
	NEXTGEN,
} from '@atlassian/jira-software-roadmap-model/src/app/index.tsx';
import type { QuickFilterId } from '@atlassian/jira-software-roadmap-model/src/quick-filter/index.tsx';
import type { Query } from '@atlassian/jira-software-roadmap-model/src/router/index.tsx';
import { getSanitizedQuery } from '@atlassian/jira-software-roadmap-services/src/utils/url-query/index.tsx';

const stringifyValuesList = <TValue extends string>(
	value?: TValue | TValue[],
): string | undefined => {
	if (Array.isArray(value)) {
		return value.length > 0 ? join(value, ',') : undefined;
	}

	return value || undefined;
};

export const convertQueryToString = (query: Query): string => {
	const modOverride = {
		statuses: stringifyValuesList<IssueStatusCategoryId>(query.statuses),
		issueType: stringifyValuesList<IssueTypeId>(query.issueType),
		assignee: stringifyValuesList<AccountId>(query.assignee),
		label: stringifyValuesList<Label>(query.label),
		issueParent: stringifyValuesList<IssueId>(query.issueParent),
		shared: query.shared ? null : undefined,
		version: stringifyValuesList<VersionId>(query.version),
		component: stringifyValuesList<ComponentId>(query.component),
		quickFilter: stringifyValuesList<QuickFilterId>(query.quickFilter),
		customFilter: stringifyValuesList<CustomFilterTypeId>(query.customFilter),
		highlightedVersions: stringifyValuesList<VersionId>(query.highlightedVersions),
		goal: stringifyValuesList<string>(query.goal),
	};

	const mod = { ...query, ...modOverride };
	return qs.stringify(mod);
};

export const convertStringToQuery = (query: string, roadmapType: RoadmapType = NEXTGEN): Query => {
	const parsed = qs.parse(query);

	return getSanitizedQuery(parsed, roadmapType);
};
