import type { Epic } from 'redux-observable';
import initiateStore from '@atlassian/jira-common-tangerine/src/state/initiate';

import errorHandlerReduxMiddleware from '@atlassian/jira-errors-handling/src/utils/redux-middleware.tsx';
import type { RoadmapType } from '@atlassian/jira-software-roadmap-model/src/app/index.tsx';
import { toRoadmapAppId } from '../constants';
import type { Dependencies } from '../model/common';
import { generalError } from './flags/actions';
import resourcesCacheMiddleware from './middleware/resources-cache';
import rootReducer from './reducer';
import type { State } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Action = any;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	type?: RoadmapType,
	rootEpic?: Epic<Action, State>,
	rootEpicDependencies?: Dependencies,
) => {
	const middlewares = [
		errorHandlerReduxMiddleware(toRoadmapAppId(type), generalError),
		resourcesCacheMiddleware,
	];

	return initiateStore<State, Action, Dependencies>({
		appName: toRoadmapAppId(type),
		rootReducer,
		rootEpic,
		rootEpicDependencies,
		middlewares,
	});
};
