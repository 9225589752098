import { createSelector } from 'reselect';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import type { Label } from '@atlassian/jira-filters/src/common/types.tsx';
import { UNASSIGNED_LABEL_ID } from '@atlassian/jira-software-filters/src/common/constants.tsx';
import { createSelectorWithDefaultEqual } from '../../../../../common/utils/reselect';
import { getIssueLabelsHash } from '../../../../entities/issues/selectors';

const getUniqueLabels = createSelector(getIssueLabelsHash, (issueLabelsHash) => {
	const allLabels: Label[] = flatten(
		Object.keys(issueLabelsHash).map((key) => {
			const issueLabels: Label[] = issueLabelsHash[key] || [];
			return issueLabels;
		}),
	);
	return uniq(allLabels).sort();
});

export const getLabels = createSelectorWithDefaultEqual(
	getUniqueLabels,
	(labels: Label[]): Label[] => labels,
);

export const getLabelsWithUnassignedOption = createSelectorWithDefaultEqual(
	getUniqueLabels,
	(labels: Label[]): Label[] => (labels.length ? [UNASSIGNED_LABEL_ID, ...labels] : labels),
);
