import { MILLISECONDS_PER_DAY } from '@atlassian/jira-software-roadmap-model/src/datetime/index.tsx';
import { DUE_DATE_OFFSET } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/date.tsx';
import type { TimelineDuration } from '@atlassian/jira-software-roadmap-timeline-table/src/common/types/timeline.tsx';
import {
	getDateFromTimelinePosition,
	getTimelinePositionFromDate,
} from '@atlassian/jira-software-roadmap-timeline-table/src/common/utils/timeline.tsx';

// The left position of the bar on the timeline, in pixels (aligns to start of day)
export const getBarLeftPosition = (
	startDate: number,
	timelineDuration: TimelineDuration,
	timelineWidth: number,
) => getTimelinePositionFromDate(timelineDuration, timelineWidth, startDate);

// The right position of the bar on the timeline, in pixels (aligns to end of day)
export const getBarRightPosition = (
	dueDate: number,
	timelineDuration: TimelineDuration,
	timelineWidth: number,
) => {
	const endOfDueDate = dueDate + DUE_DATE_OFFSET;
	const leftPosition = getTimelinePositionFromDate(timelineDuration, timelineWidth, endOfDueDate);
	return timelineWidth - leftPosition;
};

export const getClosestDay = (date: number): number =>
	Math.round(date / MILLISECONDS_PER_DAY) * MILLISECONDS_PER_DAY;

export const getStartDate = (
	leftPosition: number,
	timelineDuration: TimelineDuration,
	timelineWidth: number,
): number => {
	const startDate = getDateFromTimelinePosition(timelineDuration, timelineWidth, leftPosition);
	return getClosestDay(startDate);
};

export const getDueDate = (
	rightPosition: number,
	timelineDuration: TimelineDuration,
	timelineWidth: number,
): number => {
	const dueDate =
		getDateFromTimelinePosition(timelineDuration, timelineWidth, timelineWidth - rightPosition) -
		DUE_DATE_OFFSET;
	return getClosestDay(dueDate);
};

export const getIsBarVisible = (
	barLeftPosition: number,
	barRightPosition: number,
	timelineWidth: number,
): boolean => {
	const isBeforeTimeline = barLeftPosition < 0 && barRightPosition >= timelineWidth;
	const isAfterTimeline = barLeftPosition >= timelineWidth && barRightPosition < 0;

	return !isBeforeTimeline && !isAfterTimeline;
};
