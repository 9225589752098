import { createSelector } from 'reselect';
import type { CustomFilterId as CustomFilterTypeId } from '@atlassian/jira-filters/src/common/types.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { FilterConfiguration } from '@atlassian/jira-software-roadmap-model/src/configuration/index.tsx';
import type {
	CustomFilter,
	CustomFiltersHash,
} from '@atlassian/jira-software-roadmap-model/src/custom-filter/index.tsx';
import type {
	QuickFilter,
	QuickFiltersHash,
	QuickFilterId,
} from '@atlassian/jira-software-roadmap-model/src/quick-filter/index.tsx';
import { getFilteredQuickFilters } from '../../selectors/filters';
import type { State } from '../../types';
import { getFilteredQuickFilterQueriesPure } from './pure';

export const getFilterConfiguration = (state: State): FilterConfiguration | undefined =>
	state.ui.filter.configuration;

export const getFullQuickFilterHash = createSelector(
	getFilterConfiguration,
	(filterConfiguration?: FilterConfiguration): QuickFiltersHash => {
		if (filterConfiguration !== undefined) {
			return filterConfiguration.quickFilters;
		}
		return {};
	},
);

export const getFullCustomFilterHash = createSelector(
	getFilterConfiguration,
	(filterConfiguration?: FilterConfiguration): CustomFiltersHash => {
		if (filterConfiguration !== undefined) {
			return filterConfiguration.customFilters;
		}
		return {};
	},
);

export const getQuickFilter = (state: State, quickFilterId: QuickFilterId): QuickFilter =>
	getFullQuickFilterHash(state)[quickFilterId];

export const getCustomFilter = (state: State, customFilterId: CustomFilterTypeId): CustomFilter =>
	getFullCustomFilterHash(state)[customFilterId];

export const getJQLFilteredIssueIds = (state: State): IssueId[] => state.ui.filter.issueIds;

export const getIsJQLFiltersActive = (state: State): boolean => state.ui.filter.isJQLFilterActive;

export const getIsJQLFilterLoading = (state: State): boolean => state.ui.filter.isJQLFilterLoading;

export const getFilteredQuickFilterQueries = createSelector(
	getFilteredQuickFilters,
	getFullQuickFilterHash,
	getFilteredQuickFilterQueriesPure,
);
