import React, { Component } from 'react';
import noop from 'lodash/noop';
import SuccessFlag from '@atlassian/jira-flags/src/common/ui/components/success-auto-dismiss-flag';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import messages from './messages';

type Props = {
	id: string | number;
	onShowDependencies: () => void;
	onDismissed: (id: number | string) => void;
	intl: IntlShape;
};

// eslint-disable-next-line jira/react/no-class-components
export class HiddenDependencyCreatedFlag extends Component<Props> {
	static defaultProps = {
		onDismissed: noop,
	};

	onDismissed = () => {
		const { id, onDismissed } = this.props;
		onDismissed(id);
	};

	onShowDependencies = () => {
		const { onShowDependencies } = this.props;

		onShowDependencies();
		this.onDismissed();
	};

	render() {
		const {
			intl: { formatMessage },
			onShowDependencies,
			...props
		} = this.props;

		return (
			<SuccessFlag
				{...props}
				onDismissed={this.onDismissed}
				id={this.props.id}
				title={formatMessage(messages.title)}
				description={formatMessage(messages.description)}
				actions={[
					{
						content: formatMessage(messages.showDependencies),
						onClick: this.onShowDependencies,
					},
				]}
			/>
		);
	}
}

export default injectIntl(HiddenDependencyCreatedFlag);
