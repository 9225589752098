import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import type { ListItemMenuOptionProps } from '@atlassian/jira-software-roadmap-timeline-table/src/renderers/index.tsx';
import {
	REMOVE_DATES,
	EDIT_SPRINT,
	EDIT_DATES,
	BULK_EDIT_DATES,
	EDIT_DEPENDENCY,
	CHANGE_PARENT_ISSUE,
	CHANGE_ISSUE_COLOR,
} from '../../../../constants';
import BulkEditDatesOption from './bulk-edit-dates-option';
import ChangeColorOption from './change-color-option';
import EditDatesOption from './edit-dates-option';
import EditDependencyOption from './edit-dependency-option';
import EditSprintOption from './edit-sprint-option';
import RemoveDatesOption from './remove-dates-option';
import ReparentMenuOption from './reparent-menu-option';

const ListItemMenuOption = ({
	optionId,
	id,
	setOpenModal,
	Option,
	setCloseMenu,
	setInitialFocusRef,
}: ListItemMenuOptionProps) => {
	switch (optionId) {
		case CHANGE_PARENT_ISSUE:
			return fg('jsw_roadmaps_timeline-meatball-reparent-menu') ? (
				<ReparentMenuOption id={id} Option={Option} setCloseMenu={setCloseMenu} />
			) : null;

		case CHANGE_ISSUE_COLOR:
			return fg('jsw_roadmaps_timeline-meatballs-change-issue-color') ? (
				<ChangeColorOption id={id} Option={Option} setCloseMenu={setCloseMenu} />
			) : null;

		case EDIT_SPRINT:
			return fg('jsw_roadmaps_timeline_sprint_scheduling_a11y') ? (
				<EditSprintOption id={id} Option={Option} setCloseMenu={setCloseMenu} />
			) : null;

		case EDIT_DATES:
			return fg('jsw_roadmaps_timeline-meatballs-date-update-modal') ? (
				<EditDatesOption id={id} setOpenModal={setOpenModal} Option={Option} />
			) : null;

		case BULK_EDIT_DATES:
			return fg('jsw_roadmap_timeline_meatball_selected_item_option') ? (
				<BulkEditDatesOption
					id={id}
					setOpenModal={setOpenModal}
					Option={Option}
					setInitialFocusRef={setInitialFocusRef}
				/>
			) : null;

		case REMOVE_DATES:
			return fg('jsw_roadmaps_timeline-meatballs-date-update-modal') ? (
				<RemoveDatesOption id={id} Option={Option} setCloseMenu={setCloseMenu} />
			) : null;

		case EDIT_DEPENDENCY:
			return fg('jsw_roadmaps_timeline-meatball-dependency-modal') ? (
				<EditDependencyOption id={id} setOpenModal={setOpenModal} Option={Option} />
			) : null;

		default:
			return null;
	}
};

export default ListItemMenuOption;
