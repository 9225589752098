/** @jsx jsx */
import React, {
	useMemo,
	useCallback,
	useState,
	type ReactNode,
	type MouseEvent,
	type KeyboardEvent,
	useEffect,
} from 'react';
import { css, jsx } from '@compiled/react';
import { Popup, type TriggerProps } from '@atlaskit/popup';
import { N0, N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip, { type TriggerProps as TooltipTriggerProps } from '@atlaskit/tooltip';
import { borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { mergeRefs } from '@atlassian/jira-merge-refs';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	MARKER_BORDER_WIDTH,
	MARKER_WIDTH,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/key-date.tsx';
import { OVERLAY_FLYOUT_Z_INDEX } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/z-index.tsx';
import type {
	KeyDate,
	KeyDateStatus,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/key-date.tsx';
import {
	ContentName,
	ContentDateLabel,
	TooltipInterval,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/ui/marker-tooltip/styled.tsx';
import { useIsHeaderModalActive } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/controllers/table-providers/chart-item-interaction/main.tsx';
import { useViewport } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/viewport/context/index.tsx';
import { isEnterOrSpaceKey } from '@atlassian/jira-software-roadmap-timeline-table/src/common/utils/events.tsx';
import { getDateFromTimestamp } from '@atlassian/jira-software-roadmap-utils/src/utils/dates';
import traceUFOPress from '@atlassian/react-ufo/trace-press';
import { ReleaseIcon } from '../../../../common/ui/release-icon';
import messages from './messages';
import { getReleaseNamesAndDatesAlly } from './utils';

export type Props = {
	/**
	 * Selected Key date "id" attribute
	 */
	id: string;
	/**
	 * Collection of key dates to show in the marker
	 */
	keyDates: ReadonlyArray<KeyDate>;
	/**
	 * "left" css attribute to place the label on the timeline (position kept as "absolute")
	 */
	leftPosition: number;
	/**
	 * Content for the label
	 */
	children: ReactNode;
	/**
	 * Status for the laberl
	 */
	status: KeyDateStatus;
	/**
	 * Render props for content that is displayed inside the popup.
	 */
	renderPopupContent: () => React.ReactNode;
};

const tooltipStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
	boxSizing: 'border-box',
	padding: `${token('space.025', '2px')} ${token('space.075', '6px')}`,
	maxWidth: '600px',
	backgroundColor: token('color.background.neutral.bold', N800),
	color: token('color.text.inverse', N0),
	lineHeight: 1.3,
});

const markerStyles = css({
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${MARKER_WIDTH}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	transform: `translateX(-${MARKER_WIDTH / 2 - MARKER_BORDER_WIDTH / 2}px)`,
	display: 'flex',
	alignItems: 'center',
	cursor: 'pointer',
	'&:hover': {
		zIndex: 2,
	},
});

const MarkerWithTooltip = ({
	id,
	keyDates,
	leftPosition,
	children,
	status,
	renderPopupContent,
}: Props) => {
	const { listenToViewportScroll, stopListeningToViewportScroll } = useViewport();
	// Read the Timeline-table-kit context provider for any indication if the edit version details modal dialog was triggered (so Popup will stay open and not closed)
	const [{ isHeaderModalActive }, { onToggleHeaderModal }] = useIsHeaderModalActive();
	const [isPopupActive, setIsPopupActive] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();

	const tooltipContent = useMemo(
		() => (
			<>
				{keyDates.map(({ name, date }, index) => (
					<TooltipInterval key={index}>
						<ReleaseIcon />
						<ContentName>{name}</ContentName>
						<ContentDateLabel>{`(${getDateFromTimestamp(date)})`}</ContentDateLabel>
					</TooltipInterval>
				))}
			</>
		),
		[keyDates],
	);

	// Listen to user event scrolling the timeline table => Hide the popup on each time its been scrolled
	useEffect(() => {
		const onViewPortScrollHandler = () => {
			setIsPopupActive(false);
		};

		listenToViewportScroll(onViewPortScrollHandler);
		return function cleanup() {
			stopListeningToViewportScroll(onViewPortScrollHandler);
		};
	}, [listenToViewportScroll, stopListeningToViewportScroll]);

	const onClickMarkerLabelHandler = useCallback(
		(event: MouseEvent<HTMLElement>) => {
			traceUFOPress('timeline-keydate-marker-clicked', event.timeStamp);

			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			});
			fireUIAnalytics(analyticsEvent, 'keyDatesMarker', { status });
			setIsPopupActive(true);
		},
		[status, createAnalyticsEvent],
	);

	const onKeyDown = useCallback((event: KeyboardEvent<HTMLElement>) => {
		if (isEnterOrSpaceKey(event)) {
			event.preventDefault();
			traceUFOPress('timeline-keydate-marker-clicked', event.timeStamp);
			setIsPopupActive(true);
		}
	}, []);

	const onClosePopup = useCallback(() => {
		if (!isHeaderModalActive) {
			// This is needed due to usecase where the `<Popup />' component triggers the onClose event due to "Edit Version Details" dialog is opened which breaks the workflow
			setIsPopupActive(false);
			onToggleHeaderModal(false);
		}
	}, [isHeaderModalActive, onToggleHeaderModal]);
	const renderPopupTrigger = ({ ref, ...restTriggerProps }: TriggerProps) => (
		<Tooltip
			// @ts-expect-error Property 'css' does not exist on type 'IntrinsicAttributes & TooltipProps'.ts(2322)
			css={tooltipStyles}
			content={tooltipContent}
			position="bottom-end"
			hideTooltipOnClick
		>
			{({ 'aria-describedby': ariaDescribedBy, ...tooltipProps }: TooltipTriggerProps) => (
				<div
					{...restTriggerProps}
					{...tooltipProps}
					css={markerStyles}
					style={{
						left: `${leftPosition}px`,
						// THIS WAS NEEDED SINCE OLD IMPLEMENATION DOES NOT WRAP THE CONTENT OF TOOLTIP INSIDE A <div role="presentation" /> which causes the shift of the label to go down
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						top: '7.5px',
					}}
					ref={mergeRefs(ref, tooltipProps.ref)}
					tabIndex={0}
					role="button"
					data-testid={`roadmap.timeline-table-kit.ui.chart-header-item.key-dates.marker-${id}`}
					onClick={onClickMarkerLabelHandler}
					onKeyDown={onKeyDown}
					aria-label={formatMessage(messages.releases, {
						noOfReleases: keyDates.length,
						releaseNamesAndDates: getReleaseNamesAndDatesAlly(keyDates),
					})}
				>
					{children}
				</div>
			)}
		</Tooltip>
	);

	return (
		<Popup
			isOpen={isPopupActive}
			onClose={onClosePopup}
			placement="bottom-start"
			content={renderPopupContent}
			trigger={renderPopupTrigger}
			zIndex={OVERLAY_FLYOUT_Z_INDEX}
		/>
	);
};

export { MarkerWithTooltip };
