import type { Hash, IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

/** Maximum runs that checks cyclic depth for optimisation */
const MAX_DEPTH = 10;

/**
 * This function is essentially a depth-first search.
 * We are looking for a path from the dependency issue to the dependee issue.
 * A maximum depth is set to avoid taking too long to complete.
 *
 * !!!Note!!!
 * This cyclic dependency validation is never going to be complete because it's
 * happening on the front-end, and only from the Timeline.
 * The front-end does NOT know all issues in Jira, such as issues from other projects,
 * and cycles could exist there.
 * Furthermore, users can still create cycles from other parts of Jira,
 * so we should be careful and not prevent users from creating new dependencies
 * if there are existing cycles.
 */
export const isDependencyCyclic = ({
	dependenciesHash,
	dependency,
	dependee,
	maxDepth = MAX_DEPTH,
}: {
	dependenciesHash: Hash<string[]>;
	dependency: IssueId;
	dependee: IssueId;
	maxDepth?: number;
}): boolean => {
	const visited: Record<IssueId, boolean> = {};

	const findPath = (node: IssueId, depth: number): boolean => {
		// if we find a previously visited node, we can stop because we have already traversed subsequent paths
		if (visited[node]) {
			return false;
		}

		visited[node] = true;

		// if we reach the max depth, we stop and assume there is no path
		if (depth >= maxDepth) {
			return false;
		}

		// if we reach the destination node, then we have found a path
		if (node === dependee) {
			return true;
		}

		const connectedNodes = dependenciesHash[node] ?? [];
		return connectedNodes.some((connectedNode) => findPath(connectedNode, depth + 1));
	};

	return findPath(dependency, 0);
};
