import { INTERVAL } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/date.tsx';
import type { Interval } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/interval.tsx';
import type { State, InteractionData, IntervalDropZoneData } from '../common/types';

/* Returns whether the interval that overlaps with the interactive items position is in a given set of intervals.
 * This "target interval" is used to highlight various elements across the chart. To minimise visual noise,
 * we avoid returning the interval until the user has lingered on the relevant item.
 */
export const getTargetIntervalId = (
	{ targetIntervalId, hasLingered }: State,
	intervals: ReadonlyArray<Interval>,
): string | undefined => {
	if (hasLingered) {
		const targetInterval = intervals.find(({ id }: Interval) => id === targetIntervalId);
		return targetInterval?.id;
	}

	return undefined;
};

/* Returns the data associated with a particular item as a result of the current interaction.
 * An item may have positions even when it is not being directly interacted with as a result of
 * interaction with another item. To minimise re-renders we only provide data for an item that
 * has a current position (and thus would be visible).
 */
export const getInteractionDataById = (
	{ activeItem, dragType, itemPositions }: State,
	id: string,
): InteractionData => {
	const leftPosition = itemPositions[id]?.leftPosition;
	const rightPosition = itemPositions[id]?.rightPosition;
	const hasAnyPosition = leftPosition !== undefined || rightPosition !== undefined;

	return {
		isActive: activeItem?.id === id,
		leftPosition,
		rightPosition,
		dragType: hasAnyPosition ? dragType : undefined,
	};
};

/**
 * Returns true/false whether the modal window active due to action by any of the chart header items (e.g. sprints, releases)
 * @returns true|false
 */
export const getIsHeaderModalActive = ({ isHeaderModalActive }: State) => ({
	isHeaderModalActive,
});

/* Some items can be interacted with to update their currently assigned interval. Whether or not
 * this is possible depends on the type of item (date vs no date) as the user intent is different.
 */
export const getIntervalDropZoneDataById = (
	{ activeItem, dragType, hasLingered }: State,
	id: string,
): IntervalDropZoneData => {
	if (
		!activeItem ||
		activeItem.id !== id ||
		(activeItem.startDateType !== INTERVAL && activeItem.dueDateType !== INTERVAL)
	) {
		return {
			isVisible: false,
			currentIntervalId: undefined,
		};
	}

	if (activeItem.startDate !== undefined && activeItem.dueDate !== undefined) {
		return {
			isVisible: dragType !== undefined,
			currentIntervalId: activeItem.intervalId,
		};
	}

	return {
		isVisible: hasLingered,
		currentIntervalId: activeItem.intervalId,
	};
};
