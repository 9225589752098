import memoizeOne from 'memoize-one';
// eslint-disable-next-line jira/restricted/moment
import moment from 'moment';
import { QUARTERS, MONTHS } from '../constants';
import type { TimelineMode, TimelineDuration, ColumnDuration } from '../types/timeline.tsx';

const getColumnDurationsForMode = (
	timelineDuration: TimelineDuration,
	momentIdentifier: 'month' | 'quarter' | 'isoWeek',
): ColumnDuration[] => {
	const columnDurations: Array<ColumnDuration> = [];
	const { startMilliseconds, endMilliseconds } = timelineDuration;
	// Sanitize. We still want to *round* to an ISO week, but you can't *add* an ISO week (it fails silently).
	const momentAddIdentifier = momentIdentifier === 'isoWeek' ? 'week' : momentIdentifier;

	const startMoment = moment.utc(startMilliseconds).startOf(momentIdentifier);
	while (startMoment.valueOf() < endMilliseconds) {
		const endMoment = startMoment.clone().endOf(momentIdentifier);

		columnDurations.push({
			startMilliseconds: startMoment.valueOf(),
			endMilliseconds: endMoment.valueOf(),
			totalMilliseconds: endMoment.diff(startMoment, 'milliseconds'),
		});

		startMoment.add(1, momentAddIdentifier);
	}

	return columnDurations;
};

/* Get the duration of each column within the timeline.
 * A column is exactly one unit of time based on the specific timeline mode,
 * (e.g. one week, one month, one quarter).
 */
export const getColumnDurations = memoizeOne(
	(timelineMode: TimelineMode, timelineDuration: TimelineDuration): ColumnDuration[] => {
		if (timelineMode === QUARTERS) {
			return getColumnDurationsForMode(timelineDuration, 'quarter');
		}
		if (timelineMode === MONTHS) {
			return getColumnDurationsForMode(timelineDuration, 'month');
		}

		/* We always want a week column to start on Monday. The 'week' parameter is locale aware which could start on Sunday.
		 * An ISO week on the other hand always starts on the Monday.
		 */
		return getColumnDurationsForMode(timelineDuration, 'isoWeek');
	},
);

// Given the date range of each column, gets the relative position of each column on the timeline
export const getColumnPositions = memoizeOne(
	(timelineDuration: TimelineDuration, columnDurations: ColumnDuration[]): [number, number][] => {
		let left = 0;
		if (columnDurations.length > 0) {
			// Column start can be earlier than the timeline start. This means the first column left can be a negative value.
			// Example where this can happen is in the export table where the timeline duration is clamped to the selected date range.
			left =
				((columnDurations[0].startMilliseconds - timelineDuration.startMilliseconds) /
					timelineDuration.totalMilliseconds) *
				100;
		}

		return columnDurations.map((columnDuration: ColumnDuration) => {
			const width = (columnDuration.totalMilliseconds / timelineDuration.totalMilliseconds) * 100;
			left += width;

			return [left - width, 100 - left];
		});
	},
);

// Finds the index of the column which contains today's date
export const getTodayColumnIndex = memoizeOne(
	(today: number, columnDurations: ColumnDuration[]) => {
		let todayColumnIndex = 0;

		columnDurations.some(({ startMilliseconds, endMilliseconds }) => {
			if (today >= startMilliseconds && today <= endMilliseconds) {
				return true;
			}

			todayColumnIndex += 1;
			return false;
		});

		return todayColumnIndex;
	},
);
