import difference from 'lodash/difference';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { CUSTOM, standardIssueTypes } from '@atlassian/jira-common-constants/src/issue-types';
import ecClient from '@atlassian/jira-jsis-ec-client/src/services/index.tsx';
import IssueMutation from '@atlassian/jira-jsis-ec-client/src/services/issue-mutation/index.tsx';
import { MutationSource } from '@atlassian/jira-jsis-ec-client/src/services/storage/constants.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { CreatedIssue } from '@atlassian/jira-software-roadmap-services/src/issues/types.tsx';
import { isEcClientEventListenerEnabled } from '../../../../feature-flags';
import { getFullDerivedFields } from '../../../../state/configuration/selectors';
import { getIssueKeyById, getSafeIdResolve } from '../../../../state/entities/issues/selectors';
import { getIssueTypeName } from '../../../../state/selectors/issue-types';
import type { State } from '../../../../state/types';
import { getIsJQLFiltersActive } from '../../../../state/ui/filters/selectors';
import refreshIssuePage from '../../refresh-issue-page';

const DERIVED_FIELD_WHITELIST = ['pid', 'issuetype', 'summary', 'reporter'];

export const performPostCreateSideEffects = (
	state: State,
	temporaryId: IssueId,
	{ id, issueTypeId, parentId }: CreatedIssue,
	analyticsEvent: UIAnalyticsEvent,
) => {
	// Notify listeners waiting on the issue to have been created on the backend
	const resolveSafeId = getSafeIdResolve(state, temporaryId);
	resolveSafeId(id);

	// Update the issue view of the parent to reflect any new child issues.
	if (parentId) {
		const issueKey = getIssueKeyById(state, parentId);
		refreshIssuePage(state, issueKey);
	}

	const issueTypeName = getIssueTypeName(state, issueTypeId);
	const safeIssueTypeName =
		issueTypeName !== undefined && standardIssueTypes[issueTypeName] ? issueTypeName : CUSTOM;
	const clonedAnalyticsEvent = analyticsEvent.clone();
	if (isEcClientEventListenerEnabled()) {
		ecClient.saveIssueMutationToCache(new IssueMutation(id, MutationSource.CREATE), {
			analyticsMetadata: { scenario: 'roadmap' },
			analyticsEventObj: clonedAnalyticsEvent || {},
		});
	}
	if (clonedAnalyticsEvent) {
		const jqlFiltersActive = getIsJQLFiltersActive(state);
		const derivedFields = getFullDerivedFields(state);
		fireTrackAnalytics(clonedAnalyticsEvent, 'issue created', `${id}`, {
			issueTypeId,
			issueType: safeIssueTypeName,
			projectType: 'software',
			isJQLFiltersApplied: jqlFiltersActive,
			isDerivedFieldsApplied:
				difference(Object.keys(derivedFields), DERIVED_FIELD_WHITELIST).length > 0,
		});
	}
};
