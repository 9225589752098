import type { IssueId, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { PanelType } from '../../../model/panel';

// Determine whether the panel should be shown. Must indicate what type of panel.
export const SHOW_PANEL = 'state.ui.panel.SHOW_PANEL' as const;

export type ShowPanelAction = {
	type: typeof SHOW_PANEL;
	payload: NonNullable<PanelType>;
};

export const showPanel = (payload: NonNullable<PanelType>): ShowPanelAction => ({
	type: SHOW_PANEL,
	payload,
});

// Hide the panel, regardless of the panel type.
export const HIDE_PANEL = 'state.ui.panel.HIDE_PANEL' as const;

export type HidePanelAction = {
	type: typeof HIDE_PANEL;
};

export const hidePanel = (): HidePanelAction => ({
	type: HIDE_PANEL,
});

// Show the issue details panel type for a specific issue id
export const SHOW_ISSUE_DETAILS = 'state.ui.panel.SHOW_ISSUE_DETAILS' as const;

export type ShowIssueDetailsAction = {
	type: typeof SHOW_ISSUE_DETAILS;
	payload: IssueId;
};

// TODO: to be removed when cleaning up FF jsw.roadmaps.simplify-logic-between-router-and-panel
export const showIssueDetails = (payload: IssueId): ShowIssueDetailsAction => ({
	type: SHOW_ISSUE_DETAILS,
	payload,
});

// The selected issue is used by the issue details panel type to update the url
export const SET_SELECTED_ISSUE = 'state.ui.panel.SET_SELECTED_ISSUE' as const;

export type SetSelectedIssueAction = {
	type: typeof SET_SELECTED_ISSUE;
	payload: IssueKey | undefined;
};

/** NOTE: setSelectedIssue should NOT be batched as ROUTER_LOCATION_CHANGE is subscribed by other observables */
export const setSelectedIssue = (payload?: IssueKey): SetSelectedIssueAction => ({
	type: SET_SELECTED_ISSUE,
	payload,
});
