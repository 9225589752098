import { useCallback, useRef, useState } from 'react';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { DEPENDENCY_DRAG_ITEM_ID } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/dependency.tsx';
import type {
	DragHandleType,
	OnDropDependency,
	DependencyDragData,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/dependency.tsx';
import { getRelativeHandlePosition } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/utils/dependency/handler-position.tsx';
import { useTimelineState } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/timeline/index.tsx';
import { useViewport } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/viewport/context/index.tsx';

type Props = {
	id: string;
	type: DragHandleType;
	leftPosition: number;
	rightPosition: number;
	getBarBoundingClientRect: () => DOMRect | undefined;
	onDrop: OnDropDependency;
};

const useDependenciesDrag = ({
	id,
	type,
	getBarBoundingClientRect,
	leftPosition,
	rightPosition,
}: Props): [{ isDragging: boolean }, (el: HTMLElement | null) => void, () => void] => {
	const cleanupDragAndDrop = useRef<null | (() => void)>(null);
	const [dragState, setDragState] = useState({ isDragging: false });
	const [{ timelineWidth }] = useTimelineState();
	const { requestViewportBoundingClient, requestViewportScrollBounding, setViewportScroll } =
		useViewport();

	const makeDraggable = useCallback(
		(dragElement: HTMLElement | null) => {
			const el = dragElement;
			if (el === null) {
				return;
			}

			cleanupDragAndDrop.current = combine(
				draggable({
					element: el,
					getInitialData: () =>
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						({
							type: DEPENDENCY_DRAG_ITEM_ID,
							id,
							handleType: type,
							barRect: getBarBoundingClientRect(),
							leftPosition: getRelativeHandlePosition(
								type,
								timelineWidth,
								leftPosition,
								rightPosition,
							),
							viewportBoundingRect: requestViewportBoundingClient(),
							initialScrollBoundingRect: requestViewportScrollBounding(),
							requestViewportScrollBounding,
							setViewportScroll,
						}) as DependencyDragData,
					onGenerateDragPreview: ({ source }) => {
						const { element: draggedElement } = source;
						// hide the native drag preview
						draggedElement.style.opacity = '0';
					},
					onDragStart: ({ source }) => {
						const { element: draggedElement } = source;
						draggedElement.style.opacity = '1';
						setDragState({ isDragging: true });
					},
					onDrop: () => {
						setDragState({ isDragging: false });
					},
				}),
			);
		},
		[
			id,
			type,
			leftPosition,
			rightPosition,
			timelineWidth,
			setViewportScroll,
			requestViewportScrollBounding,
			requestViewportBoundingClient,
			getBarBoundingClientRect,
		],
	);

	const cleanupDraggable = useCallback(() => {
		cleanupDragAndDrop.current?.();
	}, []);

	return [dragState, makeDraggable, cleanupDraggable];
};

export { useDependenciesDrag };
