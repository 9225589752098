import isEqual from 'lodash/isEqual';
import memoizeOne from 'memoize-one';
import { MILLISECONDS_PER_DAY } from '@atlassian/jira-software-roadmap-model/src/datetime/index.tsx';
import { DUE_DATE_OFFSET } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/date.tsx';
import { STATUS_PRIORITY } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/key-date.tsx';
import type {
	KeyDate,
	KeyDatesGroup,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/key-date.tsx';
import {
	QUARTERS,
	WEEKS,
	MONTHS,
} from '@atlassian/jira-software-roadmap-timeline-table/src/common/constants.tsx';
import type {
	TimelineMode,
	TimelineDuration,
} from '@atlassian/jira-software-roadmap-timeline-table/src/common/types/timeline.tsx';
import { getTimelinePositionFromDate } from '@atlassian/jira-software-roadmap-timeline-table/src/common/utils/timeline.tsx';

const NUMBER_OF_DAYS_OFFSET = {
	[QUARTERS]: 3,
	[MONTHS]: 1,
	[WEEKS]: 1,
} as const;

const MAX_WIDTH = 300;

export const getKeyDatesGroups = memoizeOne(
	({
		keyDates,
		timelineMode,
		timelineDuration,
		timelineWidth,
	}: {
		keyDates: ReadonlyArray<KeyDate>;
		timelineMode: TimelineMode;
		timelineDuration: TimelineDuration;
		timelineWidth: number;
	}): KeyDatesGroup[] => {
		const dayOffset = NUMBER_OF_DAYS_OFFSET[timelineMode];

		const groups: KeyDatesGroup[] = [];
		let lastStart: number;
		let lastEnd: number;

		// Sort the data by date DESC for max width calculation
		const keyDatesInDescOrder = keyDates
			.slice()
			.sort((keyDate, nextKeyDate) => nextKeyDate.date - keyDate.date);

		keyDatesInDescOrder.forEach((keydate) => {
			const { date, status } = keydate;
			if (date >= timelineDuration.startMilliseconds && date <= timelineDuration.endMilliseconds) {
				if (lastStart && lastEnd && date > lastStart && date <= lastEnd) {
					// Add to existing group if the date is within the range (1 day or 3 days for Quarter)
					const lastGroup = groups[0];
					lastGroup.keyDates.unshift(keydate);

					// Update group's state if the new status is a higher priority
					if (STATUS_PRIORITY[status] > STATUS_PRIORITY[lastGroup.status]) {
						lastGroup.status = status;
					}
				} else {
					// Get the min date of the group
					lastStart = Math.max(
						date - dayOffset * MILLISECONDS_PER_DAY,
						timelineDuration.startMilliseconds,
					);
					lastEnd = date;

					const newLeft = getTimelinePositionFromDate(
						timelineDuration,
						timelineWidth,
						/**
						 * If markers are group by more than 1 day, take the middle position
						 * otherwise, use end of day ms for marker's position
						 */
						dayOffset > 1 ? (lastEnd + lastStart) / 2 : lastEnd + DUE_DATE_OFFSET,
					);

					// Assume full timeline width as previous left position if there is no prior group
					const lastLeft = groups.length > 0 ? groups[0].leftPosition : timelineWidth;

					// Add the entry to the first of the array so the markers of earlier date will be overlapped on top of the latter
					groups.unshift({
						id: keydate.id,
						keyDates: [keydate],
						status,
						leftPosition: newLeft,
						maxWidth: Math.min(Math.round(lastLeft - newLeft), MAX_WIDTH),
					});
				}
			}
		});

		return groups;
	},
	(newArgs, lastArgs) => {
		if (newArgs.length !== lastArgs.length) {
			return false;
		}

		const equalityFn = isEqual;
		return newArgs.every((newArg, index) => equalityFn(newArg, lastArgs[index]));
	},
);
