import { layers } from '@atlassian/jira-common-styles/src/main.tsx';

export const CHART_OVERLAY_Z_INDEX = 2;
export const DEPENDENCY_LINES_Z_INDEX = 2;
export const CHART_BAR_Z_INDEX = 3;
export const HIGHLIGHT_LINES_Z_INDEX = 3;
export const CHART_BAR_CONTENT_Z_INDEX = 4;
export const DEPENDENCIES_HANDLER_Z_INDEX = 5;
export const CHART_NAVIGATE_BUTTON_Z_INDEX = 6;
export const CHART_PREVIEW_BAR_Z_INDEX = 6;
export const CHART_DATES_Z_INDEX = 8;
export const CHART_FLYOUT_Z_INDEX = 15;
/**
 * This zIndex applies to the overlay flyout component (src/packages/software/roadmap/timeline-table-kit/src/common/ui/flyout) due to other elements can overlap on screen dependeing on user screen size (e.g. timeline container)
 */
export const OVERLAY_FLYOUT_Z_INDEX = layers.navigation;
