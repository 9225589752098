import React, {
	useRef,
	useCallback,
	type ComponentType,
	type ReactElement,
	type MouseEvent as ReactMouseEvent,
} from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { B100 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import VisuallyHidden from '@atlaskit/visually-hidden';
import { withDragObserver } from '@atlassian/jira-drag-observer/src/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { Color } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	START_DATE_PLACEHOLDER,
	DUE_DATE_PLACEHOLDER,
	START_AND_DUE_DATE_PLACEHOLDER,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/chart-item.tsx';
import {
	ROLLUP,
	INTERVAL,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/date.tsx';
import type {
	Placeholder,
	OnChartItemClick,
	OnChartItemUpdate,
	HorizontalDirection,
	BarTheme,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/chart-item.tsx';
import type { DateType } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/date.tsx';
import { RoadmapDateLabels } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/ui/date-labels/index.tsx';
import { getPlaceholderWhileDragging } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/utils/bar/placeholder.tsx';
import {
	getStartDate,
	getDueDate,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/utils/bar/positions.tsx';
import { getBarTheme } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/utils/bar/theme.tsx';
import { RoadmapBarIcon } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/chart-item-content/date-content/bar/bar-content/bar-icon/index.tsx';
import { RoadmapDependencyHandlers } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/chart-item-content/date-content/bar/bar-content/dependency-handler/index.tsx';
import { useEnablements } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/enablements/index.tsx';
import { useTimelineState } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/timeline/index.tsx';
import { isEnterOrSpaceKey } from '@atlassian/jira-software-roadmap-timeline-table/src/common/utils/events.tsx';
import { getDateFromTimestamp } from '@atlassian/jira-software-roadmap-utils/src/utils/dates';
import { LEFT_AND_RIGHT, LEFT, RIGHT } from '../../../../common/constants';
import type { Position } from '../../../../common/types';
import { getBaseBarStyles } from '../../../../common/ui/bar';
import { useChartItemInteractions } from '../../../../controllers/table-providers/chart-item-interaction';
import {
	RoadmapBarContent,
	type BarContentRenderers,
	type BarContentProps,
	type BarContentState,
	type BarIconRendererProps,
	type BarDependencyHandlerRendererProps,
	type BarDependencyHandlerRendererState,
	type BarDateLabelRendererProps,
} from './bar-content';
import { RoadmapDragHandle } from './drag-handle';
import messages from './messages';
import { getMinimumPositions, getUpdatedDates, getAnalyticsType } from './utils';
import { RoadmapBarWarnings } from './warning';

type Props = {
	isReadOnly: boolean;
	id: string;
	left: number;
	right: number;
	color: Color;
	level: number;
	startDateType: DateType;
	dueDateType: DateType;
	warnings: MessageDescriptor[];
	placeholder: Placeholder;
	DndLongTaskMetric: ComponentType<{
		isDragging: boolean;
		level: number;
	}>;
	renderBarContent: (props: BarContentProps, state: BarContentState) => ReactElement | null;
	onLeftClick: OnChartItemClick;
	onRightClick?: (id: string, position: Position) => void;
	onUpdate: OnChartItemUpdate;
};

const StyledBar = ({
	left,
	right,
	placeholder,
	theme,
	level,
	shouldAnimate,
	isSelectEnabled,
	innerRef,
	containerRef,
	children,
	startDate,
	dueDate,
	color,
	'data-testid': testId,
	onMouseEnter,
	onMouseLeave,
	onContextMenu,
	...rest
}: {
	left: number;
	right: number;
	placeholder: Placeholder;
	theme: BarTheme;
	level: number;
	shouldAnimate: boolean;
	isSelectEnabled: boolean;
	innerRef: React.MutableRefObject<HTMLDivElement>;
	containerRef: React.MutableRefObject<HTMLDivElement>;
	onClick: (nativeEvent: React.SyntheticEvent) => void;
	onMouseDown: (nativeEvent: React.SyntheticEvent) => void;
	onDragStart: (nativeEvent: React.SyntheticEvent) => void;
	onDrag: (nativeEvent: React.SyntheticEvent) => void;
	onDragEnd: (nativeEvent: React.SyntheticEvent) => void;
	onMouseEnter: () => void;
	onMouseLeave: () => void;
	onContextMenu: ((event: ReactMouseEvent<HTMLElement>) => void) | undefined;
	startDate: number;
	dueDate: number;
	color: Color;
	'data-testid': string;
	children?: React.ReactNode;
}) => {
	const { formatMessage } = useIntl();

	return (
		<Box
			ref={containerRef}
			testId={`${testId}-container`}
			style={{
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values
				...getBaseBarStyles({ level, theme, placeholder }),
				left: `${left}px`,
				right: `${right}px`,
				cursor: isSelectEnabled ? 'pointer' : 'default',
				transition: shouldAnimate ? 'left 0.1s ease, right 0.1s ease' : 'none',
			}}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<Box
				ref={innerRef}
				xcss={focusedBarStyles}
				aria-label={formatMessage(messages.gridCellAriaLabel, {
					startDate: getDateFromTimestamp(startDate),
					dueDate: getDateFromTimestamp(dueDate),
					color,
				})}
				onContextMenu={onContextMenu}
				testId={testId}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...rest}
			/>
			<Box xcss={barContentOverlayStyles}>
				<Box xcss={barContentWrapperStyles}>{children}</Box>
			</Box>
		</Box>
	);
};

const DraggableBar = withDragObserver(StyledBar);

const RoadmapBar = ({
	id,
	color,
	level,
	left: initialLeftPosition,
	right: initialRightPosition,
	startDateType,
	dueDateType,
	warnings,
	placeholder,
	isReadOnly,
	DndLongTaskMetric,
	onUpdate,
	onLeftClick,
	onRightClick,
	renderBarContent,
}: Props) => {
	const [{ isSelectEnabled }] = useEnablements();
	const [{ timelineDuration, timelineWidth }] = useTimelineState();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const barContainerRef = useRef<HTMLElement | null>(null);
	const barButtonRef = useRef<HTMLElement | null>(null);

	const [
		{ isActive, dragType, ...interactionState },
		{ onDragStart, onDrag, onDragEnd, onLingerStart, onLingerEnd },
	] = useChartItemInteractions(id);
	const { formatMessage } = useIntl();
	const isHovered = isActive && !dragType;
	const isDragging = dragType !== undefined;
	const isDragExplicit = isDragging && isActive;

	const isResizeEnabled = startDateType !== INTERVAL && dueDateType !== INTERVAL;

	const leftPosition = interactionState.leftPosition ?? initialLeftPosition;
	const rightPosition = interactionState.rightPosition ?? initialRightPosition;
	const { minimumLeft, minimumRight } = getMinimumPositions(
		leftPosition,
		rightPosition,
		timelineWidth,
	);
	const isMinimumBar = minimumLeft !== undefined && minimumRight !== undefined;

	const newLeft =
		(isDragExplicit || isHovered) && minimumLeft !== undefined ? minimumLeft : leftPosition;
	const newRight =
		(isDragExplicit || isHovered) && minimumRight !== undefined ? minimumRight : rightPosition;
	const newPlaceholder = isDragExplicit
		? getPlaceholderWhileDragging(dragType, placeholder)
		: placeholder;

	const prevStartDate = getStartDate(initialLeftPosition, timelineDuration, timelineWidth);
	const prevDueDate = getDueDate(initialRightPosition, timelineDuration, timelineWidth);
	const startDate = getStartDate(leftPosition, timelineDuration, timelineWidth);
	const dueDate = getDueDate(rightPosition, timelineDuration, timelineWidth);
	const theme = getBarTheme(color);

	// === CALLBACKS === //
	const getBarBoundingClientRect = useCallback(
		() => barContainerRef.current?.getBoundingClientRect(),
		[],
	);

	const onResizeStartLeft = () => {
		onDragStart(LEFT);
	};

	const onResizeStartRight = () => {
		onDragStart(RIGHT);
	};

	const onBarDragStart = () => {
		onDragStart(LEFT_AND_RIGHT);
	};

	const onBarDragEnd = () => {
		const updatedDates = getUpdatedDates(startDate, dueDate, dragType);

		const analyticsEvent = createAnalyticsEvent({
			action: 'dragEnded',
			actionSubject: 'bar',
		});
		const attributes = {
			type: getAnalyticsType(updatedDates),
			level,
			isStartDateInferred: startDateType === ROLLUP || startDateType === INTERVAL,
			isDueDateInferred: dueDateType === ROLLUP || dueDateType === INTERVAL,
		};
		fireUIAnalytics(analyticsEvent, 'issueBar', attributes);

		onDragEnd();
		onUpdate(id, { dates: updatedDates, meta: { isDragged: true } }, analyticsEvent);
	};

	const onBarRightClick = (event: ReactMouseEvent<HTMLElement>) => {
		if (onRightClick !== undefined) {
			event.preventDefault();
			const { top, height } = event.currentTarget.getBoundingClientRect();

			const analyticsEvent = createAnalyticsEvent({
				action: 'rightClicked',
				actionSubject: 'bar',
			});
			fireUIAnalytics(analyticsEvent, 'issueBar');
			onRightClick(id, {
				x: event.clientX,
				y: top + height,
			});
		}
	};

	const onBarLeftClick = useCallback(
		({ target, ctrlKey, metaKey, shiftKey }: MouseEvent | React.KeyboardEvent<HTMLElement>) => {
			if (target === barButtonRef.current) {
				const withCmd = ctrlKey || metaKey;
				const withShift = shiftKey;
				const analyticsEvent = createAnalyticsEvent({
					action: 'clicked',
					actionSubject: 'bar',
				});
				const itemClickMeta = { level, withCmd, withShift };

				fireUIAnalytics(analyticsEvent, 'issueBar', itemClickMeta);
				onLeftClick(id, itemClickMeta, analyticsEvent);
			}
		},
		[createAnalyticsEvent, id, level, onLeftClick],
	);

	// === RENDER === //

	const renderDragHandle = (type: HorizontalDirection, hasPlaceholder: boolean) => {
		const shouldRenderHandle =
			(isHovered && dragType === undefined) || (dragType === type && isDragExplicit);

		return shouldRenderHandle && isResizeEnabled && !isReadOnly ? (
			<RoadmapDragHandle
				id={id}
				type={type}
				theme={theme}
				hasPlaceholder={hasPlaceholder}
				onDragStart={type === LEFT ? onResizeStartLeft : onResizeStartRight}
				onDrag={onDrag}
				onDragEnd={onBarDragEnd}
			/>
		) : null;
	};

	const renderGhostBar = () => {
		if (!isDragging) {
			return null;
		}

		return (
			<div
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/enforce-style-prop
					...getBaseBarStyles({ level, theme, placeholder }),
					left: `${initialLeftPosition}px`,
					right: `${initialRightPosition}px`,
					backgroundColor: theme.background,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					opacity: '0.3',
				}}
			/>
		);
	};

	const barContentProps = {
		id,
		dragType,
		prevStartDate,
		prevDueDate,
		startDate,
		dueDate,
		startDateType,
		dueDateType,
		leftPosition: newLeft,
		rightPosition: newRight,
		placeholder: newPlaceholder,
		isReadOnly,
		isMinimumBar,
		getBarBoundingClientRect,
		onBarMouseLeave: onLingerEnd,
	};
	const barContentState = { isActive, isHovered };

	const warningsDescription = () => (
		<VisuallyHidden
			id={`roadmap.timeline-table-kit.ui.chart-item-content.date-content.bar.warning-desc-${id}`}
		>
			{warnings.length > 0 &&
				`${formatMessage(messages.warningsCount, { count: warnings.length })} `}
			{warnings.map((errorMessage) => `${formatMessage(errorMessage)} `)}
		</VisuallyHidden>
	);

	const onKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLElement>) => {
			if (isEnterOrSpaceKey(e) && isSelectEnabled) {
				// Prevents timeline from scrolling on space
				e.preventDefault();
				onBarLeftClick(e);
			}
		},
		[isSelectEnabled, onBarLeftClick],
	);

	const renderDraggableBar = () => (
		//  NOTE: ts error needs to be fixed in withDragObserver from @atlassian/jira-drag-observer
		// @ts-expect-error - TS2322 -   Property 'children' does not exist on type 'IntrinsicAttributes & IntrinsicClassAttributes<DragObserver> & Pick<Readonly<Props>, never> & InexactPartial<...> & InexactPartial<...>'. */}
		<DraggableBar
			data-testid={`roadmap.timeline-table-kit.ui.chart-item-content.date-content.bar.draggable-bar-${id}`}
			innerRef={barButtonRef}
			left={newLeft}
			right={newRight}
			level={level}
			theme={theme}
			placeholder={newPlaceholder}
			shouldAnimate={isHovered && isMinimumBar && !isDragging}
			isSelectEnabled={isSelectEnabled}
			isDisabled={isReadOnly}
			onMouseEnter={onLingerStart}
			onMouseLeave={onLingerEnd}
			onDrag={onDrag}
			onDragStart={onBarDragStart}
			onDragEnd={onBarDragEnd}
			onContextMenu={!isReadOnly ? onBarRightClick : undefined}
			onClick={isSelectEnabled ? onBarLeftClick : undefined}
			containerRef={barContainerRef}
			onKeyDown={onKeyDown}
			tabIndex={0}
			startDate={startDate}
			dueDate={dueDate}
			color={color}
			role="button"
			aria-describedby={`roadmap.timeline-table-kit.ui.chart-item-content.date-content.bar.warning-desc-${id}`}
		>
			{renderBarContent(barContentProps, barContentState)}
			{renderDragHandle(
				LEFT,
				placeholder === START_DATE_PLACEHOLDER || placeholder === START_AND_DUE_DATE_PLACEHOLDER,
			)}
			{renderDragHandle(
				RIGHT,
				placeholder === DUE_DATE_PLACEHOLDER || placeholder === START_AND_DUE_DATE_PLACEHOLDER,
			)}
		</DraggableBar>
	);

	return (
		<>
			{renderGhostBar()}
			{renderDraggableBar()}
			{warningsDescription()}
			{!isDragExplicit && !isHovered && warnings.length > 0 && (
				<RoadmapBarWarnings warnings={warnings} right={newRight} />
			)}
			<DndLongTaskMetric isDragging={isDragging} level={level} />
		</>
	);
};

export {
	RoadmapBar,
	RoadmapBarContent,
	RoadmapBarIcon,
	RoadmapDependencyHandlers,
	RoadmapDateLabels,
};
export type {
	BarContentRenderers,
	BarContentProps,
	BarContentState,
	BarIconRendererProps,
	BarDependencyHandlerRendererProps,
	BarDependencyHandlerRendererState,
	BarDateLabelRendererProps,
};

const focusedBarStyles = xcss({
	borderRadius: 'border.radius',
	boxSizing: 'border-box',
	width: '100%',
	height: '100%',

	':focus-visible': {
		outline: `2px solid ${token('color.border.focused', B100)}`,
		outlineOffset: 'space.025',
	},
});

const barContentOverlayStyles = xcss({
	padding: '0',
	paddingLeft: 'space.075',
	paddingRight: 'space.075',
	position: 'absolute',
	top: '0',
	left: '0',
	width: '100%',
	height: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	pointerEvents: 'none',
});

const barContentWrapperStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	pointerEvents: 'auto',
});
