/** @jsx jsx */
import React, { memo } from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { zIndex } from '../../../common/constants';
import { useListWidth } from '../../../common/context/list/index.tsx';
import { secondaryBorder, secondaryBorderColor } from '../../../common/styled/timeline.tsx';

const containerStyles = css({
	position: 'absolute',
	top: 0,
	bottom: 0,
	left: 0,
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.BORDER,
	pointerEvents: 'none',
});

const listContainerStyles = css({
	position: 'sticky',
	boxSizing: 'border-box',
	left: 0,
	height: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRight: `2px solid ${secondaryBorderColor}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderLeft: secondaryBorder,
	borderRadius: `${token('border.radius', '3px')} 0 0 0`,
});

// NOTE: The borders here by nature are static overlay as they do not scroll with the Timeline
// However in Firefox / Safari, the elastic over-scroll feature needs the scrolling context to produce the bounce-back effect
// As a result, this component needs to be rendered inside the custom scrollbar instead of being rendered together with other static overlays
const Borders = () => {
	const [listWidth] = useListWidth();

	return (
		<div css={containerStyles}>
			<div css={listContainerStyles} style={{ width: `${listWidth}px` }} />
		</div>
	);
};

export default memo<{}>(Borders);
