import React, { memo } from 'react';
import { useIntl } from 'react-intl-next';
import { HeadingItem } from '@atlaskit/menu';
import { Box, xcss } from '@atlaskit/primitives';
import { listItemMenu } from '../../../../../../../common/constants';
import { useListItemMenuOptions } from '../../../../../../../common/context/list/index.tsx';
import type { SingleSelectedOptionsProps } from '../../types';
import { renderOverrideLabel } from '../../utils';
import CustomOptions from '../custom-options';
import ItemSectionOptions from '../item-section-options';
import messages from '../messages';

const SingleSelectOptions = ({
	issuesSectionOptions,
	customOtherSections,
	id,
	parentId,
	level,
	depth,
	isCreateChildEnabled,
	isCreateSiblingEnabled,
	renderListItemMenuOption,
	onInlineCreateClicked,
	onDrop,
	setCloseMenu,
	setInitialFocusRef,
	setOpenModal,
	onCreateTriggerClick,
}: SingleSelectedOptionsProps) => {
	const [{ overrideLabels }] = useListItemMenuOptions();
	const { formatMessage } = useIntl();
	const headingLabel =
		renderOverrideLabel(formatMessage, overrideLabels[listItemMenu.ITEMS_SECTION]) ||
		formatMessage(messages.itemsSectionHeader);
	return (
		<>
			{issuesSectionOptions.length > 0 && (
				<Box testId="roadmap.timeline-table.main.content.list.list-item.meatballs-button.meatball-popup.single-select-options.items-section">
					<Box xcss={headingWrap}>
						<HeadingItem testId="roadmap.timeline-table.main.content.list.list-item.meatballs-button.meatball-popup.single-select-options.heading">
							{headingLabel}
						</HeadingItem>
					</Box>
					<ItemSectionOptions
						issuesSectionOptions={issuesSectionOptions}
						id={id}
						parentId={parentId}
						level={level}
						depth={depth}
						isCreateChildEnabled={isCreateChildEnabled}
						isCreateSiblingEnabled={isCreateSiblingEnabled}
						onInlineCreateClicked={onInlineCreateClicked}
						setInitialFocusRef={setInitialFocusRef}
						onDrop={onDrop}
						renderListItemMenuOption={renderListItemMenuOption}
						onCreateTriggerClick={onCreateTriggerClick}
						setCloseMenu={setCloseMenu}
						setOpenModal={setOpenModal}
					/>
				</Box>
			)}
			<CustomOptions
				id={id}
				customOtherSections={customOtherSections}
				onCreateTriggerClick={onCreateTriggerClick}
				setCloseMenu={setCloseMenu}
				setInitialFocusRef={issuesSectionOptions.length === 0 ? setInitialFocusRef : undefined}
				setOpenModal={setOpenModal}
				renderListItemMenuOption={renderListItemMenuOption}
			/>
		</>
	);
};

const headingWrap = xcss({
	marginTop: 'space.250',
	marginBottom: 'space.075',
});

export default memo<SingleSelectedOptionsProps>(SingleSelectOptions);
