import type { ItemId } from '../../../types/item.tsx';
import type { State } from '../types';

export const getIsTableEmpty = (state: State) => state.isTableEmpty;
export const getIsItemSelected = (
	{ itemsSelectionState }: State,
	id: ItemId,
): boolean | undefined => (itemsSelectionState ? itemsSelectionState[id] ?? false : undefined);
export const getIsItemMultiSelected = (
	{ itemsSelectionState, itemsSelectionCount }: State,
	id: ItemId,
): boolean | undefined => {
	if (!itemsSelectionState || itemsSelectionCount === undefined) {
		return undefined;
	}

	return (itemsSelectionCount > 1 && itemsSelectionState[id]) || false;
};
export const getItemsSelectionCount = (state: State) => state.itemsSelectionCount;
export const getItems = (state: State) => state.items;
export const getItemIndexes = (state: State) => state.itemIndexes;
export const getItemsContainerStyles = (state: State) => ({
	itemsBackground: state.itemsBackground,
	totalHeight: state.totalHeight,
});
export const getItemHeight = (state: State) => state.itemHeight;
export const getItemsCount = (state: State) => state.items.length;
export const getAriaRowCount = (state: State) => state.ariaRowCount;
export const getHasActiveCreate = (state: State): boolean => state.createItemAnchor !== undefined;
export const getCreateItemAnchor = (state: State) => state.createItemAnchor;
