import merge from 'lodash/merge';
import { PURPLE } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import type { DeriveFieldsHash } from '@atlassian/jira-software-roadmap-model/src/configuration/index.tsx';
import type { Issue } from '@atlassian/jira-software-roadmap-model/src/issue/index.tsx';

const LABELS_FIELD_ID = 'labels';
const DUE_DATE_FIELD_ID = 'duedate';
const VERSION_FIELD_ID = 'fixVersions';
const COMPONENT_FIELD_ID = 'components';

export const derivedFieldsToIssueContext = (
	derivedFieldsHash: DeriveFieldsHash,
	sprintCustomFieldId: string,
): Partial<Issue> => {
	const resultIssueContext: Partial<Issue> = {};

	Object.keys(derivedFieldsHash).forEach((fieldName: string) => {
		switch (fieldName) {
			case LABELS_FIELD_ID:
				resultIssueContext.labels = derivedFieldsHash[fieldName];
				break;
			case DUE_DATE_FIELD_ID:
				resultIssueContext.dueDate = {
					value: Number(derivedFieldsHash[fieldName][0]) || undefined,
				};
				break;
			case VERSION_FIELD_ID:
				// NOTE: ignore versions and components because these fields may not be uniquely referenced in the JQL,
				// and cause errors when doing a filtered create
				// resultIssueContext.versionIds = derivedFieldsHash[fieldName];
				break;
			case COMPONENT_FIELD_ID:
				// resultIssueContext.componentIds = derivedFieldsHash[fieldName];
				break;
			case sprintCustomFieldId:
				resultIssueContext.sprintIds = { value: derivedFieldsHash[fieldName] };
				break;
			default:
				break;
		}
	});

	return resultIssueContext;
};

export const mergeIssueContexts = (...contexts: Partial<Issue>[]): Issue => {
	const defaultContext: Issue = {
		inCreation: true,
		key: undefined,
		summary: { value: '' },
		startDate: { value: undefined },
		dueDate: { value: undefined },
		status: undefined,
		color: { value: PURPLE },
		assignee: undefined,
		issueTypeId: '',
		parentId: { value: '' },
		dependencies: [],
		rank: '1',
		labels: [],
		sprintIds: { value: [] },
		versionIds: [],
		componentIds: [],
		resolved: false,
		flagged: false,
	};
	// `merge` will overwrite previous value, but for multiple value fields, we want to concat instead
	// NOTE: ignore versions and components because these fields may not be uniquely referenced in the JQL,
	// and cause errors when doing a filtered create
	const contextsWithoutMultipleValueFields = contexts.map<Partial<Issue>>(
		({ labels, ...remainingFields }) => ({ ...remainingFields }),
	);
	const resultIssue: Issue = merge(defaultContext, ...contextsWithoutMultipleValueFields);
	// concat multiple value fields
	contexts.forEach(({ labels = [] }) => {
		resultIssue.labels.push(...labels);
	});

	return resultIssue;
};
