import React, { memo } from 'react';
import { useListWidth } from '../../common/context/list/index.tsx';
import type { RenderChartOverlay } from '../../renderers';
import ChartOverlay from './chart-overlay';
import InlineIndicators from './inline-indicators';

type Props = {
	renderChartOverlay: RenderChartOverlay;
};

const StaticOverlay = ({ renderChartOverlay }: Props) => {
	const [listWidth] = useListWidth();

	return (
		<>
			<InlineIndicators />
			<ChartOverlay listWidth={listWidth} renderChartOverlay={renderChartOverlay} />
		</>
	);
};

StaticOverlay.displayName = 'StaticOverlay';
StaticOverlay.whyDidYouRender = true;

export default memo<Props>(StaticOverlay);

export { default as Borders } from './borders';
