import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { ExportTableProps } from '@atlassian/jira-software-roadmap-image-export/src/export/types.tsx';
import type { Item } from '@atlassian/jira-software-roadmap-timeline-table/src/common/types/item.tsx';

export type FiltersAnalyticData = {
	assignees: boolean;
	issueTypes: boolean;
	statuses: boolean;
	text: boolean;
	labels: boolean;
	versions: boolean;
	quickFilters?: boolean;
	customFilters?: boolean;
	isClassic: boolean;
	goals?: boolean;
};

export type RoadmapImageExportMetrics = {
	filters: FiltersAnalyticData;
	visibleEpicsCount: number;
	visibleStoriesCount: number;
	issuesCount: number;
};

export const getRoadmapImageExportMetrics = (
	shareTableProps: ExportTableProps,
	issueIds: IssueId[],
	filtersAnalyticsData: FiltersAnalyticData,
): RoadmapImageExportMetrics => {
	let visibleStoriesCount = 0;

	const { items, expandedItems } = shareTableProps;

	items.forEach((item: Item) => {
		if (expandedItems[item.id]) {
			visibleStoriesCount += item.childItems?.length ?? 0;
		}
	});

	const visibleEpicsCount = items.length;

	return {
		filters: filtersAnalyticsData,
		visibleEpicsCount,
		visibleStoriesCount,
		issuesCount: issueIds.length,
	};
};
