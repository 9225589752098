import React, { useCallback, type MouseEvent, type KeyboardEvent } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { isParentLevel } from '@atlassian/jira-software-roadmap-model/src/hierarchy/index.tsx';
import traceUFOPress from '@atlassian/react-ufo/trace-press';
import messages from './messages';
import type { Props } from './types';

const EditDependencyOption = ({
	areDependenciesSupported,
	isChildIssuePlanningEnabled,
	level,
	setOpenModal,
	Option,
}: Props) => {
	const { formatMessage } = useIntl();

	const isDisabled = !(
		areDependenciesSupported &&
		(isChildIssuePlanningEnabled || isParentLevel(level))
	);

	const onClick = useCallback(
		(event: MouseEvent | KeyboardEvent) => {
			event.preventDefault();
			traceUFOPress('timeline-edit-dependency-option-clicked', event.timeStamp);
			setOpenModal(event);
		},
		[setOpenModal],
	);

	return (
		<Option
			isDisabled={isDisabled}
			label={formatMessage(messages.editDependencies)}
			onClick={onClick}
		/>
	);
};

export default EditDependencyOption;
