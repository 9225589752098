import React, { useRef, useCallback, type ReactNode } from 'react';
import { getLongTasksMetrics } from '@atlassian/jira-common-long-task-metrics';
import { genericReporter } from '@atlassian/jira-common-long-task-metrics/src/reporters/software-generic';
import { scrollReporterWithSlo } from '@atlassian/jira-common-long-task-metrics/src/reporters/software-scroll-slo';
import type { ObservationData } from '@atlassian/jira-common-long-task-metrics/src/types';
import { SCROLLED } from '../../constants';
import { useHeaderState } from '../../context/header/index.tsx';
import { useItemsContainerStyles } from '../../context/items/index.tsx';
import {
	ScrollMetaProviderSweetState,
	useScrollMetaActions,
} from '../../context/scroll-meta/main.tsx';
import { useSideEffectMarshal } from '../../context/side-effect-marshal/index.tsx';
import { useResizeObserver } from '../../utils/use-resize-observer';
import FPSMetric from './fps-metric';
import { useIsScrolling, type ScrollingState } from './use-is-scrolling';

type Props = {
	scope: string;
	scrollElement: HTMLElement | null;
	children: ReactNode;
};

const start = (scope: string) => {
	getLongTasksMetrics(SCROLLED).start(scope);
};

const stop = (scope: string) => {
	let reporter: (observationData: ObservationData, extraProps: { [key: string]: boolean }) => void =
		genericReporter;

	reporter = scrollReporterWithSlo();

	getLongTasksMetrics(scope).stop(reporter);
};

/**
 * SCROLL LONG TASK SLO METRIC
 */
const SCROLL_COUNT_THRESHOLD = 5;

const Container = ({ scope, scrollElement }: { scope: string; scrollElement: HTMLElement }) => {
	const [{ headerHeight }] = useHeaderState();
	const { onScrollStateChanged } = useSideEffectMarshal();
	const [, { updateScrollMeta, updateScrollPosition }] = useScrollMetaActions();
	const [{ totalHeight }] = useItemsContainerStyles();

	const isScrolling = useRef(false);
	const scrollCount = useRef(0);

	const getAndUpdateScrollPosition = useCallback(() => {
		const { scrollTop, clientHeight } = scrollElement;
		const tableHeight = totalHeight + headerHeight;
		const scrollBottom = Math.max(0, tableHeight - (scrollTop + clientHeight));

		updateScrollPosition({
			scrollTop,
			scrollBottom,
		});
	}, [scrollElement, totalHeight, updateScrollPosition, headerHeight]);

	const onScrollChange = useCallback(
		(scrollingState: ScrollingState) => {
			if (isScrolling.current !== scrollingState.isScrollingY) {
				if (
					// Send metric on every Nth scroll interaction
					scrollCount.current === SCROLL_COUNT_THRESHOLD
				) {
					if (scrollingState.isScrollingY) {
						start(scope);
					} else {
						stop(scope);
						scrollCount.current = 0;
					}
					isScrolling.current = scrollingState.isScrollingY;
				} else {
					scrollCount.current += 1;
				}
			}

			updateScrollMeta(scrollingState);

			if (!scrollingState.isScrollingY) {
				getAndUpdateScrollPosition();
			}

			onScrollStateChanged(scrollingState.isScrollingY);
		},
		[updateScrollMeta, getAndUpdateScrollPosition, onScrollStateChanged, scope],
	);

	useIsScrolling(scrollElement, onScrollChange);
	useResizeObserver(scrollElement, getAndUpdateScrollPosition);

	return null;
};

const ScrollMetaContainer = ({ scope, scrollElement, children }: Props) => (
	<ScrollMetaProviderSweetState>
		{scrollElement && <Container scope={scope} scrollElement={scrollElement} />}
		{scrollElement && <FPSMetric scope={scope} scrollElement={scrollElement} />}
		{children}
	</ScrollMetaProviderSweetState>
);

export default ScrollMetaContainer;
