import {
	createStore,
	createContainer,
	createHook,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';
import { MONTHS } from '../../constants';
import type { TimelineMode, TimelineDuration, ColumnDuration } from '../../types/timeline';
import { getColumnDurations, getColumnPositions } from '../../utils/columns.tsx';
import { getTimelineWidth, getTimelinePositionFromDate } from '../../utils/timeline.tsx';
import { actions, type Actions } from './actions';

type Props = {
	timelineMode: TimelineMode;
	timelineDuration: TimelineDuration;
	timelineOrigin: number;
	today: number;
};

type State = Props & {
	timelineWidth: number;
	timelineOriginPosition: number;
	columnDurations: ColumnDuration[];
	columnPositions: [number, number][];
};

const initialState: State = {
	/* Raw data */
	today: 0,
	timelineMode: MONTHS,
	timelineDuration: {
		startMilliseconds: 0,
		endMilliseconds: 0,
		totalMilliseconds: 0,
	},
	timelineOrigin: 0,
	/* Derived data */
	timelineWidth: 0,
	timelineOriginPosition: 0,
	columnDurations: [],
	columnPositions: [],
};

export const timelineStore = createStore<State, Actions>({
	initialState,
	actions,
	name: 'timeline-table.timeline',
});

export const useTimelineState = createHook(timelineStore);

const handleState =
	() =>
	({ setState }: StoreActionApi<State>, timelineData: Props) => {
		const { timelineMode, timelineDuration, timelineOrigin } = timelineData;

		const timelineWidth = getTimelineWidth(timelineMode, timelineDuration.totalMilliseconds);
		const columnDurations = getColumnDurations(timelineMode, timelineDuration);
		const columnPositions = getColumnPositions(timelineDuration, columnDurations);
		const timelineOriginPosition = getTimelinePositionFromDate(
			timelineDuration,
			timelineWidth,
			timelineOrigin,
		);

		setState({
			...timelineData,
			timelineWidth,
			timelineOriginPosition,
			columnDurations,
			columnPositions,
		});
	};

export const TimelineProviderSweetState = createContainer<State, Actions, Props>(timelineStore, {
	onInit: handleState,
	onUpdate: handleState,
});
