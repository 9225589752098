import { isBaseLevel } from '@atlassian/jira-software-roadmap-model/src/hierarchy/index.tsx';
import type { Sprint } from '@atlassian/jira-software-roadmap-model/src/sprint/index.tsx';
import type { EditSprintOptionParams } from '../../../../model/list-item-menu-option';

export const getEditSprintOptionParamsPure = (
	level: number,
	isCurrentlyAssignedToSprints: boolean,
	sprintsAssignable: Sprint[],
): EditSprintOptionParams => ({
	isSprintEditable:
		isBaseLevel(level) && (isCurrentlyAssignedToSprints || sprintsAssignable.length > 0),
	isSprintClearable: isCurrentlyAssignedToSprints,
	sprints: sprintsAssignable,
});
