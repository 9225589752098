/** @jsx jsx */
import React, { useState, useCallback, memo } from 'react';
import { css, jsx } from '@compiled/react';
import { B100 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { CHART_COLUMN_COORDINATE } from '../../../../common/constants';
import { useSideEffectMarshal } from '../../../../common/context/side-effect-marshal';
import { useGridCell } from '../../../../common/context/side-effect-marshal/focus-marshal/use-grid-cell';
import { getRowClassName } from '../../../../common/context/side-effect-marshal/style-marshal/utils.tsx';
import { useTimelineState } from '../../../../common/context/timeline/index.tsx';
import type { ItemId } from '../../../../common/types/item.tsx';
import type { RenderChartItemContent } from '../../../../renderers';
import { getContainerHeight } from '../../../utils/get-container-height.tsx';

export type Props = {
	id: ItemId;
	parentId: ItemId | undefined;
	depth: number;
	itemHeight: number;
	backgroundColor: string;
	renderChartItemContent: RenderChartItemContent;
};

const itemBaseStyles = css({
	display: 'flex',
	alignItems: 'center',
	transition: 'background-color 100ms linear',
	'&:focus': {
		outline: 'none',
	},
	'&:focus-visible': {
		boxShadow: `inset 0 0 0 2px ${token('color.border.focused', B100)}`,
	},
});

const chartItemContainer = css({
	position: 'relative',
	overflow: 'hidden',
});

const ChartItem = ({
	id,
	parentId,
	depth,
	itemHeight,
	backgroundColor,
	renderChartItemContent,
}: Props) => {
	const [isHovered, setIsHovered] = useState(false);
	const { onRowMouseEnter, onRowMouseLeave } = useSideEffectMarshal();
	const [{ timelineWidth }] = useTimelineState();
	const cellRef = useGridCell(id, CHART_COLUMN_COORDINATE);

	const onMouseEnter = useCallback(() => {
		onRowMouseEnter(id);
		setIsHovered(true);
	}, [id, onRowMouseEnter]);

	const onMouseLeave = useCallback(() => {
		onRowMouseLeave(id);
		setIsHovered(false);
	}, [id, onRowMouseLeave]);

	const rendererProps = { id };
	const rendererState = { isVirtual: false, isHovered };

	const height = getContainerHeight({ itemHeight, depth });

	return (
		<div
			ref={cellRef}
			role="gridcell"
			tabIndex={-1}
			data-testid={`roadmap.timeline-table.components.chart-item.container-${id}`}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={getRowClassName(id, parentId).className}
			css={[itemBaseStyles, chartItemContainer]}
			style={{
				width: `${timelineWidth}px`,
				height: `${height}`,
				backgroundColor,
			}}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			{...(fg('jsw_roadmaps_timeline-row-highlight-bar-focus')
				? { onFocus: onMouseEnter, onBlur: onMouseLeave }
				: {})}
		>
			{renderChartItemContent(rendererProps, rendererState)}
		</div>
	);
};

ChartItem.displayName = 'ChartItem';
ChartItem.whyDidYouRender = true;

export default memo<Props>(ChartItem);
