/** @jsx jsx */
import React, { useCallback, type MouseEvent, type KeyboardEvent } from 'react';
import { css, jsx } from '@compiled/react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import {
	WEEKS,
	MONTHS,
	QUARTERS,
} from '@atlassian/jira-software-roadmap-timeline-table/src/common/constants.tsx';
import { useTimelineState } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/timeline/index.tsx';
import type { TimelineMode } from '@atlassian/jira-software-roadmap-timeline-table/src/common/types/timeline.tsx';
import traceUFOPress from '@atlassian/react-ufo/trace-press';
import messages from './messages';
import TimelineModeSwitcherSkeleton from './skeleton';
import type { ModeDescriptor } from './types';

const LazyTimelineModeSwitcher = lazyAfterPaint(() =>
	import(
		/* webpackChunkName: "async-timeline-mode-switcher" */ '@atlassian/jira-aais-timeline-toolbar'
	).then(({ TimelineModeSwitcher }) => TimelineModeSwitcher),
);

const MODE_DESCRIPTORS: ModeDescriptor[] = [
	{
		type: WEEKS,
		title: messages.weeks,
	},
	{
		type: MONTHS,
		title: messages.months,
	},
	{
		type: QUARTERS,
		title: messages.quarters,
	},
];

type Props = {
	onClick: (value: TimelineMode, event?: UIAnalyticsEvent) => void;
	withContainerStyles?: boolean;
};

const timelineModeContainerStyles = css({
	display: 'inline-flex',
	backgroundColor: token('elevation.surface.overlay', N0),
	boxShadow: token('elevation.shadow.overlay', '0 4px 4px rgba(0, 0, 0, 0.25)'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
	minWidth: '160px',
	padding: token('space.050', '4px'),
});

const RoadmapTimelineMode = ({ onClick, withContainerStyles = false }: Props) => {
	const [{ timelineMode }] = useTimelineState();

	const changeMode = useCallback(
		(mode: string, event: MouseEvent<Element> | KeyboardEvent<Element>) => {
			traceUFOPress('timeline-mode', event.timeStamp);

			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			onClick(mode as TimelineMode);
		},
		[onClick],
	);

	const element = (
		<Placeholder name="timeline-mode-switcher" fallback={<TimelineModeSwitcherSkeleton />}>
			<LazyTimelineModeSwitcher
				modes={MODE_DESCRIPTORS}
				onModeChange={changeMode}
				currentMode={timelineMode}
			/>
		</Placeholder>
	);

	return withContainerStyles ? <div css={timelineModeContainerStyles}>{element}</div> : element;
};

export { RoadmapTimelineMode };
