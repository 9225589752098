import type { GICPayload } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/types.tsx';

export const ADD_PAYLOAD_GLOBAL_ISSUE_CREATE =
	'state.entities.global-issue-create.ADD_PAYLOAD_GLOBAL_ISSUE_CREATE';

export type AddPayloadGlobalIssueCreateAction = {
	type: typeof ADD_PAYLOAD_GLOBAL_ISSUE_CREATE;
	payload: Partial<GICPayload> | undefined;
};

export const addPayloadGlobalIssueCreate = (
	payload?: Partial<GICPayload>,
): AddPayloadGlobalIssueCreateAction => ({
	type: ADD_PAYLOAD_GLOBAL_ISSUE_CREATE,
	payload,
});

export const CLEAR_PAYLOAD_GLOBAL_ISSUE_CREATE =
	'state.entities.global-issue-create.CLEAR_PAYLOAD_GLOBAL_ISSUE_CREATE';

export type ClearPayloadGlobalIssueCreateAction = {
	type: typeof CLEAR_PAYLOAD_GLOBAL_ISSUE_CREATE;
};

export const clearPayloadGlobalIssueCreate = (): ClearPayloadGlobalIssueCreateAction => ({
	type: CLEAR_PAYLOAD_GLOBAL_ISSUE_CREATE,
});
