import { createSelector } from 'reselect';
import type { TimelineMode } from '@atlassian/jira-software-roadmap-timeline-table/src/common/types/timeline.tsx';
import { getIssueLimit } from '../../feature-flags';
import { hasMacroConfig } from '../app/selectors';
import {
	isDependenciesVisible,
	isProgressVisible,
	getTimelineMode,
	getNumJQLFiltersApplied,
} from './filters';
import { getFilteredIssueCount } from './issues';
import { getIsReleasesVisible } from './versions';

export type InteractionPerformanceExtraData = {
	timelineMode: TimelineMode;
	filteredIssueCount: number;
	numJQLFiltersApplied: number;
	dependenciesVisible: boolean;
	progressVisible: boolean;
	releasesVisible: boolean;
	isMacro: boolean;
	isGetIssueLimit5kEnabled: boolean;
};

/**
 * getInteractionPerformanceExtraData selects data which will be attached to
 * interaction performance metrics.
 */
export const getInteractionPerformanceExtraData = createSelector(
	getTimelineMode,
	getFilteredIssueCount,
	getNumJQLFiltersApplied,
	isDependenciesVisible,
	isProgressVisible,
	getIsReleasesVisible,
	hasMacroConfig,
	(
		timelineMode: TimelineMode,
		filteredIssueCount: number,
		numJQLFiltersApplied: number,
		dependenciesVisible: boolean,
		progressVisible: boolean,
		releasesVisible: boolean,
		isMacro: boolean,
	) => ({
		timelineMode,
		filteredIssueCount,
		numJQLFiltersApplied,
		dependenciesVisible,
		progressVisible,
		releasesVisible,
		isMacro,
		isGetIssueLimit5kEnabled: getIssueLimit() === 5000,
	}),
);
