import { createSelector } from 'reselect';
import keyBy from 'lodash/keyBy';
import type { RoadmapGoalHash } from '@atlassian/jira-software-roadmap-model/src/goals/index.tsx';
import type { State } from '../../types';

export const getIsGoalsLoaded = (state: State): boolean => state.entities.goals.isLoaded;
export const getGoalsHash = (state: State): RoadmapGoalHash => state.entities.goals.hash;

export const getGoals = createSelector(getGoalsHash, (goalHash) =>
	Object.values(goalHash).map(({ goal }) => goal),
);

export const getGoalsByKeyHash = createSelector(getGoalsHash, (goals) =>
	keyBy(Object.values(goals), (roadmapGoal) => roadmapGoal.goal?.key),
);

export const getUniqueGoalIds = createSelector(getGoalsHash, (goalHash) => Object.keys(goalHash));
