import { defineSimpleStorage } from '@atlassian/jira-common-storage-redux/src/config.tsx';
import type { State } from '../../../state/types';
import { setWarningsVisibility } from '../../../state/ui/views/actions';
import { isWarningsVisibleInView } from '../../../state/ui/views/selectors';

export default defineSimpleStorage<State, boolean | null>(
	() => 'isWarningsVisible',
	(isVisible: boolean | null) => (isVisible === null ? [] : [setWarningsVisibility(isVisible)]),
	(state) => isWarningsVisibleInView(state),
);
