import { defineSimpleStorage } from '@atlassian/jira-common-storage-redux/src/config.tsx';
import type { UserConfiguration } from '@atlassian/jira-software-roadmap-services/src/critical-data/types.tsx';
import { setIssuePanelRatio } from '../../../state/settings/actions';
import { getIssuePanelRatio } from '../../../state/settings/selectors';
import type { State } from '../../../state/types';

type IssuePanelRatio = UserConfiguration['issuePanelRatio'];

// @ts-expect-error - Type 'number | null | undefined' does not satisfy the constraint 'GenericStorageValue'.
export default defineSimpleStorage<State, IssuePanelRatio>(
	() => 'issuePanelRatio',
	(issuePanelRatio: IssuePanelRatio) =>
		issuePanelRatio ? [setIssuePanelRatio(issuePanelRatio)] : [],
	(state: State) => getIssuePanelRatio(state) || null,
);
