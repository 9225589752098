import type { ColorSchema, Color } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import { transformColorToTheme } from '@atlassian/jira-issue-epic-color/src/common/utils.tsx';
import type { BarTheme } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/chart-item.tsx';

const colorToBarTheme = (color: ColorSchema): BarTheme => ({
	background: color.primary,
	gradient: color.primaryGradient,
	handle: color.secondary,
	hasContrastSecondaryGradient: color.hasContrastSecondaryGradient,
});

const barThemes: Record<Color, BarTheme> = transformColorToTheme(colorToBarTheme);

export const getBarTheme = (color: Color): BarTheme => barThemes[color] || barThemes.PURPLE;
