import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/map';
import type { ActionsObservable } from 'redux-observable';
import type { Query } from '@atlassian/jira-software-roadmap-model/src/router/index.tsx';
import type { Dependencies } from '../../model/common';
import type { State } from '../../state/types';
import {
	SET_SELECTED_ISSUE,
	type SetSelectedIssueAction as Action,
} from '../../state/ui/panel/actions';
import type { StateEpic } from '../common/types';
import updateQuery from '../common/update-query';

/* This epic sets the selected issue by updating the relevant url parameter.
 * You can pass undefined to effectively un-select the current issues.
 */
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((
	action$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
	{ router: { push } }: Dependencies,
) =>
	action$.ofType(SET_SELECTED_ISSUE).map((action: Action) => {
		const state = store.getState();
		const issueKey = action.payload;

		return updateQuery(push, state, (query: Query) => ({
			...query,
			selectedIssue: issueKey,
		}));
	})) as StateEpic;
