import { connect } from '@atlassian/jira-react-redux';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { ListItemMenuOptionProps } from '@atlassian/jira-software-roadmap-timeline-table/src/renderers/index.tsx';
import type { ChangeColorOptionParams } from '../../../../../model/list-item-menu-option';
import { getChangeColorOptionParams } from '../../../../../state/selectors/list-item-menu-option';
import type { State } from '../../../../../state/types';
import ChangeColorOption from './view';

type OwnProps = {
	id: IssueId;
	Option: ListItemMenuOptionProps['Option'];
	setCloseMenu: ListItemMenuOptionProps['setCloseMenu'];
};

type StateProps = ChangeColorOptionParams;

const mapStateToProps = (state: State, { id }: OwnProps): StateProps => {
	const changeColorOptionParams = getChangeColorOptionParams(state, id);

	return {
		...changeColorOptionParams,
	};
};

export default connect(mapStateToProps)(ChangeColorOption);
