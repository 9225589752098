import { createSelector } from 'reselect';
import keyBy from 'lodash/keyBy';
import toArray from 'lodash/toArray';
import type { IssueStatusCategoryId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StatusCategoryHash } from '@atlassian/jira-software-roadmap-model/src/status/index.tsx';
import { DONE } from '../../../model/status';
import type { State } from '../../types';

export const getFullStatusCategoriesHash = (state: State): StatusCategoryHash =>
	state.entities.statusCategories.hash;

export const getStatusCategoriesKeyHash = createSelector(
	getFullStatusCategoriesHash,
	(statusCategories: StatusCategoryHash) => keyBy(toArray(statusCategories), 'key'),
);

export const getDoneStatusCategoryId = createSelector(
	getFullStatusCategoriesHash,
	(statusCategoriesHash: StatusCategoryHash) =>
		Object.keys(statusCategoriesHash).find(
			(id: IssueStatusCategoryId) => statusCategoriesHash[id].key === DONE,
		),
);
