// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import { ROADMAP_PACKAGE_NAME } from '../../../constants';
import type FeedbackType from './index';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyFeedback = lazyAfterPaint<typeof FeedbackType>(
	() => import(/* webpackChunkName: "async-standard-roadmap-feedback" */ './index'),
	{ ssr: false },
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	props: JSX.LibraryManagedAttributes<typeof FeedbackType, ComponentProps<typeof FeedbackType>>,
) => (
	<JSErrorBoundary
		id="async-standard-roadmap-feedback"
		packageName={ROADMAP_PACKAGE_NAME}
		fallback="flag"
	>
		<Placeholder name="standard-roadmap-feedback" fallback={null}>
			<LazyFeedback {...props} />
		</Placeholder>
	</JSErrorBoundary>
);
