import type { Action, MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import { batchActions } from 'redux-batched-actions';
import { Observable } from 'rxjs/Observable';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IssueTypeHash } from '@atlassian/jira-software-roadmap-model/src/issue-type/index.tsx';
import type { Issue } from '@atlassian/jira-software-roadmap-model/src/issue/index.tsx';
import { getCriticalDataObservable } from '@atlassian/jira-software-roadmap-services/src/initialize/critical-data.tsx';
import { getItems } from '@atlassian/jira-software-roadmap-services/src/issues/get.tsx';
import type { IssuesAndUsers } from '@atlassian/jira-software-roadmap-services/src/issues/types.tsx';
import { updateCriticalData } from '../../../state/app/actions';
import { getSourceARI } from '../../../state/app/selectors';
import { getFullIssueTypeHash } from '../../../state/configuration/selectors';
import { removeIssues } from '../../../state/entities/issues/actions';
import type { State } from '../../../state/types';
import { updateIssuesAndUsers } from './update';

export const tryGetIssueFromIssuesAndUsers = (
	issuesAndUsers: IssuesAndUsers,
	issueId: IssueId,
): Issue | undefined => issuesAndUsers.issues.hash[issueId];

export const issueTypesReloadRequired = (
	issuesAndUsers: IssuesAndUsers,
	currentIssueTypes: IssueTypeHash,
): boolean =>
	Object.entries(issuesAndUsers.issues.hash).some(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		([, issue]: [any, any]) => currentIssueTypes[issue.issueTypeId] === undefined,
	);

export const criticalDataReload = (store: MiddlewareAPI<State>): Observable<Action> => {
	getAnalyticsWebClientPromise().then((client) => {
		const analyticsClient = client.getInstance();
		if (!analyticsClient) {
			return;
		}

		analyticsClient.sendOperationalEvent({
			action: 'fetch',
			actionSubject: 'relay operation',
			attributes: {
				key: 'jira.frontend.fe.software.roadmap.critical-data.reload',
				reason: 'issue-types',
			},
			source: 'relayOperation',
		});
	});

	/**
	 * quickFilter request is not required in this getCriticalDataObservable, because it has already been handled independently in the upper layer
	 * i.e. reload-with-sequence and optimistic-updates. The reason is because not all updates require both quickFilter and critical-data reload.
	 */
	return getCriticalDataObservable(getSourceARI(store.getState()), undefined, undefined).map(
		(criticalData) => batchActions([updateCriticalData(criticalData)]),
	);
};

export const reloadIssues = (store: MiddlewareAPI<State>, issueIds: IssueId[]) => {
	if (issueIds.length === 0) {
		return Observable.empty<never>();
	}

	return getItems(getSourceARI(store.getState()), issueIds).flatMap((issues: IssuesAndUsers) => {
		const issueTypes = getFullIssueTypeHash(store.getState());
		if (issueTypesReloadRequired(issues, issueTypes)) {
			return criticalDataReload(store);
		}

		const issueIdsForRemoval = issueIds.filter(
			(issueId) => !tryGetIssueFromIssuesAndUsers(issues, issueId),
		);

		const hasDefinedIssues = issueIds.length !== issueIdsForRemoval.length;

		return hasDefinedIssues
			? Observable.of(updateIssuesAndUsers(store, issues, issueIdsForRemoval))
			: Observable.of(removeIssues(issueIdsForRemoval));
	});
};
