import {
	BASE_HEADER_HEIGHT,
	DEFAULT_HEADER_ROW_ID,
	DEFAULT_SUBHEADER_HEIGHT,
} from '../../constants';
import type { HeaderItem } from '../../types/header.tsx';

export const getDerivedHeaderState = (customHeaderItems: HeaderItem[]) => {
	// There will always be a default header which we should still include in the header state
	const combinedHeaderItems = [
		{ id: DEFAULT_HEADER_ROW_ID, height: BASE_HEADER_HEIGHT },
		...customHeaderItems,
	];

	// Make it so we don't require cascading fallback checks
	const sanitizedHeaderItems = combinedHeaderItems.map(({ id, height }) => ({
		id,
		height: height ?? DEFAULT_SUBHEADER_HEIGHT,
	}));

	const headerItemsOffsetTop: Record<string, number> = {};
	const headerHeight = sanitizedHeaderItems.reduce((total, { id, height }) => {
		headerItemsOffsetTop[id] = total;
		return total + height;
	}, 0);

	return { headerItems: sanitizedHeaderItems, headerHeight, headerItemsOffsetTop };
};
