import isNumber from 'lodash/isNumber';
// eslint-disable-next-line jira/restricted/moment
import moment from 'moment';
import {
	MAP_FROM_SERVER_COLOR,
	MAP_FROM_SERVER_CLASSIC_COLOR,
} from '@atlassian/jira-issue-epic-color/src/common/constants.tsx';
import {
	toFrontendColor,
	classicToFrontendColor,
} from '@atlassian/jira-issue-epic-color/src/common/utils.tsx';
import {
	SUMMARY_TYPE,
	DUE_DATE_TYPE,
	STATUS_TYPE,
	ASSIGNEE_TYPE,
	LABELS_TYPE,
	FIX_VERSIONS_TYPE,
	ISSUE_TYPE as ISSUE_TYPE_TYPE,
	COMPONENTS_TYPE,
	PARENT_TYPE,
} from '@atlassian/jira-platform-field-config';
import type {
	IssueId,
	ComponentId,
	IssueTypeId,
	VersionId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import type { ComponentsHash } from '@atlassian/jira-software-roadmap-model/src/component/index.tsx';
import { ISO_DATE_FORMAT } from '@atlassian/jira-software-roadmap-model/src/datetime/index.tsx';
import {
	SUMMARY,
	START_DATE,
	DUE_DATE,
	COLOR,
	STATUS,
	ASSIGNEE,
	LABELS,
	PARENT_ID,
	SPRINT_IDS,
	VERSION_IDS,
	COMPONENT_IDS,
	ISSUE_TYPE,
	type FieldName,
} from '@atlassian/jira-software-roadmap-model/src/issue-view/index.tsx';
import type { SprintId } from '@atlassian/jira-software-roadmap-model/src/sprint/index.tsx';
import type { Status } from '@atlassian/jira-software-roadmap-model/src/status/index.tsx';

// inconsistency field update, some field updates only trigger 'FIELD_CHANGED' and doesn't trigger 'FIELD_CHANGE_REQUESTED', such as 'status'
export const isFieldUpdateWithoutRequestEvent = (fieldId?: string): boolean =>
	// NOTE: Since "assign to me" won't trigger 'FIELD_CHANGE_REQUESTED', we have to handle all assignee cases here
	{
		const fields: string[] = [ASSIGNEE, STATUS, SUMMARY];
		return fieldId !== undefined && fields.includes(fieldId);
	};

export type AssigneeFieldValueType = {
	accountId: string;
} | null;
export type VersionsFieldValueType =
	| {
			fromCache: boolean;
			id: number;
			name: string;
	  }[]
	| null;
export type ComponentsFieldValueType =
	| {
			fromCache: boolean;
			id: number;
			name: string;
	  }[]
	| null;
export type LabelsFieldValueType = string[] | null;
export type IssueTypeFieldValueType = string;

export const mapIssueViewFieldToRoadmapField = (
	fieldId: string | undefined,
	startDateCustomFieldId: string,
	colorFieldId: string | null,
	epicLinkCustomField: string | undefined,
	sprintCustomFieldId: string,
): FieldName | undefined => {
	if (!fieldId) {
		return undefined;
	}

	if (colorFieldId === fieldId) {
		return COLOR;
	}

	switch (fieldId) {
		case SUMMARY_TYPE:
			return SUMMARY;
		case DUE_DATE_TYPE:
			return DUE_DATE;
		case startDateCustomFieldId:
			return START_DATE;
		case STATUS_TYPE:
			return STATUS;
		case ASSIGNEE_TYPE:
			return ASSIGNEE;
		case PARENT_TYPE:
		case epicLinkCustomField:
			return PARENT_ID;
		case LABELS_TYPE:
			return LABELS;
		case FIX_VERSIONS_TYPE:
			return VERSION_IDS;
		case COMPONENTS_TYPE:
			return COMPONENT_IDS;
		case sprintCustomFieldId:
			return SPRINT_IDS;
		case ISSUE_TYPE_TYPE:
			return ISSUE_TYPE;
		default:
			return undefined;
	}
};

const convertDateFieldValue = (fieldName: FieldName, value: string | null): number | undefined => {
	if (value === null || value === '') {
		return undefined;
	}

	if (typeof value !== 'string' && !isNumber(value)) {
		throw new Error(
			`Returned field value for field "${fieldName}" is not primitive: "${typeof value}"`,
		);
	}

	const date = moment.utc(value, ISO_DATE_FORMAT);

	if (date.isValid()) {
		return date.valueOf();
	}

	throw new Error(`Invalid date "${value}" was returned for field "${fieldName}"`);
};

const convertColorFieldValue = (fieldName: FieldName, value: string) => {
	if (value in MAP_FROM_SERVER_COLOR) {
		return toFrontendColor(value);
	}

	if (value in MAP_FROM_SERVER_CLASSIC_COLOR) {
		return classicToFrontendColor(value);
	}

	throw new Error(`Invalid color value "${value}" for "${fieldName}" field`);
};

const convertSummaryFieldValue = (value: string | null): string => {
	if (value === null) {
		throw new Error('Invalid summary value "null", should be string instead');
	}

	if (typeof value !== 'string' && !isNumber(value)) {
		throw new Error(`Returned field value for field "summary" is not primitive: "${typeof value}"`);
	}

	return value;
};

const convertStatusFieldValue = (
	value: {
		id: string;
		name: string;
		statusCategory: {
			id: number;
		};
	} | null,
): Status => {
	if (value === null || !value.id || value.name === null || !value.statusCategory) {
		throw new Error('Invalid status value "null", should be valid status value instead');
	}

	return {
		id: value.id,
		name: value.name,
		statusCategoryId: value.statusCategory.id.toString(),
	};
};

const convertAssigneeFieldValue = (value: AssigneeFieldValueType): string | undefined => {
	if (value === null || value.accountId === null) {
		return undefined;
	}

	return value.accountId;
};

const convertLabelsFieldValue = (value: LabelsFieldValueType): string[] | undefined => {
	if (value === null) {
		return undefined;
	}
	return value;
};

const convertVersionsFieldValue = (value: VersionsFieldValueType): VersionId[] | undefined => {
	if (value === null) {
		return undefined;
	}
	return value.map((version) => version.id.toString());
};

const convertComponentsFieldValue = (
	value: ComponentsFieldValueType,
): ComponentId[] | undefined => {
	if (value === null) return undefined;
	return value.map((component) => component.id.toString());
};

const convertComponentsFieldValueToConfiguration = (
	value: ComponentsFieldValueType,
): ComponentsHash | undefined => {
	if (value === null) return undefined;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return value.reduce<Record<string, any>>((acc, curr) => {
		const id = curr.id.toString();
		const { name } = curr;
		acc[`${id}`] = {
			id,
			name,
		};
		return acc;
	}, {});
};

const convertParentIdFieldValue = (value: { id: string }): IssueId | undefined => {
	if (value === null || value.id === null) {
		return undefined;
	}
	return value.id;
};

const convertSprintFieldValue = (
	value:
		| {
				fromCache: boolean;
				id: number;
				name: string;
				state: string;
		  }[]
		| null,
): SprintId[] | undefined => {
	if (value === null) {
		return undefined;
	}
	return value.map((sprint) => sprint.id.toString());
};

const convertIssueTypeFieldValue = (value: IssueTypeFieldValueType): IssueTypeId | undefined =>
	value;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertIssueViewFieldValue = (fieldName: FieldName, value: any) => {
	switch (fieldName) {
		case COLOR:
			return {
				value: convertColorFieldValue(fieldName, value),
			};
		case START_DATE:
		case DUE_DATE:
			return {
				value: convertDateFieldValue(fieldName, value),
			};
		case SUMMARY:
			return {
				value: convertSummaryFieldValue(value),
			};
		case STATUS:
			return convertStatusFieldValue(value);
		case ASSIGNEE:
			return convertAssigneeFieldValue(value);
		case LABELS:
			return convertLabelsFieldValue(value);
		case PARENT_ID:
			return {
				value: convertParentIdFieldValue(value),
			};
		case SPRINT_IDS:
			return {
				value: convertSprintFieldValue(value),
			};
		case VERSION_IDS:
			return convertVersionsFieldValue(value);
		case COMPONENT_IDS:
			return convertComponentsFieldValue(value);
		case ISSUE_TYPE:
			return convertIssueTypeFieldValue(value);
		default:
			throw new Error(`Unknow field name: "${fieldName}"`);
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertIssueViewFieldValueToConfiguration = (fieldName: FieldName, value: any) => {
	switch (fieldName) {
		case COMPONENT_IDS:
			return convertComponentsFieldValueToConfiguration(value);
		default:
			throw new Error(`Unknow field name: "${fieldName}"`);
	}
};
