import React, { type ReactNode } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { useEnablements } from '../../enablements';
import { useAriaRowCount } from '../../items';
import { useGridContainer, useGridContainerOld } from '../focus-marshal/use-grid-container';

type Props = {
	scope: string;
	ariaLabel: string;
	ariaReadOnly: boolean;
	ariaMultiselectable: boolean | undefined; // TODO: to be removed with FG project_timeline_multi-select_and_checkboxes
	children: ReactNode;
};

export const GridOld = ({
	scope,
	ariaMultiselectable,
	ariaLabel,
	ariaReadOnly,
	children,
}: Props) => {
	const [ariaRowCount] = useAriaRowCount();
	const eventHandlers = useGridContainerOld();
	const [{ isMultiSelectEnabled }] = useEnablements();

	return (
		<div
			role="grid"
			aria-rowcount={ariaRowCount}
			aria-multiselectable={
				fg('project_timeline_multi-select_and_checkboxes')
					? isMultiSelectEnabled || undefined
					: ariaMultiselectable
			}
			aria-label={ariaLabel}
			aria-readonly={ariaReadOnly}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={scope}
			{...eventHandlers}
		>
			{children}
		</div>
	);
};

export const Grid = ({ scope, ariaMultiselectable, ariaLabel, ariaReadOnly, children }: Props) => {
	const [ariaRowCount] = useAriaRowCount();
	const eventHandlers = useGridContainer();
	const [{ isMultiSelectEnabled }] = useEnablements();

	return (
		<div
			role="grid"
			aria-rowcount={ariaRowCount}
			aria-multiselectable={
				fg('project_timeline_multi-select_and_checkboxes')
					? isMultiSelectEnabled || undefined
					: ariaMultiselectable
			}
			aria-label={ariaLabel}
			aria-readonly={ariaReadOnly}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={scope}
			{...eventHandlers}
		>
			{children}
		</div>
	);
};
