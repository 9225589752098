import forEach from 'lodash/forEach';
import has from 'lodash/has';
import type { Hash } from '@atlassian/jira-shared-types/src/general.tsx';
import type { HashDiff } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EMPTY: Record<string, any> = {};

const createInitialized = <Entity,>(hash: Hash<Entity>): HashDiff<Entity> => ({
	added: hash,
	removed: EMPTY,
	changed: EMPTY,
	unchanged: EMPTY,
	hash,
});

const createUnchanged = <Entity,>(hash: Hash<Entity>): HashDiff<Entity> => ({
	added: EMPTY,
	removed: EMPTY,
	changed: EMPTY,
	unchanged: hash,
	hash,
});

const createHashDiff = <T,>(hash: Hash<T>): HashDiff<T> => ({
	removed: {},
	added: {},
	changed: {},
	unchanged: {},
	hash,
});

const getHashDiff = <T,>(prevItems: Hash<T>, items: Hash<T>): HashDiff<T> => {
	const hashDiff = createHashDiff(items);
	forEach(items, (value, key) => {
		if (!has(prevItems, key)) {
			hashDiff.added[key] = value;
		} else if (prevItems[key] !== value) {
			hashDiff.changed[key] = value;
		} else {
			hashDiff.unchanged[key] = value;
		}
	});
	forEach(prevItems, (value, key) => {
		if (!has(items, key)) {
			hashDiff.removed[key] = value;
		}
	});
	return hashDiff;
};

export { createInitialized, createUnchanged, getHashDiff };
