import { createSelector } from 'reselect';
import type {
	ComponentId,
	IssueTypeId,
	VersionId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import type {
	ComponentsHash,
	Component,
} from '@atlassian/jira-software-roadmap-model/src/component/index.tsx';
import {
	type PlanningMode,
	type ProjectConfiguration,
	type RequiredFieldsHash,
	type BoardConfiguration,
	type HierarchyConfiguration,
	SPRINT,
} from '@atlassian/jira-software-roadmap-model/src/configuration/index.tsx';
import type { IssueType } from '@atlassian/jira-software-roadmap-model/src/issue-type/index.tsx';
import {
	type SprintsHash,
	type Sprint,
	type SprintId,
	CLOSED,
	FUTURE,
	ACTIVE,
} from '@atlassian/jira-software-roadmap-model/src/sprint/index.tsx';
import type {
	VersionsHash,
	VersionWithStatus,
} from '@atlassian/jira-software-roadmap-model/src/version/index.tsx';
import type { State } from '../types';
import type { ConfigurationState } from './types';

const getSingleProjectConfiguration = (state: State): ProjectConfiguration =>
	state.configuration.projectConfiguration;

// We are assuming that there will always be exactly 1 project config, otherwise it would have thrown an error earlier
export const getProjectId = createSelector(
	getSingleProjectConfiguration,
	(projectConfig: ProjectConfiguration) => projectConfig.id,
);

const getProjectConfiguration = createSelector(
	getSingleProjectConfiguration,
	(projectConfig: ProjectConfiguration) => projectConfig,
);

export const getStartDateCustomFieldId = (state: State): string =>
	state.configuration.startDateCustomFieldId;

export const getEpicIssueTypeIds = createSelector(
	getProjectConfiguration,
	(projectConfiguration: ProjectConfiguration) => projectConfiguration.epicIssueTypeIds,
);

export const getColorCustomFieldId = (state: State): string =>
	state.configuration.colorCustomFieldId;

export const getEpicLinkCustomFieldId = (state: State): string | undefined =>
	state.configuration.epicLinkCustomFieldId;

export const getEpicNameCustomFieldId = (state: State): string | undefined =>
	state.configuration.epicNameCustomFieldId;

export const getSprintCustomFieldId = (state: State): string =>
	state.configuration.sprintCustomFieldId;

export const getBoardConfiguration = (state: State): BoardConfiguration =>
	state.configuration.boardConfiguration;

export const getFieldsNamesHash = (state: State): Record<string, string> =>
	state.configuration.fields.reduce<Record<string, string>>((acc, field) => {
		acc[field.id] = field.name;
		return acc;
	}, {});

/**
 * Use getCanUserCreate selector in src/state/selectors/permission.js
 */
export const hasCreateIssuesPermission = createSelector(
	getProjectConfiguration,
	(projectConfiguration: ProjectConfiguration) => projectConfiguration.permissions.canCreateIssues,
);

/**
 * Use getCanUserEdit selector in src/state/selectors/permission.js
 */
export const hasEditIssuesPermission = createSelector(
	getProjectConfiguration,
	(projectConfiguration: ProjectConfiguration) => projectConfiguration.permissions.canEditIssues,
);
/**
 * Use getCanUserRank selector in src/state/selectors/permission.js
 */
export const hasScheduleIssuesPermission = createSelector(
	getProjectConfiguration,
	(projectConfiguration: ProjectConfiguration) =>
		projectConfiguration.permissions.canScheduleIssues,
);

export const hasAdministerProjectPermission = createSelector(
	getProjectConfiguration,
	(projectConfiguration: ProjectConfiguration) =>
		projectConfiguration.permissions.canAdministerProjects,
);

export const rankIssuesSupported = (state: State): boolean =>
	state.configuration.rankIssuesSupported;

export const getToday = (state: State): number => state.configuration.today;

export const areDependenciesSupported = (state: State): boolean =>
	state.configuration.dependenciesSupported;

export const getFullConfiguration = (state: State): ConfigurationState => state.configuration;

export const getInwardDependencyDescription = (state: State): string | undefined =>
	state.configuration.inwardDependencyDescription;

export const getOutwardDependencyDescription = (state: State): string | undefined =>
	state.configuration.outwardDependencyDescription;

export const getFullIssueTypeHash = createSelector(
	getProjectConfiguration,
	(projectConfiguration: ProjectConfiguration) => projectConfiguration.issueTypes.hash,
);

export const getIssueType = (state: State, issueTypeId: IssueTypeId): IssueType =>
	getFullIssueTypeHash(state)[issueTypeId];

export const getProjectKey = createSelector(
	getProjectConfiguration,
	(projectConfiguration: ProjectConfiguration) => projectConfiguration.key,
);

export const getProjectName = createSelector(
	getProjectConfiguration,
	(projectConfiguration: ProjectConfiguration) => projectConfiguration.name,
);

export const getProjectDefaultItemTypeId = createSelector(
	getProjectConfiguration,
	(projectConfiguration: ProjectConfiguration) => projectConfiguration.defaultItemTypeId,
);

export const getFullRequiredFields = createSelector(
	getProjectConfiguration,
	(projectConfiguration: ProjectConfiguration) => projectConfiguration.requiredFields,
);

export const getRequiredFieldsByIssueTypeId = (state: State, issueTypeId: IssueTypeId): string[] =>
	getFullRequiredFields(state)[issueTypeId];

export const hasRequiredFields = createSelector(
	getFullRequiredFields,
	(requiredFieldsHash: RequiredFieldsHash): boolean =>
		Object.keys(requiredFieldsHash).some((itemTypeId) => requiredFieldsHash[itemTypeId].length > 0),
);

export const getBoardJql = (state: State): string | null =>
	state.configuration.boardConfiguration.jql;

export const getIsUserBoardAdmin = createSelector(
	getBoardConfiguration,
	(boardConfiguration: BoardConfiguration) => boardConfiguration.isUserBoardAdmin,
);

export const getSprintsHash = createSelector(
	getBoardConfiguration,
	(boardConfiguration: BoardConfiguration) => boardConfiguration.sprints.hash,
);

const EMPTY_ARRAY: Array<Sprint> = [];

export const getActiveAndFutureSprints = createSelector(
	getBoardConfiguration,
	(boardConfiguration: BoardConfiguration) => {
		const { sequence } = boardConfiguration.sprints;
		if (sequence !== undefined) {
			const sprintsHash = boardConfiguration.sprints.hash;
			const sprintsSequence = boardConfiguration.sprints.sequence;

			return sprintsSequence
				.filter((sprintId: SprintId) => sprintsHash[sprintId].state !== CLOSED)
				.map((sprintId: SprintId) => sprintsHash[sprintId]);
		}
		return EMPTY_ARRAY;
	},
);

export const getSprints = createSelector(getSprintsHash, (sprintsHash: SprintsHash) =>
	Object.keys(sprintsHash).map((sprintId: SprintId) => sprintsHash[sprintId]),
);

export const getFullVersionHash = createSelector(
	getProjectConfiguration,
	(projectConfiguration: ProjectConfiguration): VersionsHash => projectConfiguration.versions.hash,
);

export const getVersion = (state: State, versionId: VersionId): VersionWithStatus =>
	getFullVersionHash(state)[versionId];

export const getFullComponentHash = createSelector(
	getProjectConfiguration,
	(projectConfiguration: ProjectConfiguration): ComponentsHash =>
		projectConfiguration.components.hash,
);

export const getIsReleasesFeatureEnabled = createSelector(
	getProjectConfiguration,
	(projectConfiguration: ProjectConfiguration) =>
		Boolean(projectConfiguration.isReleasesFeatureEnabled),
);

export const getComponent = (state: State, componentId: ComponentId) =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	getFullComponentHash(state)[componentId] as Component;

export const getChildIssuePlanningMode = createSelector(
	getBoardConfiguration,
	(boardConfiguration: BoardConfiguration) => boardConfiguration.childIssuePlanningMode,
);

export const getIsSprintsFeatureEnabled = createSelector(
	getBoardConfiguration,
	(boardConfiguration: BoardConfiguration) => boardConfiguration.isSprintsFeatureEnabled,
);

export const getIsSprintsPlanningEnabled = createSelector(
	getIsSprintsFeatureEnabled,
	getChildIssuePlanningMode,
	(isSprintFeatureEnabled: boolean, mode: PlanningMode) =>
		isSprintFeatureEnabled && mode === SPRINT,
);

export const getIsChildIssuePlanningEnabled = createSelector(
	getBoardConfiguration,
	(boardConfiguration: BoardConfiguration) => boardConfiguration.isChildIssuePlanningEnabled,
);

export const hasActiveOrFutureSprint = createSelector(getSprints, (sprints: Sprint[]) =>
	sprints.some((sprint) => sprint.state === ACTIVE || sprint.state === FUTURE),
);

export const getHierarchyConfiguration = (state: State): HierarchyConfiguration =>
	state.configuration.hierarchyConfiguration;

export const getLevelOneName = createSelector(
	getHierarchyConfiguration,
	(hierarchyConfiguration: HierarchyConfiguration) => hierarchyConfiguration.levelOneName,
);

export const getFullDerivedFields = createSelector(
	getFullConfiguration,
	(configuration) => configuration.deriveFields ?? {},
);

export const getIsGoalsFeatureEnabled = createSelector(
	getProjectConfiguration,
	(projectConfiguration: ProjectConfiguration) =>
		projectConfiguration.isGoalsFeatureEnabled ?? false,
);

export const getCanInviteOthersToProject = createSelector(
	getFullConfiguration,
	(configuration: ConfigurationState) => configuration.canInviteOthersToProject,
);
