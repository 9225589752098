import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/finally';
import 'rxjs/add/observable/defer';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { filterItemsByJQLFilters } from '@atlassian/jira-software-roadmap-services/src/issues/filter.tsx';
import { getSourceARI } from '../../state/app/selectors';
import { getQuickFilters, getCustomFilters } from '../../state/router/selectors';
import type { State } from '../../state/types';
import {
	type JQLFiltersReapplyAction as Action,
	JQL_FILTERS_REAPPLY,
	jqlFiltersSuccess,
} from '../../state/ui/filters/actions';
import type { StateEpic } from '../common/types';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$.ofType(JQL_FILTERS_REAPPLY).switchMap(() => {
		const state = store.getState();

		// Only expecting either quick filters to exist or custom filters to exist, but never both.
		const quickFilterIds = getQuickFilters(state);
		const customFilterIds = getCustomFilters(state);
		const isJQLFilterApplied = quickFilterIds.length > 0 || customFilterIds.length > 0;

		const sourceARI = getSourceARI(state);

		if (isJQLFilterApplied)
			// Deferred due to query automatically subscribing when filterItemsByJQLFilters is
			// called rather than subscribing when the outer observable is subscribed to.
			// Now when switchMap switches its inner observable, the previous request will be
			// cancelled.
			return Observable.defer(() =>
				filterItemsByJQLFilters(sourceARI, quickFilterIds, customFilterIds).map((issueIds) =>
					jqlFiltersSuccess(issueIds),
				),
			);

		return Observable.empty();
	})) as StateEpic;
