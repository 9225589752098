import { borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';
import {
	BORDER_WIDTH,
	BAR_GAP_WIDTH,
	EXPANDABLE_INDICATOR_WIDTH,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/chart-header.tsx';
import {
	CLOSED,
	ACTIVE,
	FUTURE,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/interval.tsx';
import type { Interval } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/interval.tsx';
import {
	MIN_CONTENT_SIZE,
	HIGHLIGHTED_BORDER,
	HIGHLIGHTED_COLORS,
	MARKER_COLORS_BY_STATUS,
	DEFAULT_HORIZONTAL_PADDING,
	APPROX_WIDTH_OF_SINGLE_CHARACTER_AND_ELLIPSIS,
} from './constants';
import type {
	MarkerChildVisibility,
	MarkerColors,
	MarkerBorders,
	MarkerSpacing,
	StyleParameters,
} from './types';

/* CSS ellipsis does not work well in extremely narrow containers, and so we resort
 * to manually estimating whether certain child elements are visible using napkin math.
 */
export const getChildVisibility = ({
	width,
	isExpanded,
	isExpandable,
}: {
	width: number;
	isExpanded: boolean;
	isExpandable: boolean;
}): MarkerChildVisibility => {
	const isSpaceToRenderExpandIndicator = width >= 2 * EXPANDABLE_INDICATOR_WIDTH;
	const isExpandIndicatorVisible = isExpandable && !isExpanded && isSpaceToRenderExpandIndicator;

	const availableLabelWidth = width - (isExpandIndicatorVisible ? EXPANDABLE_INDICATOR_WIDTH : 0);
	const isLabelVisible = availableLabelWidth >= APPROX_WIDTH_OF_SINGLE_CHARACTER_AND_ELLIPSIS;

	return { isLabelVisible, isExpandIndicatorVisible };
};

export const getColours = ({
	intervals,
	isHighlighted,
}: {
	intervals: ReadonlyArray<Interval>;
	isHighlighted: boolean;
}): MarkerColors => {
	if (isHighlighted) {
		return HIGHLIGHTED_COLORS;
	}
	if (intervals.some(({ state }) => state === ACTIVE)) {
		return MARKER_COLORS_BY_STATUS[ACTIVE];
	}
	if (intervals.some(({ state }) => state === FUTURE)) {
		return MARKER_COLORS_BY_STATUS[FUTURE];
	}
	return MARKER_COLORS_BY_STATUS[CLOSED];
};

/* We limit the position of elements in the header to within the timeline boundaries
 * rather than use overflow hidden. This is more convenient for achieving our designs.
 * However, for markers that would continue beyond the boundaries, we remove the border
 * radius to enhance the visual illusion.
 */
export const getBorders = ({
	isHighlighted,
	isLeftClipped,
	isRightClipped,
}: {
	isHighlighted: boolean;
	isLeftClipped: boolean;
	isRightClipped: boolean;
}): MarkerBorders => {
	const leftRadius = isLeftClipped ? 0 : borderRadius;
	const rightRadius = isRightClipped ? 0 : borderRadius;

	return {
		borderRadius: `${leftRadius}px ${rightRadius}px ${rightRadius}px ${leftRadius}px`,
		border: isHighlighted ? HIGHLIGHTED_BORDER : 'none',
	};
};

export const getSpacing = ({
	width,
	isExpanded,
	isHighlighted,
	isRightClipped,
	isLabelVisible,
	isExpandIndicatorVisible,
}: {
	width: number;
	isExpanded: boolean;
	isHighlighted: boolean;
	isRightClipped: boolean;
	isLabelVisible: boolean;
	isExpandIndicatorVisible: boolean;
}): MarkerSpacing => {
	// Margins provide a gap between markers where there would otherwise be none
	const leftMargin = BAR_GAP_WIDTH / 2;
	const rightMargin = isRightClipped ? 0 : leftMargin;
	const margin = `0px ${rightMargin}px 0px ${leftMargin}px`;

	// Only show padding if the bar is wide enough to fit it
	const leftPadding = width > MIN_CONTENT_SIZE ? DEFAULT_HORIZONTAL_PADDING : 0;
	const rightPadding = leftPadding + (isExpanded ? BAR_GAP_WIDTH : 0);
	const topPadding = isHighlighted ? 0 : 2;

	if (!isLabelVisible || !isHighlighted) {
		return {
			padding: `${topPadding}px ${rightPadding}px 0px ${leftPadding}px`,
			margin,
		};
	}

	// Adjust for borders to keep text centred
	const rightPaddingWithOffset = leftPadding - (isExpandIndicatorVisible ? 0 : BORDER_WIDTH);
	const leftPaddingWithOffset = leftPadding - BORDER_WIDTH;

	return {
		padding: `${topPadding}px ${rightPaddingWithOffset}px 0px ${leftPaddingWithOffset}px`,
		margin,
	};
};

export const getDynamicMarkerStyles = ({
	intervalGroup,
	isExpanded,
	isExpandable,
	isHighlighted,
}: StyleParameters) => {
	const { intervals, isLeftClipped } = intervalGroup;

	const width = isExpanded ? intervalGroup.widthExpanded : intervalGroup.width;
	const childVisibility = getChildVisibility({ width, isExpanded, isExpandable });

	// Flatten the right side of marker to meet at the edge of the indicator when it is visible
	const isRightClipped = intervalGroup.isRightClipped || childVisibility.isExpandIndicatorVisible;

	return {
		colors: getColours({ intervals, isHighlighted }),
		borders: getBorders({ isHighlighted, isLeftClipped, isRightClipped }),
		spacing: getSpacing({
			width,
			isExpanded,
			isHighlighted,
			isRightClipped,
			...childVisibility,
		}),
		childVisibility,
	};
};
