/** @jsx jsx */
import React, { memo } from 'react';
import { css, jsx } from '@compiled/react';
import { B300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { zIndex } from '../../../../common/constants';
import { getDragIndicatorClassName } from '../../../../common/context/side-effect-marshal/style-marshal/utils.tsx';

const containerStyles = css({
	display: 'none',
	position: 'absolute',
	top: 0,
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: zIndex.INLINE_INDICATOR,
});

const lineStyles = css({
	height: '2px',
	backgroundColor: token('color.icon.brand', B300),
	'&::before': {
		content: '',
		position: 'absolute',
		height: 0,
		width: 0,
		top: '-3px',
		left: '-5px',
		borderTop: '4px solid transparent',
		borderBottom: '4px solid transparent',
		borderLeft: `8px solid ${token('color.border.brand', B300)}`,
	},
});

type Props = {
	left: number;
	width: number;
};

const InlineDragIndicator = ({ left, width }: Props) => (
	<div
		data-testid="roadmap.timeline-table.main.static-overlay.inline-indicators.drag.inline-drag-indicator"
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		className={getDragIndicatorClassName().className}
		css={containerStyles}
		style={{ left: `${left}px` }}
	>
		<div css={lineStyles} style={{ width: `${width}px` }} />
	</div>
);

export default memo<Props>(InlineDragIndicator);
