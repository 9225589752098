import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IdentifiableHash } from '@atlassian/jira-software-roadmap-model/src/common/index.tsx';
import type { Sprint } from '@atlassian/jira-software-roadmap-model/src/sprint/index.tsx';

export const getSprintNameForBaseLevelIssue = (
	issueId: IssueId,
	issueSprintsHash: IdentifiableHash<IssueId, Sprint[]>,
): string | undefined => {
	const issueSprints = issueSprintsHash[issueId];
	if (issueSprints.length > 0) {
		const recentSprint = issueSprints[0];
		return recentSprint?.name;
	}

	return undefined;
};
