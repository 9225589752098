import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { SkeletonCompiled } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';

type ListItemCreateSkeletonProps = {
	isActive: boolean;
};

const ListItemCreateSkeleton = ({ isActive }: ListItemCreateSkeletonProps) =>
	isActive ? (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		<Box xcss={containerStyles} style={{ width: '100%' }}>
			<SkeletonCompiled
				width="16px"
				height="16px"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={{ marginRight: token('space.100', '8px') }}
			/>
			<SkeletonCompiled width="50%" height="14px" />
		</Box>
	) : (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		<Box xcss={containerStyles} style={{ width: '100%' }}>
			<SkeletonCompiled width="35%" height="20px" />
		</Box>
	);

export default ListItemCreateSkeleton;

const containerStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	paddingTop: '0',
	paddingBottom: '0',
	paddingLeft: 'space.150',
	paddingRight: 'space.150',
	boxSizing: 'border-box',
});
