import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TIMELINE_ID } from '../../../../common/constants';
import { useTimelineViewport } from '../../../../common/context/timeline-viewport/index.tsx';
import { useResizeObserver } from '../../../../common/utils/use-resize-observer';

/** Inline indicators width / position calculations are consolidated into custom scrollbar (main/custom-scrollbars) and accessed via `useTimelineViewport`
 * However, due to a known caveat (https://hello.atlassian.net/wiki/spaces/AG/pages/1151004200/Core+Layout+-+Known+Caveats#Custom-Scrollbar%3A-OS-Settings),
 * we'll need to fall back to dedicated calculations based on query selector when the custom scrollbars are not available, i.e.
 * - In Mac, changing "Show scroll bars" to "When scrolling"
 * - In Windows, changing "Automatically hide scrollbars" to "On"
 */
export const useInlineIndicator = (): { left: number; width: number } => {
	const [{ left, clientWidth, indicatorLineOffset }] = useTimelineViewport();
	const [params, setParams] = useState({ left: 0, width: 0 });
	const viewportRef = useRef<HTMLElement | null>(null);

	const setInlineIndicatorParams = (viewport: HTMLElement | null) => {
		if (viewport) {
			setParams({
				left: viewport.getBoundingClientRect().left,
				width: viewport.clientWidth,
			});
		}
	};

	useEffect(() => {
		if (!clientWidth) {
			const viewport = document.getElementById(TIMELINE_ID);

			viewportRef.current = viewport;
			setInlineIndicatorParams(viewport);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const onContentResize = useCallback(() => {
		setInlineIndicatorParams(viewportRef.current);
	}, []);

	useResizeObserver(viewportRef.current, onContentResize);

	const inlineIndicatorParams = useMemo(
		() => ({
			left: viewportRef.current ? params.left : left,
			width: viewportRef.current
				? params.width - indicatorLineOffset
				: clientWidth - indicatorLineOffset,
		}),
		[params.left, params.width, left, indicatorLineOffset, clientWidth],
	);

	return inlineIndicatorParams;
};
