import type { OverlayDependencyItem } from '@atlassian/jira-aais-dependency-lines-overlay/src/common/ui/types.tsx';
import { DUE_DATE_OFFSET } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/date.tsx';
import { getBarLeftPosition } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/utils/bar/positions.tsx';
import { isDependencyDatesOverlapping } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/utils/dependency/overlap.tsx';
import type { TimelineDuration } from '@atlassian/jira-software-roadmap-timeline-table/src/common/types/timeline.tsx';
import type { DependencyItem } from './types';

/* Generally, consumers shouldn't have to concern themselves with pixel values as this is in the table "domain".
 * Instead we take dependency dates and convert it to pixels for them.
 * NOTE: We are using the bar positioning logic which tweaks this conversion a little, since dependencies attach to a bar.
 */
export const getDependenciesWithPositions = (
	dependencies: DependencyItem[],
	timelineDuration: TimelineDuration,
	timelineWidth: number,
): OverlayDependencyItem[] =>
	dependencies.map(({ itemHeight, from, to }) => ({
		isOverlapping: isDependencyDatesOverlapping(undefined, from.dueDate, to.startDate, undefined),
		itemHeight,
		to: {
			id: to.id,
			y: to.y,
			x: getBarLeftPosition(to.startDate, timelineDuration, timelineWidth),
			color: to.color,
		},
		from: {
			id: from.id,
			y: from.y,
			x: getBarLeftPosition(from.dueDate + DUE_DATE_OFFSET, timelineDuration, timelineWidth),
			color: from.color,
		},
	}));
