/** @jsx jsx */
import React, { useState, useEffect, useCallback } from 'react';
import { css, keyframes, jsx } from '@compiled/react';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import { token } from '@atlaskit/tokens';
import { usePreviousWithInitial } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import type {
	DragHandleType,
	OnDropDependency,
	DependencyTheme,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/dependency.tsx';
import { getFixedHandlePosition } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/utils/dependency/handler-position.tsx';
import Portal from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/portal/portal/view.tsx';
import { useSideEffectMarshal } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/side-effect-marshal/index.tsx';
import { useViewport } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/viewport/context/index.tsx';
import { TOP } from '../../../../../../../common/constants';
import { DependencyDragHandler } from '../../../../../../../common/ui/dependency/drag-handle';
import { useDependenciesDrag } from './use-drag';

interface Props {
	id: string;
	type: DragHandleType;
	theme: DependencyTheme;
	leftPosition: number;
	rightPosition: number;
	getBarBoundingClientRect: () => ClientRect | undefined;
	onDrop: OnDropDependency;
	onDragEnd: () => void;
	onDragStart: (type: DragHandleType) => void;
}

const DependenciesDragHandles = ({
	theme,
	id,
	type,
	leftPosition,
	rightPosition,
	getBarBoundingClientRect,
	onDrop,
	onDragStart,
	onDragEnd,
}: Props) => {
	const [handlePosition, setHandlePosition] = useState<
		| {
				top: number;
				left: number;
		  }
		| undefined
	>(undefined);

	const { custom } = useSideEffectMarshal();
	const { listenToViewportScroll, stopListeningToViewportScroll } = useViewport();

	const [{ isDragging }, makeDraggable, cleanupDraggable] = useDependenciesDrag({
		id,
		type,
		leftPosition,
		rightPosition,
		getBarBoundingClientRect,
		onDrop,
	});

	const wasDragging = usePreviousWithInitial(isDragging);

	// Callbacks //

	const onUpdateBarRectPosition = useCallback(() => {
		const barClientRect = getBarBoundingClientRect();
		if (barClientRect) {
			const fixedHandlePosition = getFixedHandlePosition(type, barClientRect);
			setHandlePosition(fixedHandlePosition);
		}
	}, [type, getBarBoundingClientRect]);

	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onScrollEnd = useCallback(
		debounce(() => {
			onUpdateBarRectPosition();
		}, 100),
		[],
	);

	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const onScroll = useCallback(
		throttle(() => {
			setHandlePosition(undefined);
			onScrollEnd();
		}, 100),
		[],
	);

	// Effects //

	useEffect(() => {
		listenToViewportScroll(onScroll);
		return () => {
			stopListeningToViewportScroll(onScroll);

			// drag connections will be unsubscribed if NULL was passed to drag handler.
			cleanupDraggable();
		};
	}, [onScroll, cleanupDraggable, listenToViewportScroll, stopListeningToViewportScroll]);

	useEffect(() => {
		if (!wasDragging && isDragging) {
			onDragStart(type);
			custom.onDependencyDragStart();
		} else if (wasDragging && !isDragging) {
			onDragEnd();
			custom.onDependencyDragEnd();
		}
	}, [type, isDragging, wasDragging, onDragEnd, onDragStart, custom]);

	useEffect(() => {
		onUpdateBarRectPosition();
	}, [onUpdateBarRectPosition]);

	// Render //
	if (!handlePosition) {
		return null;
	}

	return (
		<Portal>
			<div
				css={dragHandleContainerStyles}
				style={{
					visibility: isDragging ? 'hidden' : 'visible',
					left: handlePosition.left,
					top: handlePosition.top,
				}}
				data-testid="roadmap.timeline-table-kit.ui.chart-item-content.date-content.bar.bar-content.dependency-handler.handle"
				ref={makeDraggable}
			>
				<div
					data-component-selector="timeline-data-dot"
					css={[
						dragHandleDotStyles,

						type === TOP && slideInTopStyles,

						type !== TOP && slideInBottomStyles,
					]}
					style={{
						backgroundColor: theme.handle.background,
					}}
				/>
				<div css={dragHandleWrapperStyles} data-component-selector="timeline-drag-handler">
					<DependencyDragHandler color={theme.icon} />
				</div>
			</div>
		</Portal>
	);
};

export default DependenciesDragHandles;

const SLIDE_OFFSET = 15;

const slideInTop = keyframes({
	from: {
		opacity: 0,
		transform: `translateY(${SLIDE_OFFSET}px)`,
	},
	to: {
		opacity: 1,
		transform: 'translateY(0)',
	},
});

const slideInBottom = keyframes({
	from: {
		opacity: 0,
		transform: `translateY(-${SLIDE_OFFSET}px)`,
	},
	to: {
		opacity: 1,
		transform: 'translateY(0)',
	},
});

const slideInTopStyles = css({
	animation: `${slideInTop} 0.4s`,
});

const slideInBottomStyles = css({
	animation: `${slideInBottom} 0.4s`,
});

const dragHandleDotStyles = css({
	width: token('space.100', '8px'),
	height: token('space.100', '8px'),
	borderRadius: '50%',
	cursor: 'pointer',
	animationIterationCount: 1,
	transition: 'opacity 0.2s ease-in-out',
});

const dragHandleWrapperStyles = css({
	position: 'absolute',
	top: token('space.025', '2px'),
	left: token('space.025', '2px'),
	cursor: 'pointer',
	opacity: 0,
	transition: 'opacity 0.2s ease-in-out',
});

// Current solution for component selectors https://hello.atlassian.net/wiki/spaces/UAF/pages/1393355483/Compiled+Component+selectors#Proposed-solution---HTML-data-attribute-selectors (a WIP solution yet to be implement for better parent/child styling and will be documented)
const dragHandleContainerStyles = css({
	position: 'fixed',
	display: 'flex',
	flexDirection: 'column',
	width: token('space.300', '24px'),
	height: token('space.300', '24px'),
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '50%',
	pointerEvents: 'all',
	transform: 'translate(-50%, -50%)',
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'[data-component-selector="timeline-drag-handler"]': {
			opacity: 1,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'[data-component-selector="timeline-data-dot"]': {
			opacity: 0,
		},
	},
});
