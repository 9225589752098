import { Fragment } from 'react';
import {
	EXPLICIT,
	ROLLUP,
	INTERVAL,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/date.tsx';
import type { DateType } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/date.tsx';
import { RollupIcon } from '../rollup-icon';
import { SprintIcon } from '../sprint-icon';

export const DATE_ICON_LOOKUP: Record<
	DateType,
	typeof Fragment | typeof RollupIcon | typeof SprintIcon
> = {
	[EXPLICIT]: Fragment,
	[ROLLUP]: RollupIcon,
	[INTERVAL]: SprintIcon,
} as const;

// 'i' is the intrinsic HTML element for italics, or <i/> in JSX.
export const DATE_CONTAINER_LOOKUP: Record<DateType, typeof Fragment | string> = {
	[EXPLICIT]: Fragment,
	[ROLLUP]: 'i',
	[INTERVAL]: 'i',
} as const;
