/** @jsx jsx */
import React, { useState, useCallback, useEffect } from 'react';
import { css, jsx } from '@compiled/react';
import type { Color } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import { DEPENDENCIES_DROP_TARGET_CLASS_NAME } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/dependency.tsx';
import { DEPENDENCIES_HANDLER_Z_INDEX } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/z-index.tsx';
import type { Placeholder } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/chart-item.tsx';
import type {
	DragHandleType,
	OnDropDependency,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/dependency.tsx';
import { generateBarBorderRadius } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/utils/bar/styled.tsx';
import { dependencyThemes } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/utils/dependency/theme.tsx';
import { TOP, BOTTOM } from '../../../../../../common/constants';
import DragHandle from './handle';
import { useDependenciesDrop } from './use-drop';

const containerStyles = css({
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	pointerEvents: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: DEPENDENCIES_HANDLER_Z_INDEX,
});

const suffixElementStyles = css({
	content: '',
	position: 'absolute',
	width: '100%',
	height: '100%',
	transition: 'opacity 0.1s ease-in-out',
});

type Props = {
	isHovered: boolean;
	id: string;
	color: Color;
	leftPosition: number;
	rightPosition: number;
	placeholder: Placeholder;
	getBarBoundingClientRect: () => ClientRect | undefined;
	onBarMouseLeave: () => void;
	onCreateDependency: OnDropDependency;
};
const RoadmapDependencyHandlers = ({
	id,
	color,
	leftPosition,
	rightPosition,
	isHovered,
	placeholder,
	onCreateDependency,
	getBarBoundingClientRect,
	onBarMouseLeave,
}: Props) => {
	const [{ isDraggedOver }, makeDroppable, cleanupDroppable] = useDependenciesDrop(
		id,
		onCreateDependency,
	);
	const [currentDragHandle, setCurrentDragHandle] = useState<unknown>(undefined);

	const isDragging = currentDragHandle !== undefined;
	const theme = dependencyThemes?.[color];

	// Callbacks //
	const onDragStart = useCallback(
		(type: DragHandleType) => {
			setCurrentDragHandle(type);
			onBarMouseLeave();
		},
		[onBarMouseLeave],
	);

	const onDragEnd = useCallback(() => {
		setCurrentDragHandle(undefined);
	}, []);

	// Effects //
	useEffect(
		() => () => {
			// drop connections will be unsubscribed if NULL was passed to drop handler.
			cleanupDroppable();
		},
		[cleanupDroppable],
	);

	// Render //

	const renderDragHandle = (type: DragHandleType) => {
		const isHandleInteractive = isHovered || currentDragHandle === type;

		if (!isHandleInteractive) {
			return null;
		}

		return (
			<DragHandle
				id={id}
				type={type}
				theme={theme}
				leftPosition={leftPosition}
				rightPosition={rightPosition}
				onDrop={onCreateDependency}
				onDragEnd={onDragEnd}
				onDragStart={onDragStart}
				getBarBoundingClientRect={getBarBoundingClientRect}
			/>
		);
	};

	return (
		<div
			css={containerStyles}
			style={{
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				borderRadius: generateBarBorderRadius(placeholder),
			}}
			data-testid={`roadmap.timeline-table-kit.ui.chart-item-content.date-content.bar.dependency-drop-target-${id}`}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={DEPENDENCIES_DROP_TARGET_CLASS_NAME}
			ref={makeDroppable}
		>
			{renderDragHandle(TOP)}
			{renderDragHandle(BOTTOM)}
			<span
				css={suffixElementStyles}
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					borderRadius: generateBarBorderRadius(placeholder),
					boxShadow: `inset 0 0 0 2px ${theme?.border}`,
					opacity: isDragging || isDraggedOver ? 1 : 0,
				}}
			/>
		</div>
	);
};

export { RoadmapDependencyHandlers };
