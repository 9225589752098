import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import type { Hash } from '@atlassian/jira-shared-types/src/general.tsx';
import type { HashDiff } from '../hash-diff';
import { isHashModified } from './utils';

type PropertyHashSelector<State, Property> = (arg1: State, arg2?: boolean) => Hash<Property>;

const createPropertyHashSelector = <State, Entity, Property>(
	getVersionedEntityHash: (arg1: State, arg2: Hash<Entity> | undefined) => HashDiff<Entity>,
	propertyAccessor: (arg1: Entity, arg2: string) => Property,
): PropertyHashSelector<State, Property> => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let previousPropertyHash: Record<string, any> = {};
	let previousEntityHash: Hash<Entity>;

	return (state: State) => {
		// get ids of entities differing in the entity hash
		// external O(#entities + #oldEntities) - but memoized
		const hashDiff = getVersionedEntityHash(state, previousEntityHash);
		previousEntityHash = hashDiff.hash;
		// if the content of the entity hash (or the hash itself) did not change - early exit
		// O(1)
		if (!isHashModified(hashDiff)) {
			return previousPropertyHash;
		}
		// start building up the new property hash
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const newPropertyHash: Record<string, any> = {};
		// first copy over properties from changed entities and remember if one changed
		// O(#updatedEntities * propertyAccess)
		let somePropertyModified = false;
		forEach(hashDiff.changed, (item, key) => {
			newPropertyHash[key] = propertyAccessor(item, key);
			somePropertyModified =
				somePropertyModified || previousPropertyHash[key] !== newPropertyHash[key];
		});

		// if from the changed entities no relevant property got updated AND no entity has been added or removed - early exit
		// O(1)
		if (!somePropertyModified && isEmpty(hashDiff.added) && isEmpty(hashDiff.removed)) {
			return previousPropertyHash;
		}

		// copy over the properties from the added entities
		// O(#addedEntities * propertyAccess)
		forEach(hashDiff.added, (entity, key) => {
			newPropertyHash[key] = propertyAccessor(entity, key);
		});

		// copy over the properties from the unchanged items from the previous hash
		// O(#unchanged Entities)
		forEach(hashDiff.unchanged, (entity, key) => {
			newPropertyHash[key] = previousPropertyHash[key];
		});

		previousPropertyHash = newPropertyHash;
		return newPropertyHash;
	};
};

export { createPropertyHashSelector };
export type { PropertyHashSelector };
