import type { RoadmapType } from '@atlassian/jira-software-roadmap-model/src/app/index.tsx';

export const ROADMAP_APP_ID = 'standard-roadmap';
export const toRoadmapAppId = (type?: RoadmapType) =>
	type ? `${ROADMAP_APP_ID}.${type}`.toLowerCase() : ROADMAP_APP_ID;
export const ROADMAP_PACKAGE_NAME = 'jiraSoftwareRoadmapStandard';

// SLA constants
export const ROADMAP_CRITICAL_DATA_SUCCEEDED = 'roadmap-critical-data-succeeded';
export const ROADMAP_CRITICAL_DATA_FAILED = 'roadmap-critical-data-failed';
export const ROADMAP_RESOURCE_DATA_FAILED = 'roadmap-resource-data-failed';

export const TTRSLO = 1000;
export const TTISLO = 4000;

// Feature flag constants
export const NOT_ENROLLED = 'not-enrolled';
export const CONTROL = 'control';
export const EXPERIMENT = 'experiment';

// Issue date error message ids for whether the parent issue lies outside of its children's dates
export const PARENT_STARTS_AFTER_CHILD_MESSAGE_ID = 'parentStartsAfterChild' as const;
export const PARENT_ENDS_BEFORE_CHILD_MESSAGE_ID = 'parentEndsBeforeChild' as const;
export const PARENT_STARTS_AFTER_CHILD_AND_ENDS_BEFORE_CHILD_MESSAGE_ID =
	'parentStartsAfterChildAndEndsBeforeChild' as const;

// Issue date error message ids for whether the child issue lies outside of its sprint's dates
export const SPRINT_ENDS_BEFORE_CHILD_MESSAGE_ID = 'sprintEndsBeforeChild' as const;

// Issue date error message ids for whether the start date is after the due date
export const START_DATE_LATER_DUE_DATE_MESSAGE_ID = 'startDateLaterDueDate' as const;

// Issue date error message ids for whether the start date is after the due date
export const END_DATE_LATER_RELEASE_DATE_MESSAGE_ID = 'endDateLaterReleasesDate' as const;

// Placeholder days
export const PARENT_PLACEHOLDER_DAYS = 30;
export const CHILD_PLACEHOLDER_DAYS = 14;

// Interacted items limit for issue drag-along and bulk interactions
export const INTERACTED_ITEMS_LIMIT = 50;

// List item menu options id
export const SCHEDULING_SECTION = 'schedulingSection' as const;
export const BULK_SECTION = 'bulkSection' as const;
export const REMOVE_DATES = 'REMOVE_DATES' as const;
export const EDIT_SPRINT = 'EDIT_SPRINT' as const;
export const EDIT_DATES = 'EDIT_DATES' as const;
export const BULK_EDIT_DATES = 'BULK_EDIT_DATES' as const;
export const CHANGE_PARENT_ISSUE = 'CHANGE_PARENT_ISSUE' as const;
export const CHANGE_ISSUE_COLOR = 'CHANGE_ISSUE_COLOR' as const;
export const EDIT_DEPENDENCY = 'EDIT_DEPENDENCY' as const;

export const REMOVE_START_DATE = 'REMOVE_START_DATE' as const;
export const REMOVE_DUE_DATE = 'REMOVE_DUE_DATE' as const;
export const REMOVE_START_DATE_AND_DUE_DATE = 'REMOVE_START_DATE_AND_DUE_DATE' as const;
