import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { DEPENDENCIES_FLYOUT_TYPE } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/overlay.tsx';

export type DependencyTooltipParams = {
	issueId: IssueId;
	type: typeof DEPENDENCIES_FLYOUT_TYPE;
	dependencies: IssueId[];
	dependees: IssueId[];
	position: {
		x: number;
		y: number;
	};
	showImplicitDependency: boolean;
	triggerRef?: React.RefObject<HTMLElement>;
};

export const DEPENDENCY_ADD_ERROR = 'DEPENDENCY_ADD_ERROR' as const;

export const DEPENDENCY_DELETE_ERROR = 'DEPENDENCY_DELETE_ERROR' as const;

export type DependenciesPermissionType =
	| typeof DEPENDENCY_ADD_ERROR
	| typeof DEPENDENCY_DELETE_ERROR;
