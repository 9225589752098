import noop from 'lodash/noop';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types';
import ProjectAddPeopleAsync, {
	type Props,
} from '@atlassian/jira-project-add-people/src/async.tsx';
import { connect } from '@atlassian/jira-react-redux';
import { getProjectKey, getProjectId } from '../../../../state/configuration/selectors';
import type { State } from '../../../../state/types';

type OwnProps = {
	isOpen: boolean;
	onClose: () => void;
};

const mapStateToProps = (state: State, ownProps: OwnProps): Props => ({
	baseUrl: '',
	projectId: Number(getProjectId(state)),
	projectKey: getProjectKey(state),
	isOpen: ownProps.isOpen,
	projectType: SOFTWARE_PROJECT,
	isAdmin: true,
	analyticsPrefix: 'software.roadmap',
	allowFlags: true,
	onClose: noop,
	onError: noop,
	onAddSuccess: noop,
	onStopPerfMetric: noop,
	projectRoles: undefined,
	partiesInProject: undefined,
	defaultRoleId: undefined,
	adminRoleId: undefined,
	roleConfigurable: undefined,
});

export default connect(mapStateToProps, {})(ProjectAddPeopleAsync);
