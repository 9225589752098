import {
	ISSUE_CREATED_EVENT,
	ISSUE_DELETED_EVENT,
	ISSUE_TRANSITIONED_EVENT,
	ISSUE_UPDATED_EVENT,
} from '@atlassian/jira-realtime/src/common/constants/events.tsx';
import type { RealtimeEventType } from '@atlassian/jira-realtime/src/common/types/events.tsx';

export const REALTIME_EVENTS: RealtimeEventType[] = [
	ISSUE_CREATED_EVENT,
	ISSUE_DELETED_EVENT,
	ISSUE_TRANSITIONED_EVENT,
	ISSUE_UPDATED_EVENT,
];
