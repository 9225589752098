import React from 'react';
import ProgressBar from '@atlassian/jira-common-components-progress-bar/src/main.tsx';
import ProgressElement from '@atlassian/jira-common-components-progress-bar/src/progress-element/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { Progress, ProgressEntry } from '../../types/progress';
import messages from './messages';

type Props = {
	isRichContentEnabled: boolean;
	// Progress is displayed in the order it is passed, with the last entry representing the unfulfilled portion of the bar. This is a limitation of the underlying platform component.
	progress: Progress;
};

const RoadmapProgressBar = ({ isRichContentEnabled, progress }: Props) => {
	const { formatMessage } = useIntl();

	const total = progress.reduce((totalCount, entry) => totalCount + entry.count, 0);

	/* Progress components skips adding a tooltip wrapper if tooltipContent is an empty string.
	 * We use this as a performance optimisation.
	 */
	const getTooltipContent = ({ count, name }: ProgressEntry) => {
		if (!isRichContentEnabled) return '';
		// The progress bar will have a button role when tooltipContent is a string, so adding a fragment to avoid this.
		return <>{formatMessage(messages.tooltip, { count, name, total })}</>;
	};

	const fulfilledProgress = progress.slice(0, -1);
	const unfulfilledProgress = progress[progress.length - 1];

	const progressBarAriaLabel = [...fulfilledProgress, unfulfilledProgress]
		.map(({ count, name }) => formatMessage(messages.tooltip, { count, name, total }))
		.join(', ');

	return (
		<ProgressBar
			aria-label={progressBarAriaLabel}
			sum={total}
			backgroundColor={unfulfilledProgress.color}
			remainingTooltipContent={getTooltipContent(unfulfilledProgress)}
			height={4}
		>
			{fulfilledProgress.map((entry: ProgressEntry) => (
				<ProgressElement
					key={entry.id}
					value={entry.count}
					color={entry.color}
					tooltipContent={getTooltipContent(entry)}
				/>
			))}
		</ProgressBar>
	);
};

export { RoadmapProgressBar };
