import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { Ari } from '@atlassian/jira-platform-ari';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type {
	RoadmapItemRankInput,
	updateRoadmapItemMutation$data,
} from '@atlassian/jira-relay/src/__generated__/updateRoadmapItemMutation.graphql';
import type { IssueTypeId, IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { RankRequest } from '@atlassian/jira-software-roadmap-model/src/issue/index.tsx';
import { updateItemGraphqlOnServer } from '@atlassian/jira-software-roadmap-services/src/issues/update-roadmap-item.tsx';
import { getSourceARI } from '../../state/app/selectors';
import { getEpicIssueTypeIds, getProjectId } from '../../state/configuration/selectors';
import { type RankIssueAction, RANK_ISSUE } from '../../state/entities/issues/actions';
import {
	getSafeId,
	getIssueKeyById,
	getIssueParentIdHash,
	getIssueTypeIdForIssueId,
} from '../../state/entities/issues/selectors';
import { aggError } from '../../state/flags/actions';
import type { State } from '../../state/types';
import { onHandleAggErrors } from '../common/handle-agg-errors';
import type { StateEpic } from '../common/types';
import { getSafeRankRequest } from './common/rank';
import refreshIssuePage from './refresh-issue-page';

export const rank = (
	unsafeIssueId: Observable<IssueId>,
	unsafeRankRequest: Observable<RankRequest>,
	analyticsMetadata: {
		event: UIAnalyticsEvent;
		projectId: string;
		attributes: {
			issueTypeId: IssueTypeId;
			issueType?: string | undefined;
			level: number;
		};
	},
	sourceARI: Ari,
): Observable<void> =>
	unsafeIssueId.flatMap((issueId: IssueId) =>
		unsafeRankRequest.mergeMap((request: RankRequest) => {
			let aggRank: RoadmapItemRankInput | null | undefined;

			if ('rankBeforeId' in request) {
				aggRank = {
					id: request.rankBeforeId,
					position: 'BEFORE',
				};
			} else if ('rankAfterId' in request) {
				aggRank = {
					id: request.rankAfterId,
					position: 'AFTER',
				};
			}

			return updateItemGraphqlOnServer({
				sourceARI,
				input: {
					itemId: issueId,
					projectId: analyticsMetadata.projectId,
					rank: aggRank,
				},
			}).map((data: updateRoadmapItemMutation$data) => {
				const updateRoadmapItemData = data?.roadmaps?.updateRoadmapItem;
				const errors = updateRoadmapItemData?.errors;
				const success = updateRoadmapItemData?.success;
				if (success === false && errors?.length) {
					onHandleAggErrors(!!updateRoadmapItemData, errors);
				}
				const clonedEvent = analyticsMetadata.event.clone();
				if (clonedEvent) {
					const attributes = {
						...analyticsMetadata.attributes,
						projectType: 'software',
						updatedItems: [
							{
								name: 'rank',
							},
						],
					};
					fireTrackAnalytics(clonedEvent, 'issue updated', attributes);
				}
				return undefined;
			});
		}),
	);

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<RankIssueAction>, store: MiddlewareAPI<State>) =>
	action$.ofType(RANK_ISSUE).mergeMap((action: RankIssueAction) => {
		const state = store.getState();
		const sourceARI = getSourceARI(state);
		const { issueId, rankRequest } = action.payload;
		const safeId = getSafeId(state, issueId);
		const safeRankRequest = getSafeRankRequest(state, rankRequest);
		const projectId = getProjectId(state);
		const issueTypeId = getIssueTypeIdForIssueId(state, issueId);
		const level = getEpicIssueTypeIds(state).includes(issueTypeId) ? 1 : 0;
		const parentId = getIssueParentIdHash(state)[issueId];
		const parentIssueKey = parentId ? getIssueKeyById(state, parentId) : undefined;

		return rank(
			safeId,
			safeRankRequest,
			{
				event: action.meta.analyticsEvent,
				projectId,
				attributes: {
					issueTypeId,
					level,
				},
			},
			sourceARI,
		)
			.flatMap(() => {
				refreshIssuePage(state, parentIssueKey);
				return Observable.empty<never>();
			})
			.catch(() => Observable.from([aggError()]));
	})) as StateEpic;
