import { createSelector } from 'reselect';
import type { LevelOneSetting } from '@atlassian/jira-software-roadmap-model/src/filter/index.tsx';
import type { TimelineMode } from '@atlassian/jira-software-roadmap-timeline-table/src/common/types/timeline.tsx';
import { isClassic as getIsClassic } from '../../app/selectors';
import { getIssueIds } from '../../entities/issues/selectors';
import { isShared } from '../../router/selectors';
import { getTimelineMode, getIssuesFilter, getLevelOneSetting } from '../filters';
import { getFilteredIssueCount } from '../issues';
import { getShareTableProps } from '../share';
import { getHighlightedVersions } from '../versions';
import {
	getRoadmapImageExportMetrics as getRoadmapImageExportMetricsPure,
	type FiltersAnalyticData,
} from './pure';

export { type RoadmapImageExportMetrics } from './pure';

export type ViewAnalyticData = {
	shared: boolean;
	timelineMode: TimelineMode;
	filters: FiltersAnalyticData;
	levelOneSetting: LevelOneSetting;
	highlightedVersionCount: number;
	filteredIssueCount: number;
};

export const getFiltersAnalyticsData = createSelector(
	getIssuesFilter,
	getIsClassic,
	(
		{ assignees, issueTypes, statuses, text, labels, versions, quickFilters, customFilters, goals },
		isClassic,
	) => ({
		assignees: assignees.length > 0,
		issueTypes: issueTypes.length > 0,
		statuses: statuses.length > 0,
		text: text.length > 0,
		labels: labels.length > 0,
		versions: versions.length > 0,
		quickFilters: quickFilters.length > 0,
		customFilters: customFilters.length > 0,
		isClassic,
		goals: goals.length > 0,
	}),
);

export const getRoadmapImageExportMetrics = createSelector(
	getShareTableProps,
	getIssueIds,
	getFiltersAnalyticsData,
	getRoadmapImageExportMetricsPure,
);

export const getRoadmapLoadedAnalyticsData = createSelector(
	[
		getTimelineMode,
		getFiltersAnalyticsData,
		isShared,
		getLevelOneSetting,
		getHighlightedVersions,
		getFilteredIssueCount,
	],
	(timelineMode, filters, shared, levelOneSetting, highlightedVersions, filteredIssueCount) => ({
		shared,
		timelineMode,
		filters,
		levelOneSetting,
		highlightedVersionCount: highlightedVersions.length,
		filteredIssueCount,
	}),
);
