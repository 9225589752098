import { useRef, useCallback } from 'react';
import kebabCase from 'lodash/kebabCase';
import type { RefObject } from '@atlassian/jira-shared-types/src/general.tsx';
import { useResizeObserver } from './use-resize-observer';

/**
 * Breakpoint value, and values to apply when above and below the breakpoint.
 * Variable keys are to be in camelcase.
 */
export type UseBreakpointCSSVariablesOptions = {
	breakpoint: number;
	above: Record<string, string>;
	below: Record<string, string>;
};

const toPropertyName = (name: string) => `--${kebabCase(name)}`;

const setVariables = (element: HTMLElement, variables: Record<string, string>) => {
	Object.keys(variables).forEach((key) => {
		const property = toPropertyName(key);
		const value = variables[key];

		element.style.setProperty(property, value);
	});
};

export const useBreakpointCSSVariables = (
	ref: RefObject<HTMLElement>,
	options: UseBreakpointCSSVariablesOptions,
) => {
	const { breakpoint, above, below } = options;

	const flag = useRef<unknown>(null);

	const evalWidth = useCallback(
		(width: number) => {
			const element = ref.current;
			if (!element) return;

			const isAbove = width > breakpoint;
			if (flag.current !== null && isAbove === flag.current) return;

			flag.current = isAbove;

			const variables = isAbove ? above : below;
			setVariables(element, variables);
		},
		[above, below, breakpoint, ref],
	);

	useResizeObserver(ref, (entry) => {
		const { width } = entry.contentRect;

		evalWidth(width);
	});
};
