import { useCallback, useRef } from 'react';
import {
	attachInstruction,
	extractInstruction,
	type Instruction,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
	dropTargetForElements,
	type ElementDropTargetGetFeedbackArgs,
	type ElementEventBasePayload,
	type ElementDropTargetEventBasePayload,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { ROW_DRAG_AND_DROP_ID, UP, DOWN, DELAY_EXPAND_TIME } from '../../../../../common/constants';
import { useSideEffectMarshal } from '../../../../../common/context/side-effect-marshal/index.tsx';
import { useTimelineViewportActions } from '../../../../../common/context/timeline-viewport/index.tsx';
import { getListContentPaddingLeft } from '../../../../../common/styled/list.tsx';
import type {
	ItemDropPayload,
	OnItemExpandChanged,
} from '../../../../../common/types/callbacks.tsx';
import type { RowDragData, VerticalDirection } from '../../../../../common/types/drag-and-drop.tsx';
import type { ItemId } from '../../../../../common/types/item.tsx';
import {
	getUpdateType,
	getIsDragChildOverParent,
	getTreeItemIsDragChildOverParent,
	getIsDragParentOverChild,
	getBlockedInstructions,
	UPDATE_PARENT,
	UPDATE_RANK,
	UPDATE_RANK_AND_PARENT,
	INDENT_PER_LEVEL,
} from './utils';

type UseDropProps = {
	id: ItemId;
	level: number;
	depth: number;
	parentId?: ItemId;
	isParent: boolean;
	isExpanded: boolean;
	isInTransition: boolean;
	isTreeDrag: boolean;
	childItemsHeight: number | undefined;
	onDrop: (id: ItemId, payload: ItemDropPayload) => void;
	onExpandChanged: OnItemExpandChanged;
};

const useListDropTarget = ({
	id,
	level,
	depth,
	parentId,
	isParent,
	isExpanded,
	isInTransition,
	isTreeDrag,
	childItemsHeight = 0,
	onDrop,
	onExpandChanged,
}: UseDropProps): [(el: HTMLElement | null) => void, () => void] => {
	const directionRef = useRef<VerticalDirection>(DOWN);
	const cleanupDragAndDrop = useRef<null | (() => void)>(null);
	const isDraggedOver = useRef<boolean>(false);
	const timerRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
	const instructionRef = useRef<Instruction | null>(null);
	const { onResize } = useTimelineViewportActions();
	const isDragChildOverParent = useRef<boolean>(false);
	const { onRowDragOver, onRowDragChildOverParent, onRowDragChildOverParentEnd } =
		useSideEffectMarshal();

	const makeDroppable = useCallback(
		(dragElement: HTMLElement | null) => {
			const el = dragElement;
			if (el === null) {
				return;
			}

			const getData = () => ({ id, level, isInTransition });

			const canDrop = ({ source }: ElementDropTargetGetFeedbackArgs) => {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const item = source.data as RowDragData;
				return (
					item.type === ROW_DRAG_AND_DROP_ID &&
					!getIsDragParentOverChild(item.level, level) &&
					isInTransition !== true
				);
			};

			const onDragEnter = ({ source }: ElementDropTargetEventBasePayload) => {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const item = source.data as RowDragData;
				if (item.type === ROW_DRAG_AND_DROP_ID && item.id !== id) {
					isDraggedOver.current = true;
					// expand parent
					if (getIsDragChildOverParent(item.level, level)) {
						// set the drag over parent styles
						onRowDragChildOverParent(item.id, id);
						if (isParent && !isExpanded) {
							// expand if it is a collapsed parent
							timerRef.current && clearTimeout(timerRef.current);
							timerRef.current = setTimeout(() => {
								onExpandChanged(id, false);
								clearTimeout(timerRef.current);
								timerRef.current = undefined;
							}, DELAY_EXPAND_TIME);
						}
					}
				}
			};

			const onDrag = ({ source, location }: ElementEventBasePayload) => {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const item = source.data as RowDragData;
				const {
					current: { input: current },
				} = location;
				if (
					isDraggedOver.current &&
					// not dragging child over parent
					!getIsDragChildOverParent(item.level, level)
				) {
					// calculate drop position
					const { top, bottom } = el.getBoundingClientRect();
					const midpoint = top + (bottom - top) / 2;

					directionRef.current = current.pageY <= midpoint ? UP : DOWN;
					const bottomWithOffset = bottom + childItemsHeight;
					const relativeTop = directionRef.current === DOWN ? bottomWithOffset : top;

					onRowDragOver(item.id, relativeTop);
				}
			};

			const onItemDrop = ({
				source,
				location: {
					current: { dropTargets },
				},
			}: ElementEventBasePayload) => {
				/**
				 * Check whether the drop happens over only the current target,
				 * or over a nested one.
				 * The first item in dropTargets is always the inner most dropTarget.
				 */
				const isShallowDrop = dropTargets.length > 0 && dropTargets[0].data.id === id;
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const item = source.data as RowDragData;
				if (item.id !== id && isShallowDrop) {
					const rankRequest =
						directionRef.current === DOWN ? { rankAfterId: id } : { rankBeforeId: id };
					const updateType = getUpdateType(
						{
							level: item.level,
							parentId: item.parentId,
							id: item.id,
							depth: item.depth,
						},
						{ level, parentId, id, depth },
					);

					switch (updateType) {
						case UPDATE_RANK:
							onDrop(item.id, { level: item.level, rankRequest });
							break;
						case UPDATE_PARENT:
							onDrop(item.id, {
								level: item.level,
								parentId: id,
							});
							break;
						case UPDATE_RANK_AND_PARENT:
							onDrop(item.id, {
								level: item.level,
								parentId,
								rankRequest,
							});
							break;
						default:
							// do nothing
							break;
					}
				}
			};

			const treeGetData = ({ source, input, element }: ElementDropTargetGetFeedbackArgs) => {
				const data = { id, level, isInTransition };
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const draggedItem = source.data as RowDragData;

				const dropTarget = {
					id,
					parentId,
					level,
					depth,
				};
				const blockedInstructions = getBlockedInstructions({
					dropTarget,
					draggedItem,
				});

				return attachInstruction(data, {
					input,
					element,
					indentPerLevel: INDENT_PER_LEVEL,
					currentLevel: depth || 0,
					mode: isParent && isExpanded ? 'expanded' : 'standard',
					block: blockedInstructions,
				});
			};

			const treeCanDrop = ({ source }: ElementDropTargetGetFeedbackArgs) =>
				source.data.type === ROW_DRAG_AND_DROP_ID && isInTransition !== true;

			const onTreeDrag = ({ source, self, location }: ElementDropTargetEventBasePayload) => {
				const { dropTargets } = location.current;
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const item = source.data as RowDragData;
				const dropTarget = dropTargets[0];
				if (dropTarget.data.id === id) {
					const extractedInstruction = extractInstruction(self.data);
					if (extractedInstruction?.type !== 'instruction-blocked') {
						// show indicator line only when rerank issue
						if (
							extractedInstruction?.type === 'reorder-above' ||
							extractedInstruction?.type === 'reorder-below'
						) {
							const { top, bottom } = el.getBoundingClientRect();

							const relativeTop = extractedInstruction?.type === 'reorder-below' ? bottom : top;

							const paddingLeft = getListContentPaddingLeft({
								isParent: true,
								depth,
							});
							onResize({
								indicatorLineOffset: paddingLeft,
							});
							onRowDragOver(item.id, relativeTop, paddingLeft);

							// Clear the background when a child is dragged over a parent and transitions to the state of reordering above or below.
							if (isDragChildOverParent.current) {
								onRowDragChildOverParentEnd(parentId ?? id);
								isDragChildOverParent.current = false;
							}
						}

						// show indicator background
						if (extractedInstruction?.type === 'make-child') {
							onRowDragChildOverParent(item.id, id);
							isDragChildOverParent.current = true;
						}
						instructionRef.current = extractedInstruction;
					}
				}
			};

			const onTreeDrop = ({
				source,
				location: {
					current: { dropTargets },
				},
			}: ElementEventBasePayload) => {
				const isShallowDrop = dropTargets.length > 0 && dropTargets[0].data.id === id;
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const item = source.data as RowDragData;
				if (instructionRef.current && item.id !== id && isShallowDrop) {
					// const isUnlinkParent = item.parentId && !parentId;
					const isReparent = item.parentId !== parentId;
					switch (instructionRef.current.type) {
						case 'make-child':
							onDrop(item.id, {
								level: item.level,
								parentId: id,
							});
							// Clear the parent's background after a child is dragged over the parent and dropped onto it
							if (isDragChildOverParent.current) {
								onRowDragChildOverParentEnd(id);
								isDragChildOverParent.current = false;
							}
							break;
						case 'reorder-above':
						case 'reorder-below':
							onDrop(item.id, {
								level: item.level,
								rankRequest:
									instructionRef.current.type === 'reorder-above'
										? { rankBeforeId: id }
										: { rankAfterId: id },
								...(isReparent ? { parentId } : {}),
							});
							break;
						default:
							// do nothing
							break;
					}
				}
				instructionRef.current = null;
			};

			const onTreeDragEnter = ({ source }: ElementDropTargetEventBasePayload) => {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const item = source.data as RowDragData;
				if (item.type === ROW_DRAG_AND_DROP_ID && item.id !== id) {
					isDraggedOver.current = true;
					// expand parent
					if (getTreeItemIsDragChildOverParent(item.level, level)) {
						if (isParent && !isExpanded) {
							// expand if it is a collapsed parent
							timerRef.current && clearTimeout(timerRef.current);
							timerRef.current = setTimeout(() => {
								onExpandChanged(id, false);
								clearTimeout(timerRef.current);
								timerRef.current = undefined;
							}, DELAY_EXPAND_TIME);
						}
					}
				}
			};

			const onTreeDropTargetChange = ({ location }: ElementEventBasePayload) => {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const previousDropTargetId = location.previous?.dropTargets[0]?.data.id as string;
				if (isDragChildOverParent.current && previousDropTargetId) {
					onRowDragChildOverParentEnd(previousDropTargetId);
					isDragChildOverParent.current = false;
				}
			};

			cleanupDragAndDrop.current = combine(
				dropTargetForElements({
					element: el,
					getData: isTreeDrag ? treeGetData : getData,
					canDrop: isTreeDrag ? treeCanDrop : canDrop,
					onDragEnter: isTreeDrag ? onTreeDragEnter : onDragEnter,
					onDrag: isTreeDrag ? onTreeDrag : onDrag,
					onDrop: isTreeDrag ? onTreeDrop : onItemDrop,
					onDragLeave: () => {
						if (isDraggedOver.current || timerRef.current) {
							isDraggedOver.current = false;
							clearTimeout(timerRef.current);
							timerRef.current = undefined;
						}
					},
					...(isTreeDrag ? { onDropTargetChange: onTreeDropTargetChange } : {}),
				}),
			);
		},
		[
			id,
			level,
			isParent,
			isExpanded,
			isInTransition,
			isTreeDrag,
			parentId,
			depth,
			childItemsHeight,
			onExpandChanged,
			onRowDragOver,
			onRowDragChildOverParent,
			onDrop,
			onResize,
			onRowDragChildOverParentEnd,
		],
	);

	const cleanupDroppable = useCallback(() => {
		cleanupDragAndDrop.current?.();
	}, []);

	return [makeDroppable, cleanupDroppable];
};

export { useListDropTarget };
