import { batchActions } from 'redux-batched-actions';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import {
	INITIALIZE_CRITICAL_DATA,
	type InitializeCriticalDataAction,
} from '../../state/app/actions';
import { getCriticalDataActions } from '../common/initialize/utils.tsx';
import type { StateEpic } from '../common/types';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<InitializeCriticalDataAction>) =>
	action$
		.ofType(INITIALIZE_CRITICAL_DATA)
		.mergeMap((action) =>
			Observable.of(batchActions(getCriticalDataActions(action.payload))),
		)) as StateEpic;
