import React, { type ComponentType } from 'react';
import { ExperienceTracker as ViewExperienceTracker } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracker-consumer/tracker-base/index.tsx';

export type Props = {
	isMacroView: boolean;
	isServer: boolean;
};

type ComponentBeingTrackedProps = Props & {
	onExperienceSuccess: () => void;
};

export const withViewExperienceTracker = (
	ComponentBeingTracked: ComponentType<ComponentBeingTrackedProps>,
) => {
	const WithViewExperienceTracker = (props: Props) => (
		<ViewExperienceTracker location="roadmap">
			{({ onExperienceSuccess }) => (
				<ComponentBeingTracked onExperienceSuccess={onExperienceSuccess} {...props} />
			)}
		</ViewExperienceTracker>
	);

	return WithViewExperienceTracker;
};
