import { gridSize, borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';

export const BAR_HEIGHT: number = gridSize * 3;
export const BASE_BAR_HEIGHT: number = gridSize * 2.5;
// A bar *can* be smaller than this, but requires special handling when it is
export const MINIMUM_BAR_LENGTH = 36;

export const DEFAULT_BORDER_RADIUS = `${borderRadius}px`;
export const PLACEHOLDER_BORDER_RADIUS = `${borderRadius * 3}px`;
export const LEFT_PLACEHOLDER_BORDER_RADIUS = `${PLACEHOLDER_BORDER_RADIUS} ${DEFAULT_BORDER_RADIUS} ${DEFAULT_BORDER_RADIUS} ${PLACEHOLDER_BORDER_RADIUS}`;
export const RIGHT_PLACEHOLDER_BORDER_RADIUS = `${DEFAULT_BORDER_RADIUS} ${PLACEHOLDER_BORDER_RADIUS} ${PLACEHOLDER_BORDER_RADIUS} ${DEFAULT_BORDER_RADIUS}`;
export const LEFT_AND_RIGHT_PLACEHOLDER_BORDER_RADIUS = `${PLACEHOLDER_BORDER_RADIUS}`;

export const START_DATE_PLACEHOLDER = 'START_DATE_PLACEHOLDER' as const;
export const DUE_DATE_PLACEHOLDER = 'DUE_DATE_PLACEHOLDER' as const;
export const START_AND_DUE_DATE_PLACEHOLDER = 'START_AND_DUE_DATE_PLACEHOLDER' as const;
