import { createSelector } from 'reselect';
import invert from 'lodash/invert';
import type { IssueType } from '@atlassian/jira-filters/src/common/types.tsx';
import { createSelectorWithDefaultEqual } from '../../../../../common/utils/reselect';
import { getIssueTypeIdHash } from '../../../../entities/issues/selectors';
import { getChildIssueTypes } from '../../../filters';

const getIssueTypesUsageHash = createSelector(getIssueTypeIdHash, invert);
const getSortedChildIssueTypes = createSelector(
	getChildIssueTypes,
	getIssueTypesUsageHash,
	(childIssueTypes, issueTypesUsageHash): IssueType[] =>
		childIssueTypes
			.filter(({ id }) => issueTypesUsageHash[id])
			.sort((issueTypeA, issueTypeB) =>
				issueTypeA.name.toLowerCase() < issueTypeB.name.toLowerCase() ? -1 : 1,
			),
);

export const getIssueTypes = createSelectorWithDefaultEqual(
	getSortedChildIssueTypes,
	(childIssueTypes: IssueType[]): IssueType[] => childIssueTypes,
);
