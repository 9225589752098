import React, { useCallback } from 'react';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import ExportIcon from '@atlaskit/icon/glyph/export';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { ResponsiveButton } from '@atlassian/jira-responsive-button/src/ui/index.tsx';
import type { ExportCsvActionPayload } from '../../../common/types/export-action';
import messages from './messages';

type Props = {
	onExportCsv: (payload: ExportCsvActionPayload) => void;
	onExportAsImageClick?: () => void;
};

const ExportButton = ({ onExportCsv, onExportAsImageClick }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onExportCsvCallback = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({});
		fireUIAnalytics(analyticsEvent, 'exportCsv clicked');
		onExportCsv({
			inferredStartDateHeader: formatMessage(messages.exportSpreadsheetInferredStartDate),
			inferredDueDateHeader: formatMessage(messages.exportSpreadsheetInferredDueDate),
		});
	}, [createAnalyticsEvent, onExportCsv, formatMessage]);

	return (
		<DropdownMenu
			trigger={({ triggerRef, ...props }) => (
				<ResponsiveButton
					label={formatMessage(messages.exportButton)}
					// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
					icon={ExportIcon}
					ref={triggerRef}
					{...props}
				/>
			)}
		>
			<DropdownItemGroup>
				<DropdownItem onClick={onExportAsImageClick}>
					{formatMessage(messages.exportImagePng)}
				</DropdownItem>
				<DropdownItem onClick={onExportCsvCallback}>
					{formatMessage(messages.exportSpreadsheetCsv)}
				</DropdownItem>
			</DropdownItemGroup>
		</DropdownMenu>
	);
};

export { ExportButton };
