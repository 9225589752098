import { defineSimpleStorage } from '@atlassian/jira-common-storage-redux/src/config.tsx';
import type { TimelineMode } from '@atlassian/jira-software-roadmap-timeline-table/src/common/types/timeline.tsx';
import { getTimelineMode } from '../../../state/selectors/filters';
import type { State } from '../../../state/types';
import { setTimelineMode } from '../../../state/ui/filters/actions';

export default defineSimpleStorage<State, TimelineMode | null>(
	() => 'timelineMode',
	(timelineMode: TimelineMode | null) =>
		timelineMode === null ? [] : [setTimelineMode(timelineMode)],
	(state) => getTimelineMode(state),
);
