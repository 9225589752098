import { DEFAULT_LEVEL_ONE_VIEW_SETTINGS } from '@atlassian/jira-software-roadmap-model/src/filter/index.tsx';
import {
	SET_DEPENDENCIES_VIEW_VISIBILITY,
	SET_PROGRESS_VISIBILITY,
	SET_WARNINGS_VISIBILITY,
	SET_LEVEL_ONE_SETTING,
	SET_RELEASES_VISIBILITY,
	type SetDependenciesViewVisibilityAction,
	type SetProgressVisibilityAction,
	type SetWarningsVisibilityAction,
	type SetLevelOneSettingAction,
	type SetReleasesVisibilityAction,
} from './actions';
import type { ViewsState as State } from './types';

const defaultState = {
	isDependenciesVisible: true,
	isProgressVisible: true,
	isWarningsVisible: true,
	isReleasesVisible: true,
	levelOneViewSettings: DEFAULT_LEVEL_ONE_VIEW_SETTINGS,
} as const;

type Action =
	| SetDependenciesViewVisibilityAction
	| SetProgressVisibilityAction
	| SetWarningsVisibilityAction
	| SetLevelOneSettingAction
	| SetReleasesVisibilityAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = defaultState, action: Action): State => {
	switch (action.type) {
		case SET_DEPENDENCIES_VIEW_VISIBILITY:
			return {
				...state,
				isDependenciesVisible: action.payload,
			};
		case SET_PROGRESS_VISIBILITY:
			return {
				...state,
				isProgressVisible: action.payload,
			};
		case SET_WARNINGS_VISIBILITY:
			return {
				...state,
				isWarningsVisible: action.payload,
			};
		case SET_RELEASES_VISIBILITY:
			return {
				...state,
				isReleasesVisible: action.payload,
			};
		case SET_LEVEL_ONE_SETTING:
			return {
				...state,
				levelOneViewSettings: action.payload,
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
