import type { ActionsObservable } from 'redux-observable';
import { tap } from 'rxjs/operators/tap';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/take';
import { JQL_FILTERS_REAPPLY } from '@atlassian/jira-software-roadmap-utils/src/utils/metrics/common/constants.tsx';
import { interactionPerformance } from '../../metrics';
import {
	CREATE_ISSUE,
	type CreateIssueAction,
	SCHEDULE_ISSUES,
	type ScheduleIssuesAction,
	UPDATE_ISSUE,
	type UpdateIssueAction,
} from '../../state/entities/issues/actions';
import { JQL_FILTERS_SUCCESS } from '../../state/ui/filters/actions';
import type { StateEpic } from '../common/types';

type Actions = CreateIssueAction | UpdateIssueAction | ScheduleIssuesAction;

/**
 * Listens for redux actions that may cause the JQL filters to be reapplied, and times how long it takes for them to be
 * reapplied. It will cancel the timer if another action is detected, since the reapply op cancels requests in a similar
 * way.
 */
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<Actions>) =>
	action$
		.ofType(CREATE_ISSUE, UPDATE_ISSUE, SCHEDULE_ISSUES)
		.switchMap(() => {
			interactionPerformance.start(JQL_FILTERS_REAPPLY);

			return action$
				.ofType(JQL_FILTERS_SUCCESS)
				.take(1)
				.pipe(tap(() => interactionPerformance.stop(JQL_FILTERS_REAPPLY)));
		})
		.ignoreElements()) as StateEpic;
