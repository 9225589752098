import type { UserHash } from '@atlassian/jira-software-roadmap-model/src/user/index.tsx';

export const ADD_USERS = 'state.entities.users.ADD_USERS' as const;

export type AddUsersAction = {
	type: typeof ADD_USERS;
	payload: UserHash;
};

export const addUsers = (payload: UserHash): AddUsersAction => ({
	type: ADD_USERS,
	payload,
});
