import isEmpty from 'lodash/isEmpty';
import { generateDefaultValues } from '@atlassian/jira-new-issue-create-trigger/src/utils';
import type {
	IssueTriggerPayload,
	GICPayload,
} from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/types';
import type {
	ComponentId,
	IssueKey,
	VersionId,
	ProjectId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import type { Issue } from '@atlassian/jira-software-roadmap-model/src/issue/index.tsx';
import type { AggErrors } from '@atlassian/jira-software-roadmap-services/src/common/types.tsx';
import {
	getProjectId,
	getEpicLinkCustomFieldId,
	getEpicNameCustomFieldId,
} from '../../../../state/configuration/selectors';
import { getIssueKeyById } from '../../../../state/entities/issues/selectors';
import type { State } from '../../../../state/types';

const asStringArray = (array: VersionId[] | ComponentId[]): string[] => array;

export const buildGICPayload = (
	issue: Issue,
	projectId: ProjectId,
	parentIssueKey?: IssueKey,
	epicLinkCustomFieldId?: string,
	epicNameCustomFieldId?: string,
): IssueTriggerPayload => {
	const outputPayload: IssueTriggerPayload = {};

	const { summary, issueTypeId, assignee, labels, versionIds, componentIds, parentId } = issue;

	outputPayload.project = {
		projectId,
	};
	outputPayload.issueType = {
		issueTypeId,
	};

	if (parentId?.value !== undefined) {
		outputPayload.parentId = {
			parentId: parentId.value,
		};
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const otherFields: Record<string, any> = {
		summary: summary.value,
	};
	if (parentIssueKey !== undefined && epicLinkCustomFieldId !== undefined) {
		otherFields.epic = {
			fieldId: epicLinkCustomFieldId,
			fieldValue: `key:${parentIssueKey}`,
		};
	}
	if (parentIssueKey === undefined && epicNameCustomFieldId !== undefined) {
		otherFields.epicName = {
			fieldId: epicNameCustomFieldId,
			fieldValue: summary.value,
		};
	}
	if (assignee !== undefined) {
		otherFields.assignee = assignee;
	}
	if (labels !== undefined) {
		otherFields.labels = labels;
	}
	if (versionIds !== undefined) {
		otherFields.fixVersions = asStringArray(versionIds);
	}
	if (componentIds !== undefined) {
		otherFields.components = asStringArray(componentIds);
	}

	if (Object.keys(otherFields).length > 0) {
		const { defaultValues, fieldIds } = generateDefaultValues(
			parentId?.value !== undefined ? { parent: parentId.value, ...otherFields } : otherFields,
		);
		if (!isEmpty(defaultValues)) {
			outputPayload.defaultValues = defaultValues;
		}
		outputPayload.customFieldsToPreserveOnFormRecreation = fieldIds;
	}

	return outputPayload;
};

/* We do not populate the start date field. This is because without transforming
 * it to the users configured date format, it would block creation from the dialog.
 * We should revisit this in the future #ROAD-3096
 */
export const getFieldsForCreateDialogGIC = (
	state: State,
	issue: Issue,
	reason?: string,
): Partial<GICPayload> => {
	const { parentId } = issue;

	const projectId = getProjectId(state);
	const parentKey = parentId.value ? getIssueKeyById(state, parentId.value) : undefined;
	const epicLinkCustomFieldId = getEpicLinkCustomFieldId(state);
	const epicNameCustomFieldId = getEpicNameCustomFieldId(state);

	return {
		payload: buildGICPayload(
			issue,
			projectId,
			parentKey,
			epicLinkCustomFieldId,
			epicNameCustomFieldId,
		),
		submitErrorMessage: reason,
	};
};

export const getGICReasonOnCreateError = (errors?: AggErrors | null): string | null | undefined => {
	const createError =
		errors && errors.find((e) => e && e.extensions && e.extensions.statusCode === 400);
	if (createError) {
		return createError.message;
	}

	return undefined;
};
