import React, { type ComponentPropsWithoutRef } from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import { ROADMAP_PACKAGE_NAME } from '../../../../../../constants';
import type ReparentMenu from './index';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const ReparentMenuWrapperLazy = lazyAfterPaint<typeof ReparentMenu>(
	() => import(/* webpackChunkName: "async-standard-roadmap-reparent-menu" */ './index'),
	{ ssr: false },
);

type Props = JSX.LibraryManagedAttributes<
	typeof ReparentMenu,
	ComponentPropsWithoutRef<typeof ReparentMenu>
>;

export const ReparentMenuWrapperAsync = (props: Props) => (
	<JSErrorBoundary
		id="async-standard-roadmap-reparent-menu"
		packageName={ROADMAP_PACKAGE_NAME}
		fallback="flag"
	>
		<Placeholder name="async-standard-roadmap-reparent-menu" fallback={null}>
			<ReparentMenuWrapperLazy {...props} />
		</Placeholder>
	</JSErrorBoundary>
);
