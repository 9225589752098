import type { Hash } from '@atlassian/jira-software-roadmap-model/src/common/index.tsx';
import type { IssueTypeHash } from '@atlassian/jira-software-roadmap-model/src/issue-type/index.tsx';
import type { Option as EditDependencyOption } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/edit-dependency-modal.tsx';

export const getDependencyOptionsPure = (
	issueIds: string[],
	issueTypeIdHash: Hash<string>,
	issueTypesHash: IssueTypeHash,
	issueKeyHash: Hash<string | undefined>,
	issueSummaryHash: Hash<string>,
): EditDependencyOption[] =>
	issueIds.reduce((dependencyOptions: EditDependencyOption[], id) => {
		const issueKey = issueKeyHash[id];
		const issueType = issueTypesHash[issueTypeIdHash[id]];

		if (issueKey) {
			dependencyOptions.push({
				id,
				issueKey,
				issueSummary: issueSummaryHash[id],
				issueType: issueType.name,
				iconUrl: issueType.iconUrl,
			});
		}

		return dependencyOptions;
	}, []);
