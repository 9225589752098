/** @jsx jsx */
import React, { useState, type ReactNode } from 'react';
import { css, jsx } from '@compiled/react';
import ArrowLeftCircleIcon from '@atlaskit/icon/glyph/arrow-left-circle';
import ArrowRightCircleIcon from '@atlaskit/icon/glyph/arrow-right-circle';
import { N0, N50, N70 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { gridSize, borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { CHART_NAVIGATE_BUTTON_Z_INDEX } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/z-index.tsx';
import { useIsScrollingX } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/scroll-meta/main.tsx';
import { useViewport } from '@atlassian/jira-software-roadmap-timeline-table/src/common/context/viewport/context/index.tsx';
import { NAVIGATE_OFFSET } from './constants';

// Constants //

const BUTTON_SIZE = 3 * gridSize;
const DATE_OFFSET = BUTTON_SIZE + gridSize;
const SCROLL_BAR_OFFSET = 10;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const PAST_BORDER_RADIUS = `0 ${borderRadius}px ${borderRadius}px 0`;
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const FUTURE_BORDER_RADIUS = `${borderRadius}px 0 0 ${borderRadius}px`;

const baseButtonStyles = css({
	position: 'absolute',
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${3 * gridSize}px`,
	boxSizing: 'border-box',
	padding: token('space.050', '4px'),
	backgroundColor: token('color.background.neutral', N70),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: CHART_NAVIGATE_BUTTON_Z_INDEX,
	cursor: 'pointer',
	'&:hover': {
		backgroundColor: token('color.background.neutral.hovered', N70),
	},
});

const pastButtonBorderRadiusStyles = css({
	borderRadius: PAST_BORDER_RADIUS,
});

const futureButtonBorderRadiusStyles = css({
	borderRadius: FUTURE_BORDER_RADIUS,
});

const pastLabelContainerStyles = css({
	position: 'absolute',
	left: token('space.400', '32px'),
});

const futureLabelContainerStyles = css({
	position: 'absolute',
});

type Props = {
	// Current pixel boundaries of the chart-item-content. Will be used to check whether the item is before or after the visible viewport.
	itemMinLeftPosition: number;
	itemMaxRightPosition: number;
	// Label to be shown when the button is hovered and the item is before or after the visible viewport.
	label: ReactNode;
};

const RoadmapNavigateButton = ({ itemMinLeftPosition, itemMaxRightPosition, label }: Props) => {
	const { requestViewportScrollBounding, setViewportScroll } = useViewport();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [isScrollingX] = useIsScrollingX();
	const [isHovered, setIsHovered] = useState(false);

	if (isScrollingX) {
		return null;
	}

	const { scrollLeft, scrollRight, scrollWidth, isOverflowY } = requestViewportScrollBounding();
	const isItemInPast = scrollWidth - itemMaxRightPosition < scrollLeft;
	const isItemInFuture = scrollWidth - itemMinLeftPosition < scrollRight;

	// The chart-item-content is visible in the viewport, no need to navigate to it.
	if (!isItemInPast && !isItemInFuture) {
		return null;
	}

	// Callbacks //

	const onMouseEnter = () => {
		setIsHovered(true);
	};

	const onMouseLeave = () => {
		setIsHovered(false);
	};

	const onClick = () => {
		const analyticsEvent = createAnalyticsEvent({ action: 'clicked', actionSubject: 'button' });
		const analyticsAttributes = { direction: isItemInPast ? 'past' : 'future' };
		fireUIAnalytics(analyticsEvent, 'navigateButton', analyticsAttributes);
		setViewportScroll({ scrollLeft: itemMinLeftPosition - NAVIGATE_OFFSET });
	};

	// Render //

	// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
	const renderArrowIcon = (Icon: typeof ArrowLeftCircleIcon) => (
		<Icon
			size="small"
			label=""
			primaryColor={isHovered ? token('color.icon', N0) : token('color.icon', N50)}
			secondaryColor={token('color.icon.inverse', N70)}
		/>
	);

	if (isItemInPast) {
		return (
			<div
				css={[baseButtonStyles, pastButtonBorderRadiusStyles]}
				style={{
					left: `${scrollLeft}px`,
					width: `${BUTTON_SIZE}px`,
				}}
				data-testid="roadmap.timeline-table-kit.ui.chart-item-content.date-content.navigate-button.navigate"
				onClick={onClick}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				onKeyDown={(e) => (e.key === 'Enter' || e.key === 'Space') && onClick()}
				role="presentation"
			>
				{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
				{renderArrowIcon(ArrowLeftCircleIcon)}
				{isHovered && <div css={pastLabelContainerStyles}>{label}</div>}
			</div>
		);
	}

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
		<div
			css={[baseButtonStyles, futureButtonBorderRadiusStyles]}
			style={{
				right: `${scrollRight}px`,
				width: `${BUTTON_SIZE + (isOverflowY ? SCROLL_BAR_OFFSET : 0)}px`,
			}}
			data-testid="roadmap.timeline-table-kit.ui.chart-item-content.date-content.navigate-button.navigate"
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons */}
			{renderArrowIcon(ArrowRightCircleIcon)}
			{isHovered && (
				<div
					css={futureLabelContainerStyles}
					style={{
						right: `${DATE_OFFSET + (isOverflowY ? SCROLL_BAR_OFFSET : 0)}px`,
					}}
				>
					{label}
				</div>
			)}
		</div>
	);
};

export { RoadmapNavigateButton };
