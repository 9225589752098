import { isParentLevel } from '@atlassian/jira-software-roadmap-model/src/hierarchy/index.tsx';
import type { IssueType } from '@atlassian/jira-software-roadmap-model/src/issue-type/index.tsx';
import type { Issue } from '@atlassian/jira-software-roadmap-model/src/issue/index.tsx';
import {
	START_DATE_PLACEHOLDER,
	DUE_DATE_PLACEHOLDER,
	START_AND_DUE_DATE_PLACEHOLDER,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/chart-item.tsx';
import {
	INTERVAL,
	ROLLUP,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/date.tsx';
import type { EditDatesOptionParams } from '../../../../model/list-item-menu-option';
import type { ChartData } from '../../table';

export const getEditDatesOptionParamsPure = (
	isChildIssuePlanningEnabled: boolean,
	isSprintsPlanningEnabled: boolean,
	isStartDateRequired: boolean,
	isDueDateRequired: boolean,
	issue: Issue | undefined,
	issueTypeData: IssueType,
	chartData: ChartData | undefined,
): EditDatesOptionParams => {
	const { key: issueKey = '', summary, startDate: startDateRaw, dueDate: dueDateRaw } = issue ?? {};
	const { name: issueType, iconUrl } = issueTypeData ?? {};
	const {
		level = 0,
		startDate,
		dueDate,
		startDateType,
		dueDateType,
		placeholder,
		warnings,
	} = chartData ?? {};

	const isParent = isParentLevel(level);
	const isDateEditable = (isChildIssuePlanningEnabled && !isSprintsPlanningEnabled) || isParent;

	const isStartDatePlaceholder =
		placeholder === START_DATE_PLACEHOLDER || placeholder === START_AND_DUE_DATE_PLACEHOLDER;
	const isDueDatePlaceholder =
		placeholder === DUE_DATE_PLACEHOLDER || placeholder === START_AND_DUE_DATE_PLACEHOLDER;
	const isStartDateInferred = startDateType === ROLLUP || startDateType === INTERVAL;
	const isDueDateInferred = dueDateType === ROLLUP || dueDateType === INTERVAL;
	const isStartDateEmpty = startDate === undefined || isStartDatePlaceholder || isStartDateInferred;
	const isDueDateEmpty = dueDate === undefined || isDueDatePlaceholder || isDueDateInferred;
	const isStartDateClearable = !isStartDateRequired && !isStartDateEmpty;
	const isDueDateClearable = !isDueDateRequired && !isDueDateEmpty;
	const isDateFlipped =
		startDate !== undefined &&
		dueDate !== undefined &&
		startDate < dueDate &&
		(startDate === dueDateRaw?.value || dueDate === startDateRaw?.value);

	const canHaveChildRollup = isChildIssuePlanningEnabled && !isSprintsPlanningEnabled && isParent;
	const canHaveSprintRollup = isChildIssuePlanningEnabled && isSprintsPlanningEnabled && isParent;
	const currentStartDate = isStartDateEmpty ? undefined : new Date(startDate);
	const currentDueDate = isDueDateEmpty ? undefined : new Date(dueDate);

	return {
		isDateEditable,
		isStartDateRequired,
		isDueDateRequired,
		startDate,
		dueDate,
		editDatesModalParams: {
			isStartDateInferred,
			isDueDateInferred,
			canHaveChildRollup,
			canHaveSprintRollup,
			issueKey,
			issueSummary: summary?.value ?? '',
			issueType,
			iconUrl,
			level,
			currentStartDate,
			currentDueDate,
			warnings: warnings ?? [],
		},
		// NOTE: Dates removal check should be based on original dates instead of chart dates (flipped dates included)
		removeDatesOptionParams: {
			isStartDateClearable: isDateFlipped ? isDueDateClearable : isStartDateClearable,
			isDueDateClearable: isDateFlipped ? isStartDateClearable : isDueDateClearable,
			isStartDateRolledUp: isDateFlipped ? isDueDateInferred : isStartDateInferred,
			isDueDateRolledUp: isDateFlipped ? isStartDateInferred : isDueDateInferred,
		},
	};
};
