import isEqual from 'lodash/isEqual';
import memoizeOne from 'memoize-one';
import { STATUS_PRIORITY } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/key-date.tsx';
import type {
	HighlightLine,
	KeyDatesGroup,
	KeyDateStatus,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/key-date.tsx';

export const getKeyDatesAttributesHash = memoizeOne(
	(
		keyDateGroups: KeyDatesGroup[],
	): {
		[key: string]: {
			groupId: string;
			left: number;
			status: KeyDateStatus;
		};
	} => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const keyDatesAttributesHash: Record<string, any> = {};
		keyDateGroups.forEach((keyDateGroup) => {
			const { leftPosition, keyDates: keyDatesWithinGroup, id: groupId } = keyDateGroup;
			keyDatesWithinGroup.forEach(({ id, status }) => {
				keyDatesAttributesHash[`${id}`] = {
					groupId,
					left: leftPosition,
					status,
				};
			});
		});
		return keyDatesAttributesHash;
	},
	(newArgs, lastArgs) => {
		if (newArgs.length !== lastArgs.length) {
			return false;
		}

		const equalityFn = isEqual;
		return newArgs.every((newArg, index) => equalityFn(newArg, lastArgs[index]));
	},
);

export const getHighlightLines = memoizeOne(
	({
		keyDateGroups,
		highlightedKeyDates,
	}: {
		keyDateGroups: KeyDatesGroup[];
		highlightedKeyDates: string[];
	}): HighlightLine[] => {
		const highlightGroups: {
			[key: string]: HighlightLine;
		} = {};
		const keyDatesAttributesHash = getKeyDatesAttributesHash(keyDateGroups);

		highlightedKeyDates.forEach((id) => {
			const keyDateAttributes = keyDatesAttributesHash[id];
			if (keyDateAttributes) {
				const { left, groupId, status } = keyDateAttributes;

				// if multiple lines from the same group, show only one with the highest status priority
				const highlightedGroup = highlightGroups[groupId];
				if (
					!highlightedGroup ||
					STATUS_PRIORITY[status] > STATUS_PRIORITY[highlightedGroup.status]
				) {
					highlightGroups[groupId] = { left, status, id: groupId };
				}
			}
		});

		return Object.keys(highlightGroups).map((key) => highlightGroups[key]);
	},
	(newArgs, lastArgs) => {
		if (newArgs.length !== lastArgs.length) {
			return false;
		}

		const equalityFn = isEqual;
		return newArgs.every((newArg, index) => equalityFn(newArg, lastArgs[index]));
	},
);
