import type { RoadmapGoalHash } from '@atlassian/jira-software-roadmap-model/src/goals/index.tsx';

export const SET_GOALS = 'state.entities.goals.SET_GOALS' as const;

export type SetGoalsAction = {
	type: typeof SET_GOALS;
	payload: RoadmapGoalHash;
};

export const setGoals = (payload: RoadmapGoalHash): SetGoalsAction => ({
	type: SET_GOALS,
	payload,
});
