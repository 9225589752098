import type { ProjectId, VersionId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { ComponentsHash } from '@atlassian/jira-software-roadmap-model/src/component/index.tsx';
import type { Sprint } from '@atlassian/jira-software-roadmap-model/src/sprint/index.tsx';
import type { VersionWithStatus } from '@atlassian/jira-software-roadmap-model/src/version/index.tsx';
import type { canInviteOthersResponse } from '@atlassian/jira-software-roadmap-services/src/user-invite/types.tsx';
import type { ConfigurationState, DeriveFieldsHashState } from './types';

export const SET_CONFIGURATION = 'state.configuration.SET_CONFIGURATION' as const;

export const SET_TODAY = 'state.configuration.SET_TODAY' as const;

export const SET_COMPONENTS = 'state.configuration.SET_COMPONENTS' as const;

export const SET_VERSION = 'state.configuration.SET_VERSION' as const;

export const SET_SPRINT = 'state.configuration.SET_SPRINT' as const;

export type SetConfigurationAction = {
	type: typeof SET_CONFIGURATION;
	payload: ConfigurationState;
};

export const setConfiguration = (payload: ConfigurationState): SetConfigurationAction => ({
	type: SET_CONFIGURATION,
	payload,
});

export type SetTodayAction = {
	type: typeof SET_TODAY;
	payload: number;
};

export const setToday = (payload: number): SetTodayAction => ({
	type: SET_TODAY,
	payload,
});

type SetComponentsPayload = {
	projectId: ProjectId;
	components: ComponentsHash;
};

export type SetComponentsAction = {
	type: typeof SET_COMPONENTS;
	payload: SetComponentsPayload;
};

export const setComponents = (payload: SetComponentsPayload) => ({
	type: SET_COMPONENTS,
	payload,
});

export interface SetVersionPayload extends VersionWithStatus {
	id: VersionId;
}

export type SetVersionAction = {
	type: typeof SET_VERSION;
	payload: SetVersionPayload;
};

export const setVersion = (payload: SetVersionPayload): SetVersionAction => ({
	type: SET_VERSION,
	payload,
});

export type sprintPayload = Sprint;

export type SetSprintAction = {
	type: typeof SET_SPRINT;
	payload: sprintPayload;
};

export const setSprint = (payload: sprintPayload): SetSprintAction => ({
	type: SET_SPRINT,
	payload,
});

// === Issue create context from board JQL and applied JQL filters

export const DERIVE_FIELDS_REQUEST = 'state.configuration.DERIVE_FIELDS_REQUEST' as const;

export type DeriveFieldsRequestAction = {
	type: typeof DERIVE_FIELDS_REQUEST;
};

export const deriveFieldsRequest = (): DeriveFieldsRequestAction => ({
	type: DERIVE_FIELDS_REQUEST,
});

export const SET_DERIVE_FIELDS = 'state.configuration.SET_DERIVE_FIELDS' as const;

export type SetDeriveFieldsHashAction = {
	type: typeof SET_DERIVE_FIELDS;
	payload: DeriveFieldsHashState;
};

export const setDeriveFieldsHash = (payload: DeriveFieldsHashState): SetDeriveFieldsHashAction => ({
	type: SET_DERIVE_FIELDS,
	payload,
});

export const SET_CAN_USER_INVITE = 'state.actions.can-user-invite.SET_CAN_USER_INVITE' as const;

export type SetCanUserInviteAction = {
	type: typeof SET_CAN_USER_INVITE;
	payload: canInviteOthersResponse;
};

export const setCanUserInvite = (payload: canInviteOthersResponse): SetCanUserInviteAction => ({
	type: SET_CAN_USER_INVITE,
	payload,
});
