import React from 'react';
import type { FlagsMapper, FlagComponentProps } from '@atlassian/jira-flags';
import {
	GENERAL_ERROR,
	type GeneralErrorAction,
	IRRELEVANT_ISSUES_CREATED,
	type IrrelevantIssuesCreatedAction,
	UNABLE_TO_RANK_ISSUE,
	type UnableToRankIssueAction,
	HIDDEN_DEPENDENCY_CREATED,
	type HiddenDependencyCreatedAction,
	DEPENDENCIES_UNAVAILABLE,
	type DependenciesUnavailableAction,
	DEPENDENCIES_PERMISSION,
	type DependenciesPermissionAction,
	UPDATE_ITEM_PERMISSION,
	type UpdateItemPermissionAction,
	AGG_ERROR,
	type AggErrorAction,
	SPRINT_SCOPE_CHANGE,
	type SprintScopeChangeAction,
	ISSUE_DISAPPEARS_FROM_DISPLAY_RANGE,
	type IssueDisappearsFromDisplayRangeAction,
	INTERACTED_ITEMS_LIMIT_EXCEEDED,
	type InteractedItemsLimitExceededAction,
	DEPENDENCY_CYCLE_CREATION,
	type DependencyCycleCreationAction,
	DEPENDENCY_DUPLICATION,
	type DependencyDuplicationAction,
	ISSUE_HIDDEN,
	type IssueHiddenAction,
} from '../state/flags/actions';
import type { State } from '../state/types';
import AggErrorFlag from './agg-error/view.tsx';
import DependencyInvalidFlag from './dependencies-invalid';
import DependenciesPermissionFlag from './dependencies-permission/view.tsx';
import DependenciesUnavailableFlag from './dependencies-unavailable';
import GeneralErrorFlag from './general-error';
import HiddenDependencyCreatedFlag from './hidden-dependency-created';
import InteractedItemsLimitExceededFlag from './interacted-items-limit-exceeded';
import InteractedItemsLimitExceededWithActionsFlag from './interacted-items-limit-exceeded-with-actions';
import IrrelevantItemCreated from './irrelevant-item-created';
import IssueHiddenFlag from './issue-hidden';
import IssueUpdatePermissionFlag from './issue-update-permission';
import ItemDisappearsFromDisplayRangeFlag from './item-disappears-from-display-range';
import UnableToRankIssueFlag from './rank-disabled';
import SprintScopeChangeFlag from './sprint-scope-change';

type CombinedActions =
	| GeneralErrorAction
	| AggErrorAction
	| IssueHiddenAction
	| IrrelevantIssuesCreatedAction
	| HiddenDependencyCreatedAction
	| DependenciesUnavailableAction
	| DependenciesPermissionAction
	| UnableToRankIssueAction
	| SprintScopeChangeAction
	| IssueDisappearsFromDisplayRangeAction
	| UpdateItemPermissionAction
	| InteractedItemsLimitExceededAction
	| DependencyCycleCreationAction
	| DependencyDuplicationAction;

export const flagsMapper: FlagsMapper<CombinedActions, State> = (action) => {
	switch (action.type) {
		case GENERAL_ERROR:
			return (props: FlagComponentProps) => <GeneralErrorFlag {...props} />;
		case UPDATE_ITEM_PERMISSION:
			return (props: FlagComponentProps) => <IssueUpdatePermissionFlag {...props} />;
		case AGG_ERROR:
			return (props: FlagComponentProps) => <AggErrorFlag {...props} {...action.payload} />;
		case ISSUE_HIDDEN:
			return (props: FlagComponentProps) => <IssueHiddenFlag {...props} {...action.payload} />;
		case HIDDEN_DEPENDENCY_CREATED:
			return (props: FlagComponentProps) => <HiddenDependencyCreatedFlag {...props} />;
		case IRRELEVANT_ISSUES_CREATED:
			return (props: FlagComponentProps) => (
				<IrrelevantItemCreated {...props} issueKeys={action.payload.issueKeys} />
			);
		case DEPENDENCIES_UNAVAILABLE:
			return (props: FlagComponentProps) => <DependenciesUnavailableFlag {...props} />;
		case ISSUE_DISAPPEARS_FROM_DISPLAY_RANGE:
			return (props: FlagComponentProps) => (
				<ItemDisappearsFromDisplayRangeFlag {...props} {...action.payload} />
			);
		case DEPENDENCIES_PERMISSION:
			return (props: FlagComponentProps) => (
				<DependenciesPermissionFlag {...props} {...action.payload} />
			);
		case UNABLE_TO_RANK_ISSUE:
			return (props: FlagComponentProps) => (
				<UnableToRankIssueFlag {...props} boardId={action.payload.boardId} />
			);
		case SPRINT_SCOPE_CHANGE:
			return (props: FlagComponentProps) => (
				<SprintScopeChangeFlag {...props} {...action.payload} />
			);
		case INTERACTED_ITEMS_LIMIT_EXCEEDED:
			return (props: FlagComponentProps) =>
				action.payload ? (
					<InteractedItemsLimitExceededWithActionsFlag {...props} {...action.payload} />
				) : (
					<InteractedItemsLimitExceededFlag {...props} />
				);
		case DEPENDENCY_CYCLE_CREATION:
		case DEPENDENCY_DUPLICATION:
			return (props: FlagComponentProps) => (
				<DependencyInvalidFlag
					{...props}
					{...action.payload}
					isDependencyDuplicated={action.type === DEPENDENCY_DUPLICATION}
				/>
			);
		default: {
			const _exhaustiveCheck: never = action;
			return undefined;
		}
	}
};
