import { DEFAULT_LEVEL_ONE_VIEW_SETTINGS } from '@atlassian/jira-software-roadmap-model/src/filter/index.tsx';
import { DEFAULT_PANEL_RATIO } from '@atlassian/jira-software-roadmap-model/src/settings/constants.tsx';
import {
	MONTHS,
	DEFAULT_LIST_WIDTH,
} from '@atlassian/jira-software-roadmap-timeline-table/src/common/constants.tsx';
import { SET_TIMELINE_MODE, type SetTimelineModeAction } from '../ui/filters/actions';
import {
	SET_SETTINGS,
	type SetSettingAction,
	SET_LIST_WIDTH,
	type SetListWidthAction,
	SET_CREATION_PREFERENCES,
	type SetCreationPreferencesAction,
	UPDATE_CREATION_PREFERENCES,
	type UpdateCreationPreferencesAction,
	SET_HIGHLIGHTED_VERSIONS,
	type SetHighlightedVersionsAction,
	SET_ISSUE_PANEL_RATIO,
	type SetIssuePanelRatioAction,
} from './actions';
import type { UserSettingsState } from './types';

type Action =
	| SetTimelineModeAction
	| SetSettingAction
	| SetListWidthAction
	| SetCreationPreferencesAction
	| UpdateCreationPreferencesAction
	| SetHighlightedVersionsAction
	| SetIssuePanelRatioAction;

const defaultState: UserSettingsState = {
	timelineMode: MONTHS,
	listWidth: DEFAULT_LIST_WIDTH,
	issuePanelRatio: DEFAULT_PANEL_RATIO,
	creationPreferences: {
		itemTypes: {},
	},
	isDependenciesVisible: false,
	isProgressVisible: false,
	isWarningsVisible: false,
	isReleasesVisible: false,
	highlightedVersions: [],
	levelOneViewSettings: DEFAULT_LEVEL_ONE_VIEW_SETTINGS,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: UserSettingsState = defaultState, action: Action): UserSettingsState => {
	switch (action.type) {
		case SET_SETTINGS:
			return {
				...state,
				...action.payload,
			};

		case SET_TIMELINE_MODE:
			return {
				...state,
				timelineMode: action.payload,
			};

		case SET_LIST_WIDTH:
			return {
				...state,
				listWidth: action.payload,
			};

		case SET_CREATION_PREFERENCES:
			return {
				...state,
				creationPreferences: action.payload,
			};

		case UPDATE_CREATION_PREFERENCES:
			return {
				...state,
				creationPreferences: {
					...state.creationPreferences,
					itemTypes: {
						...state.creationPreferences.itemTypes,
						[action.payload.level]: {
							itemTypeId: action.payload.itemTypeId,
						},
					},
				},
			};

		case SET_HIGHLIGHTED_VERSIONS:
			return {
				...state,
				highlightedVersions: action.payload,
			};

		case SET_ISSUE_PANEL_RATIO:
			return {
				...state,
				issuePanelRatio: action.payload,
			};

		default:
			return state;
	}
};
