import type { Hash } from '@atlassian/jira-software-roadmap-model/src/common/index.tsx';
import type { IssueTypeHash } from '@atlassian/jira-software-roadmap-model/src/issue-type/index.tsx';
import type { Option as ChangeParentMenuOption } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/change-parent-menu/types.tsx';

export const getParentOptionsPure = (
	parentIds: string[],
	issueTypeIdHash: Hash<string>,
	issueTypesHash: IssueTypeHash,
	issueKeyHash: Hash<string | undefined>,
	issueSummaryHash: Hash<string>,
): ChangeParentMenuOption[] =>
	parentIds.reduce((parentOptions: ChangeParentMenuOption[], id) => {
		const issueKey = issueKeyHash[id];
		const issueSummary = issueSummaryHash[id];
		const issueTypeId = issueTypeIdHash[id];
		const issueType = issueTypesHash[issueTypeId];

		if (issueKey) {
			parentOptions.push({
				id,
				key: issueKey,
				summary: issueSummary,
				issueType: issueType.name,
				iconUrl: issueType.iconUrl,
			});
		}

		return parentOptions;
	}, []);
