import { useCallback, useRef } from 'react';
import { autoScroller } from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-autoscroll';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { ROW_DRAG_AND_DROP_ID } from '../../../common/constants';
import type { RowDragData } from '../../../common/types/drag-and-drop.tsx';

const useDragScroller = (timelineTop: number) => {
	const cleanupDragAndDrop = useRef<null | (() => void)>(null);
	const clientXPos = useRef<number>(0);
	const thresholdTop = useRef<number>(0);

	const setupDragScroller = useCallback(
		(viewportElement: HTMLElement | null) => {
			cleanupDragAndDrop.current = combine(
				monitorForElements({
					onDragStart: ({ source, location }) => {
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						const item = source.data as RowDragData;
						if (item.type === ROW_DRAG_AND_DROP_ID && viewportElement !== null) {
							const viewportBoundingRect = viewportElement?.getBoundingClientRect();
							const offsetTop = viewportBoundingRect?.top ?? 0;
							clientXPos.current =
								(viewportBoundingRect?.left ?? 0) + (viewportBoundingRect?.width ?? 0) / 2;
							thresholdTop.current = offsetTop / 2 + timelineTop + offsetTop;

							autoScroller.start({
								input: {
									...location.current.input,
									// lock the x axis
									pageX: clientXPos.current,
									clientX: clientXPos.current,
								},
							});
						}
					},
					onDrop: ({ source }) => {
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						const item = source.data as RowDragData;
						if (item.type === ROW_DRAG_AND_DROP_ID && viewportElement !== null) {
							autoScroller.stop();
						}
					},
					onDrag: ({ source, location }) => {
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						const item = source.data as RowDragData;

						if (item.type === ROW_DRAG_AND_DROP_ID && viewportElement !== null) {
							const { clientY, pageX, clientX, pageY, ...restInputs } = location.current.input;

							const newClientY = clientY < thresholdTop.current ? clientY - timelineTop : clientY;

							const input = {
								...restInputs,
								// lock the x axis
								pageX: clientXPos.current,
								clientX: clientXPos.current,
								pageY: newClientY,
								clientY: newClientY,
							};

							autoScroller.updateInput({
								input,
							});
						}
					},
				}),
			);
		},
		[timelineTop],
	);

	const teardownDragScroller = useCallback(() => {
		cleanupDragAndDrop.current?.();
	}, []);

	return [setupDragScroller, teardownDragScroller];
};

export { useDragScroller };
