import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/zip';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import {
	type IssueLinkDirection,
	IssueLinkDirectionTypes,
} from '@atlassian/jira-issue-shared-types/src/common/types/linked-issue-type.tsx';
import { ChangeEventTypes } from '@atlassian/jira-issue-view-model/src/change-type';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { getItems } from '@atlassian/jira-software-roadmap-services/src/issues/get.tsx';
import type { IssuesAndUsers } from '@atlassian/jira-software-roadmap-services/src/issues/types.tsx';
import { getSourceARI } from '../../state/app/selectors';
import { getFullIssueTypeHash } from '../../state/configuration/selectors';
import {
	RELOAD_ISSUE,
	type ReloadIssueAction as Action,
} from '../../state/entities/issues/actions';
import { isIssueExists, getIssueTypeIdHash } from '../../state/entities/issues/selectors';
import type { State } from '../../state/types';
import type { StateEpic } from '../common/types';
import { reloadIssues } from './common/reload';
import { updateIssuesAndUsers } from './common/update';

type NewLink = {
	linkedIssueId: IssueId;
	direction: IssueLinkDirection;
};

const updateDependencies = (store: MiddlewareAPI<State>, issueId: IssueId, links: NewLink[]) => {
	const state = store.getState();

	const issueIdsSet: Set<IssueId> = new Set();

	links.forEach((link) => {
		if (link.direction === IssueLinkDirectionTypes.INWARD) {
			issueIdsSet.add(issueId);
		} else if (link.direction === IssueLinkDirectionTypes.OUTWARD) {
			issueIdsSet.add(link.linkedIssueId);
		}
	});

	return Observable.zip(
		...Array.from(issueIdsSet.values())
			.filter((targetIssueId: IssueId) => isIssueExists(state, targetIssueId))
			.map((issueIdToFetch: IssueId) => getItems(getSourceARI(state), [issueIdToFetch])),
		(...issuesData: IssuesAndUsers[]) => {
			if (issuesData.length) {
				const getMergedIssues = () => {
					const result = {
						issues: {
							hash: {},
							sequence: [],
						},
						users: {},
					};
					issuesData.forEach((issuesDataItem) => {
						Object.assign(result.issues.hash, issuesDataItem.issues.hash);
						Object.assign(result.users, issuesDataItem.users);
					});
					return result;
				};

				const mergedIssues = getMergedIssues();
				return updateIssuesAndUsers(store, mergedIssues);
			}

			return Observable.empty<never>();
		},
	);
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default ((action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	// @ts-expect-error - Property 'type' is missing in type 'Observable<never>' but required in type 'Action'.
	action$.ofType(RELOAD_ISSUE).mergeMap((action: Action) => {
		const event = action.payload;

		switch (event.type) {
			case ChangeEventTypes.ISSUE_TYPE_CHANGED: {
				const { issueId } = event;

				return reloadIssues(store, [issueId]);
			}
			case ChangeEventTypes.ISSUE_LINKS_CREATED: {
				const {
					issueId,
					meta: { links },
				} = event;
				const state = store.getState();
				const issueTypeId = getIssueTypeIdHash(state)[issueId];
				const issueTypeHash = getFullIssueTypeHash(state);

				// Check for issue types that exist on the roadmap
				if (!issueTypeHash[issueTypeId]) {
					break;
				}

				return updateDependencies(store, issueId, links);
			}
			case ChangeEventTypes.ISSUE_LINK_DELETED: {
				const {
					issueId,
					meta: { direction, linkedIssueId },
				} = event;

				const state = store.getState();
				const issueTypeIdHash = getIssueTypeIdHash(state);
				const issueTypeHash = getFullIssueTypeHash(state);

				// Check for issue types that exist on the roadmap
				if (
					!issueTypeHash[issueTypeIdHash[issueId]] &&
					!issueTypeHash[issueTypeIdHash[linkedIssueId]]
				) {
					break;
				}

				if (direction === IssueLinkDirectionTypes.INWARD) {
					return reloadIssues(store, [issueId]);
				}
				if (direction === IssueLinkDirectionTypes.OUTWARD) {
					return reloadIssues(store, [linkedIssueId]);
				}

				break;
			}
			case ChangeEventTypes.CHILD_ISSUE_ADDED: {
				const {
					issueId,
					meta: {
						childIssue: { id: childIssueId },
					},
				} = event;

				if (isIssueExists(store.getState(), issueId)) {
					return reloadIssues(store, [childIssueId]);
				}
				break;
			}
			default: {
				// do nothing for other event types
				break;
			}
		}

		return Observable.empty<never>();
	})) as StateEpic;
