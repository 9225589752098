import type { SelectedDependency } from '@atlassian/jira-aais-dependency-lines-overlay/src/common/ui/types.tsx';
import { connect } from '@atlassian/jira-react-redux';
import { DEPENDENCIES_FLYOUT_TYPE } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/overlay.tsx';
import { RoadmapDependencyLines } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/chart-timeline-overlay/dependency-lines/index.tsx';
import type {
	DependencyItem,
	DependencyIds,
	DependencyClickCoords,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/chart-timeline-overlay/dependency-lines/types.tsx';
import {
	getSelectedDependency,
	getDependenciesItems,
} from '../../../../../state/selectors/dependencies';
import type { State } from '../../../../../state/types';
import { showToolTip } from '../../../../../state/ui/table/actions';

type StateProps = {
	dependencies: DependencyItem[];
	selectedDependency: SelectedDependency | undefined;
};

const mapStateToProps = (state: State): StateProps => ({
	dependencies: getDependenciesItems(state),
	selectedDependency: getSelectedDependency(state),
});

const onDependencyClick = ({ toId, fromId }: DependencyIds, position: DependencyClickCoords) =>
	showToolTip({
		id: fromId,
		type: DEPENDENCIES_FLYOUT_TYPE,
		links: {
			fromIds: [],
			toIds: [toId],
		},
		position,
		showImplicitDependency: false,
	});

const mapDispatchToProps = {
	onDependencyClick,
} as const;

export default connect(mapStateToProps, mapDispatchToProps)(RoadmapDependencyLines);
