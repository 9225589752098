import React, { type ComponentType } from 'react';
import { styled } from '@compiled/react';
import BreadcrumbRenderer from '@atlassian/jira-breadcrumbs/src/breadcrumb-renderer/index.tsx';
import PageHeader from '@atlassian/jira-common-components-page-header';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

type Props = {
	Indicator: ComponentType<{}>;
};

const Healthcheck = ({ Indicator }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<>
			<PageHeader truncateTitle breadcrumbs={<BreadcrumbRenderer />}>
				<PageHeaderContainer>{formatMessage(messages.timeline)}</PageHeaderContainer>
			</PageHeader>
			<Content>
				<Indicator />
			</Content>
		</>
	);
};

export default Healthcheck;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Content = styled.div({
	display: 'flex',
	width: '100%',
	flexDirection: 'column',
	justifyContent: 'flex-start',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PageHeaderContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
});
