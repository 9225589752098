import React from 'react';
import type { ColumnDuration, TimelineDuration } from '../../../common/types/timeline.tsx';
import { getColumnPositions, getTodayColumnIndex } from '../../../common/utils/columns.tsx';
import Columns from './view';

type Props = {
	isHighlightToday: boolean;
	today: number;
	timelineDuration: TimelineDuration;
	columnDurations: ColumnDuration[];
};

const ColumnsOverlay = ({ isHighlightToday, today, timelineDuration, columnDurations }: Props) => (
	<Columns
		isHighlightToday={isHighlightToday}
		columnPositions={getColumnPositions(timelineDuration, columnDurations)}
		todayColumnIndex={getTodayColumnIndex(today, columnDurations)}
	/>
);

export default ColumnsOverlay;
export type { Props as APIProps };
