import type { DragLayerMeta } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/chart-overlay/drag-layer/types.tsx';
import type { APIProps } from './dependency-line-preview';
import type { DependencyDragItem } from './types';

export const getPreviewProps = ({
	clientOffset,
	scrollOffset,
	item,
}: DragLayerMeta<DependencyDragItem>): APIProps | undefined => {
	if (!item) {
		return undefined;
	}

	const { id, barRect, leftPosition, viewportBoundingRect, requestViewportScrollBounding } = item;

	const scrollBounding = requestViewportScrollBounding();

	return {
		id,
		x0: leftPosition - scrollBounding.scrollLeft,
		y0: barRect.top + barRect.height / 2 - viewportBoundingRect.top + scrollOffset.scrollTop,
		x: clientOffset.x - viewportBoundingRect.left,
		y: clientOffset.y - viewportBoundingRect.top,
		width: viewportBoundingRect.width,
		height: viewportBoundingRect.height,
	};
};
