import type { Dispatch } from 'redux';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { connect } from '@atlassian/jira-react-redux';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { SprintId } from '@atlassian/jira-software-roadmap-model/src/sprint/index.tsx';
import type { IssueFieldModification } from '../../model/issue';
import {
	updateAndPersistIssueWithoutSprintScopeCheck,
	type UpdateIssueAction,
	resetTransitionState,
	type ResetTransitionStateAction,
} from '../../state/entities/issues/actions';
import { getIssueKeyById } from '../../state/entities/issues/selectors';
import { type SprintScopeChangeDirection, ASSIGN } from '../../state/flags/types';
import { getSprintName } from '../../state/selectors/sprint';
import type { State } from '../../state/types';
import Flag from './view';

type Action = UpdateIssueAction | ResetTransitionStateAction;

type OwnProps = {
	issueId: IssueId;
	hasOverdueReleaseDate: boolean | undefined;
	id: string | number;
	targetSprintId: SprintId | undefined;
	sourceSprintId: SprintId | undefined;
	transitionId: string;
	direction: SprintScopeChangeDirection;
	properties: IssueFieldModification;
	sourceEvent: UIAnalyticsEvent;
	onDismissed: (id: string | number) => void;
};

type OwnDefaultProps = {
	onDismissed: (id: string | number) => void;
};

type OwnPropsWithDefault = OwnProps & OwnDefaultProps;

type DispatchProps = {
	onPersist: (id: number | string, event: UIAnalyticsEvent) => void;
	onUndo: (id: number | string, event: UIAnalyticsEvent) => void;
};

const mapDispatchToProps = (
	dispatch: Dispatch<Action>,
	ownProps: OwnPropsWithDefault,
): DispatchProps => ({
	onPersist: () => {
		dispatch(
			updateAndPersistIssueWithoutSprintScopeCheck(
				ownProps.issueId,
				ownProps.properties,
				ownProps.sourceEvent,
				ownProps.hasOverdueReleaseDate,
			),
		);
	},
	onUndo: () => {
		dispatch(
			resetTransitionState({
				issueId: ownProps.issueId,
				transitionId: ownProps.transitionId,
			}),
		);
	},
});

const ConnectedFlag = connect(
	(state: State, ownProps: OwnPropsWithDefault) => ({
		issueKey: getIssueKeyById(state, ownProps.issueId),
		changedSprintName: getSprintName(state)(
			ownProps.direction === ASSIGN ? ownProps.targetSprintId : ownProps.sourceSprintId,
		),
	}),
	mapDispatchToProps,
	(stateProps, dispatchProps, ownProps) => {
		const { issueKey, changedSprintName } = stateProps;
		const { id, direction, onDismissed } = ownProps;
		return {
			...dispatchProps,
			issueKey,
			id,
			changedSprintName,
			direction,
			onDismissed,
		};
	},
)(Flag);

export default ConnectedFlag;
