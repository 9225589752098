import React, { Component, type ReactElement } from 'react';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { Ari } from '@atlassian/jira-platform-ari';
import type { RoadmapType } from '@atlassian/jira-software-roadmap-model/src/app/index.tsx';
import type { Query } from '@atlassian/jira-software-roadmap-model/src/router/index.tsx';
import { getCriticalDataObservable } from '@atlassian/jira-software-roadmap-services/src/initialize/critical-data.tsx';
import type { CriticalData } from '@atlassian/jira-software-roadmap-services/src/initialize/types.tsx';
import { toRoadmapAppId } from '../../../constants';
import Skeleton from './skeleton/view.tsx';

export type APIProps = {
	locationAri: Ari | undefined;
	sourceAri: Ari;
	query: Query | undefined;
	roadmapType: RoadmapType;
};

type ChildrenProps = {
	data: CriticalData | undefined;
	error: Error | undefined;
};

type Props = APIProps & {
	children: (props: ChildrenProps) => ReactElement;
};

type State = {
	data: CriticalData | undefined;
	isLoading: boolean;
	error: Error | undefined;
};

// eslint-disable-next-line jira/react/no-class-components
class StandardCriticalDataProvider extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			isLoading: true,
			error: undefined,
			data: undefined,
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	async fetchData() {
		const { sourceAri, locationAri, roadmapType, query } = this.props;
		const appId = toRoadmapAppId(roadmapType);

		try {
			const data = await getCriticalDataObservable(sourceAri, query, locationAri).toPromise();

			this.setState({ isLoading: false, data, error: undefined });
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			log.safeErrorWithoutCustomerData(
				`roadmap.${appId}.critical-data-provider`,
				'Unable to fetch critical data',
				error,
			);
			this.setState({ isLoading: false, error, data: undefined });
		}
	}

	render() {
		const { children } = this.props;
		const { isLoading, error, data } = this.state;

		return isLoading ? <Skeleton /> : children({ error, data });
	}
}

export default StandardCriticalDataProvider;
