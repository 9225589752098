import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import type { ComponentId, IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { UNASSIGNED_COMPONENT_ID } from '@atlassian/jira-software-filters/src/common/constants.tsx';
import type {
	Hash,
	IdentifiableHash,
} from '@atlassian/jira-software-roadmap-model/src/common/index.tsx';
import type {
	ComponentsHash,
	Component,
} from '@atlassian/jira-software-roadmap-model/src/component/index.tsx';

export const createGetSanitisedIssueComponentIdsHash = (
	issueComponentIdsHash: Hash<ComponentId[]>,
	allComponentsHash: ComponentsHash,
): Hash<ComponentId[]> =>
	Object.keys(issueComponentIdsHash).reduce<Hash<ComponentId[]>>((acc, key) => {
		const componentIds = issueComponentIdsHash[key];
		acc[key] = componentIds.filter((componentId) => Boolean(allComponentsHash[componentId]));
		return acc;
	}, {});

export const createGetUniqueComponents = (
	issueComponentsHash: IdentifiableHash<IssueId, ComponentId[]>,
): ComponentId[] => {
	const allComponents: ComponentId[] = flatten(
		Object.keys(issueComponentsHash).map((key) => {
			const issueComponents: ComponentId[] = issueComponentsHash[key] || [];
			return issueComponents;
		}),
	);
	const uniqueComponents = uniq(allComponents);

	return uniqueComponents.length
		? [UNASSIGNED_COMPONENT_ID, ...uniqueComponents]
		: uniqueComponents;
};

/**
 * Pure function for getting an array of unique component objects (sorted by name) that are assigned to at least one issue.
 *
 * @param uniqueComponents An array of unique component IDs that are assigned to at least one issue.
 * @param componentsHash A map of component IDs to component objects.
 * @returns {Array<Component>} An array of component objects, sorted by name.
 */
export const createGetComponents = (
	uniqueComponents: ComponentId[],
	componentsHash: ComponentsHash,
): Component[] => {
	const components: Component[] = [];

	uniqueComponents.forEach((componentId: ComponentId) => {
		const component = componentsHash[componentId];

		// NOTE: Leave unassigned component case out as it's not a real component
		component && components.push(component);
	});

	return components.sort((componentA, componentB) =>
		componentA.name.toLowerCase() < componentB.name.toLowerCase() ? -1 : 1,
	);
};
