// eslint-disable-next-line jira/restricted/react-component-props
import type { ComponentProps } from 'react';
import { connect } from '@atlassian/jira-react-redux';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { RoadmapListItemSecondaryContent } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/list-item-secondary-content/main.tsx';
import { getSecondaryFieldsHash } from '../../../../state/selectors/table';
import type { State } from '../../../../state/types';

type OwnProps = {
	id: IssueId;
};

type StateProps = ComponentProps<typeof RoadmapListItemSecondaryContent>;

export default connect(
	(state: State, { id }: OwnProps): StateProps => ({
		...getSecondaryFieldsHash(state)[id],
	}),
	null,
	(stateProps: StateProps) => ({
		...stateProps,
	}),
)(RoadmapListItemSecondaryContent);
