import { MILLISECONDS_PER_DAY } from '@atlassian/jira-software-roadmap-model/src/datetime/index.tsx';

export const DATE_FORMAT = 'yyyy/MM/dd' as const;
export const DATE_FORMAT_MOMENT = 'yyyy/MM/DD' as const;

// An elements end position is not a true reflection of its date, instead it visually extends until end of day
export const DUE_DATE_OFFSET = MILLISECONDS_PER_DAY - 1;

// Types of dates
export const INTERVAL = 'INTERVAL' as const;
export const ROLLUP = 'ROLLUP' as const;
export const EXPLICIT = 'EXPLICIT' as const;
