import React from 'react';
import {
	BAR_GAP_WIDTH,
	EXPANDABLE_INDICATOR_WIDTH,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/chart-header.tsx';
import { HEADER_CONTENT_HEIGHT } from '@atlassian/jira-software-roadmap-timeline-table/src/common/constants.tsx';

const POLYGON_COORDINATES = `0,0 ${EXPANDABLE_INDICATOR_WIDTH - BAR_GAP_WIDTH},${
	HEADER_CONTENT_HEIGHT / 2
} 0,${HEADER_CONTENT_HEIGHT}`;

type Props = {
	backgroundColor: string;
};

const ExpandableIndicator = ({ backgroundColor }: Props) => (
	<svg
		width={EXPANDABLE_INDICATOR_WIDTH}
		height={HEADER_CONTENT_HEIGHT}
		style={{
			// @ts-expect-error([Part of upgrade 4.9-5.4]) - Object literal may only specify known properties, and '[`${'--icon-primary-color'}`]' does not exist in type 'Properties<number | (string & {}), string & {}>'.
			[`${'--icon-primary-color'}`]: backgroundColor,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			color: 'var(--icon-primary-color)',
		}}
	>
		<g fill="currentColor">
			<polygon points={POLYGON_COORDINATES} />
		</g>
	</svg>
);

export { ExpandableIndicator };
