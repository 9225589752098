import memoizeOne from 'memoize-one';
import {
	START_AND_DUE_DATE_PLACEHOLDER,
	START_DATE_PLACEHOLDER,
	DUE_DATE_PLACEHOLDER,
	MINIMUM_BAR_LENGTH,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/chart-item.tsx';
import { ROLLUP } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/constants/date.tsx';
import type {
	BarDragType,
	HorizontalDirection,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/chart-item.tsx';
import type { DateType } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/date.tsx';
import {
	getBarLeftPosition,
	getBarRightPosition,
} from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/utils/bar/positions.tsx';
import type { TimelineDuration } from '@atlassian/jira-software-roadmap-timeline-table/src/common/types/timeline.tsx';
import { LEFT, RIGHT, LEFT_AND_RIGHT } from '../../../../../../common/constants';
import type { DragPosition, InteractiveChartItem } from '../../../common/types';
import { snapToGrid } from '../../../common/utils';
import type { ChildItemsHash, DateInteractiveChartItem, GetItemsCollectionResult } from './types';

export const shouldUpdateLeftPosition = (
	dragType: BarDragType,
	hasLeftPlaceholder: boolean,
	dateType: DateType,
) =>
	dragType === LEFT_AND_RIGHT ||
	dragType === LEFT ||
	(dragType === RIGHT && hasLeftPlaceholder && dateType !== ROLLUP);

export const shouldUpdateRightPosition = (
	dragType: BarDragType,
	hasRightPlaceholder: boolean,
	dateType: DateType,
) =>
	dragType === LEFT_AND_RIGHT ||
	dragType === RIGHT ||
	(dragType === LEFT && hasRightPlaceholder && dateType !== ROLLUP);

// Generally, these rules are designed to keep a bar inside the timeline.
// But, if a bar already intersects with the timeline, we try to still allow them to be dragged.
export const isValidDrag = (
	dragType: BarDragType,
	leftPosition: number,
	rightPosition: number,
	timelineWidth: number,
	direction: HorizontalDirection,
) => {
	if (dragType === LEFT_AND_RIGHT && leftPosition < 0 && direction === LEFT) {
		return false;
	}
	if (dragType === LEFT_AND_RIGHT && rightPosition < 0 && direction === RIGHT) {
		return false;
	}
	if (dragType === LEFT && (leftPosition < 0 || leftPosition > timelineWidth)) {
		return false;
	}
	if (dragType === RIGHT && (rightPosition < 0 || rightPosition > timelineWidth)) {
		return false;
	}
	if (dragType === LEFT || dragType === RIGHT) {
		// Cannot be smaller than the minimum width
		return timelineWidth - rightPosition - leftPosition > MINIMUM_BAR_LENGTH;
	}
	return true;
};

const getDragDirection = (
	dragType: BarDragType,
	leftPosition: number | undefined,
	baselineLeftPosition: number,
): HorizontalDirection => {
	if (dragType === LEFT_AND_RIGHT) {
		return leftPosition !== undefined && leftPosition < baselineLeftPosition ? LEFT : RIGHT;
	}
	if (dragType === LEFT) {
		return LEFT;
	}
	return RIGHT;
};

export const getDragPosition = (
	dragType: BarDragType,
	item: DateInteractiveChartItem,
	timelineDuration: TimelineDuration,
	timelineWidth: number,
	{ to, start }: DragPosition,
	gridSize: number,
	scrollOffset: number,
): {
	leftPosition: number | undefined;
	rightPosition: number | undefined;
} => {
	let leftPosition;
	let rightPosition;

	const { startDate, dueDate, placeholder, startDateType, dueDateType } = item;
	const baselineLeftPosition = getBarLeftPosition(startDate, timelineDuration, timelineWidth);
	const baselineRightPosition = getBarRightPosition(dueDate, timelineDuration, timelineWidth);

	const hasLeftPlaceholder =
		placeholder === START_DATE_PLACEHOLDER || placeholder === START_AND_DUE_DATE_PLACEHOLDER;
	const hasRightPlaceholder =
		placeholder === DUE_DATE_PLACEHOLDER || placeholder === START_AND_DUE_DATE_PLACEHOLDER;

	if (shouldUpdateLeftPosition(dragType, hasLeftPlaceholder, startDateType)) {
		const offset = to.x - start.x - scrollOffset;
		leftPosition = snapToGrid(baselineLeftPosition, gridSize, offset);
	}

	if (shouldUpdateRightPosition(dragType, hasRightPlaceholder, dueDateType)) {
		const offset = start.x - to.x + scrollOffset;
		rightPosition = snapToGrid(baselineRightPosition, gridSize, offset);
	}

	const actualLeftPosition = leftPosition ?? baselineLeftPosition;
	const actualRightPosition = rightPosition ?? baselineRightPosition;

	const direction = getDragDirection(dragType, leftPosition, baselineLeftPosition);

	if (isValidDrag(dragType, actualLeftPosition, actualRightPosition, timelineWidth, direction)) {
		return {
			leftPosition,
			rightPosition,
		};
	}

	return {
		leftPosition: undefined,
		rightPosition: undefined,
	};
};

const isDateInteractiveChartItem = (item: InteractiveChartItem): item is DateInteractiveChartItem =>
	item.startDate !== undefined && item.dueDate !== undefined;

export const getItemsCollection = memoizeOne(
	(items: InteractiveChartItem[], selectedItemIds: string[]): GetItemsCollectionResult => {
		const itemsTree = items.reduce<ChildItemsHash>((acc, item) => {
			if (item.parentId && isDateInteractiveChartItem(item)) {
				const childItems = acc[item.parentId] ?? [];
				childItems.push(item);
				acc[item.parentId] = childItems;
			}

			return acc;
		}, {});

		const descendantItemsHash: ChildItemsHash = {};
		const findDescendants = (parentId: string): DateInteractiveChartItem[] => {
			if (descendantItemsHash[parentId]) {
				return descendantItemsHash[parentId];
			}

			const children = itemsTree[parentId] ?? [];
			const descendants = children.flatMap((child) => [child, ...findDescendants(child.id)]);

			descendantItemsHash[parentId] = descendants;
			return descendants;
		};

		return items.reduce<GetItemsCollectionResult>(
			(acc, item) => {
				if (!isDateInteractiveChartItem(item)) {
					return acc;
				}

				if (selectedItemIds.includes(item.id)) {
					acc.selectedItems.push(item);
				}

				if (item.parentId) {
					acc.childItemsHash[item.parentId] = findDescendants(item.parentId);
				}

				return acc;
			},
			{ selectedItems: [], childItemsHash: {} },
		);
	},
);
