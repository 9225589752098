import { CLOSED, type SprintId } from '@atlassian/jira-software-roadmap-model/src/sprint/index.tsx';
import type { Sprint } from '../model/sprint/types';
import type { IntervalType } from '../state/selectors/sprint/utils';

export const getFirstOpenSprint = (
	intervals: ReadonlyArray<IntervalType<Sprint>>,
): SprintId | undefined => intervals.find((sprint: Sprint) => sprint.state !== CLOSED)?.id;

export { convertStringToQuery, convertQueryToString } from './router';

export { getRoadmapSettingsUrl } from './urls';

export { isDependencyCyclic } from './dependencies/detect-dependency-cycle.tsx';
