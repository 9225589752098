import { defineSimpleStorage } from '@atlassian/jira-common-storage-redux/src/config.tsx';
import { setListWidth } from '../../../state/settings/actions';
import { getListWidth } from '../../../state/settings/selectors';
import type { State } from '../../../state/types';

export default defineSimpleStorage<State, number | null>(
	() => 'listWidth',
	(listWidth: number | null) => (listWidth === null ? [] : [setListWidth(listWidth)]),
	(state) => getListWidth(state),
);
