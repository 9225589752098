import has from 'lodash/has';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { ProjectId, IssueTypeId, IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	SPRINT_IDS,
	START_DATE,
	DUE_DATE,
} from '@atlassian/jira-software-roadmap-model/src/issue-view/index.tsx';
import type {
	AnalyticsUpdatedItems,
	Issue,
} from '@atlassian/jira-software-roadmap-model/src/issue/index.tsx';
import type { IssueFieldModification } from '../../../model/issue';
import { getUserDisplayNameHash } from '../../../state/entities/users/selectors';
import {
	getFilterFunction,
	getSettingFilterFunction,
	type FilterableIssue,
} from '../../../state/selectors/issues';
import type { State } from '../../../state/types';

export type UpdateAnalyticsMetadata = {
	event: UIAnalyticsEvent;
	projectId: ProjectId;
	attributes: {
		issueTypeId: IssueTypeId;
		updatedItems: AnalyticsUpdatedItems;
		level: number;
		hasOverdueReleaseDate?: boolean | undefined;
	};
};

export const getFieldModForIssueUpdate = (issue: Issue): IssueFieldModification => {
	const fieldMod: IssueFieldModification = {};
	const clearFields = [];

	if (has(issue.summary, 'old')) {
		fieldMod.summary = issue.summary.value;
	}

	if (has(issue.startDate, 'old')) {
		if (issue.startDate.value === undefined) {
			clearFields.push(START_DATE);
		} else {
			fieldMod.startDate = issue.startDate.value;
		}
	}

	if (has(issue.dueDate, 'old')) {
		if (issue.dueDate.value === undefined) {
			clearFields.push(DUE_DATE);
		} else {
			fieldMod.dueDate = issue.dueDate.value;
		}
	}

	if (has(issue.color, 'old')) {
		fieldMod.color = issue.color.value;
	}

	if (has(issue.parentId, 'old')) {
		fieldMod.parentId = issue.parentId.value;
	}

	if (has(issue.sprintIds, 'old')) {
		if (issue.sprintIds.value.length === 0) {
			clearFields.push(SPRINT_IDS);
		} else {
			const sprintId = issue.sprintIds.value[0];
			fieldMod.sprintId = sprintId;
		}
	}

	if (clearFields.length > 0) {
		fieldMod.clearFields = clearFields;
	}

	return fieldMod;
};

export const getIsIssueHiddenByFiltersOrSettings = (
	state: State,
	issue: Issue,
	issueId: IssueId,
	skipQuickFilters = false,
): {
	isHiddenByFilter: boolean;
	isHiddenBySetting: boolean;
} => {
	const {
		key,
		issueTypeId,
		summary,
		status,
		assignee: assigneeId,
		labels,
		parentId,
		versionIds,
		dueDate,
		componentIds,
	} = issue;

	const filter = getFilterFunction(state, skipQuickFilters);
	const settingFilter = getSettingFilterFunction(state);
	const userDisplayNameHash = getUserDisplayNameHash(state);

	const assignee =
		assigneeId !== undefined
			? {
					id: assigneeId,
					displayName: userDisplayNameHash[assigneeId],
				}
			: undefined;

	const filterFields: FilterableIssue = {
		key,
		summary: summary.value,
		labels,
		parentId: parentId.value,
		type: { id: issueTypeId },
		versionIds,
		componentIds,
		statusCategoryId: status?.statusCategoryId,
		children: [],
		assignee,
		dueDate: dueDate.value,
		id: issueId,
	};

	const isHiddenByFilter = filter ? filter(filterFields) === false : false;
	const isHiddenBySetting = settingFilter ? settingFilter(filterFields) === false : false;
	return {
		isHiddenByFilter,
		isHiddenBySetting,
	};
};
