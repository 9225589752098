import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { IssueTypeId, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { IdentifiableHash } from '@atlassian/jira-software-roadmap-model/src/common/index.tsx';
import type {
	Healthcheck,
	HealthcheckIssueType,
	HealthcheckResolutionSelection,
} from '@atlassian/jira-software-roadmap-model/src/healthcheck/index.tsx';

export const RETRIEVE_ISSUE_TYPE_COUNT = 'state.ui.healthcheck.RETRIEVE_ISSUE_TYPE_COUNT' as const;

export type RetrieveIssueTypeCountPayload = {
	issueTypeId: IssueTypeId;
	projectId: ProjectId;
};
export type RetrieveIssueTypeCountAction = {
	type: typeof RETRIEVE_ISSUE_TYPE_COUNT;
	payload: RetrieveIssueTypeCountPayload;
};
export const retrieveIssueTypeCount = (
	payload: RetrieveIssueTypeCountPayload,
): RetrieveIssueTypeCountAction => ({
	type: RETRIEVE_ISSUE_TYPE_COUNT,
	payload,
});

export const EXTEND_ISSUE_TYPE_COUNT = 'state.ui.healthcheck.EXTEND_ISSUE_TYPE_COUNT' as const;

export type ExtendIssueTypeCountAction = {
	type: typeof EXTEND_ISSUE_TYPE_COUNT;
	payload: IdentifiableHash<IssueTypeId, number>;
};

export const extendIssueTypeCount = (
	payload: IdentifiableHash<IssueTypeId, number>,
): ExtendIssueTypeCountAction => ({
	type: EXTEND_ISSUE_TYPE_COUNT,
	payload,
});

export const RESOLVE = 'state.ui.healthcheck.RESOLVE' as const;

export type ResolveActionPayload = {
	epicName: string | undefined;
	epicDescription: string | undefined;
	issueTypeToPromote: IssueTypeId | undefined;
};

export type ResolveAction = {
	type: typeof RESOLVE;
	payload: ResolveActionPayload;
};

export const resolve = (payload: ResolveActionPayload): ResolveAction => ({
	type: RESOLVE,
	payload,
});

export const SET_RESOLVING = 'state.ui.healthcheck.SET_RESOLVING' as const;

export type SetResolvingAction = {
	type: typeof SET_RESOLVING;
	payload: boolean;
};

export const setResolving = (payload: boolean): SetResolvingAction => ({
	type: SET_RESOLVING,
	payload,
});

export const UPDATE_HEALTHCHECK = 'state.ui.healthcheck.UPDATE_HEALTHCHECK';

type UpdateHealthcheckActionPayload = Healthcheck | undefined;

export type UpdateHealthcheckAction = {
	type: typeof UPDATE_HEALTHCHECK;
	payload: UpdateHealthcheckActionPayload;
};

export const updateHealthcheck = (
	payload: UpdateHealthcheckActionPayload,
): UpdateHealthcheckAction => ({
	type: UPDATE_HEALTHCHECK,
	payload,
});

export const RESOLVE_HEALTHCHECK = 'state.ui.healthcheck.RESOLVE_HEALTHCHECK';

export type ResolveHealthcheckActionPayload = {
	actionId: string;
	levelOneIssueTypeSelection: HealthcheckResolutionSelection | undefined;
	refreshNavigationData: () => void;
};

export type ResolveHealthcheckAction = {
	type: typeof RESOLVE_HEALTHCHECK;
	payload: ResolveHealthcheckActionPayload;
	meta: {
		analyticsEvent: UIAnalyticsEvent;
	};
};

export const resolveHealthcheck = (
	payload: ResolveHealthcheckActionPayload,
	analyticsEvent: UIAnalyticsEvent,
): ResolveHealthcheckAction => ({
	type: RESOLVE_HEALTHCHECK,
	payload,
	meta: {
		analyticsEvent,
	},
});

export type RequestIssueTypesPayload = {
	cloudId: string;
	projectId: number;
};

export const REQUEST_ISSUE_TYPES = 'state.ui.healthcheck.REQUEST_ISSUE_TYPES' as const;

export type RequestIssueTypesAction = {
	type: typeof REQUEST_ISSUE_TYPES;
	payload: RequestIssueTypesPayload;
};

export const requestIssueTypes = (payload: RequestIssueTypesPayload): RequestIssueTypesAction => ({
	type: REQUEST_ISSUE_TYPES,
	payload,
});

export const EXTEND_ISSUE_TYPES = 'state.ui.healthcheck.EXTEND_ISSUE_TYPES' as const;

export type ExtendIssueTypesAction = {
	type: typeof EXTEND_ISSUE_TYPES;
	payload: IdentifiableHash<IssueTypeId, HealthcheckIssueType>;
};

export const extendIssueTypes = (
	payload: IdentifiableHash<IssueTypeId, HealthcheckIssueType>,
): ExtendIssueTypesAction => ({
	type: EXTEND_ISSUE_TYPES,
	payload,
});
